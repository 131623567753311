<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('customers')">Customers</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="customer.deleted_at" class="mb-6" @restore="restore">
      This customer has been deleted.
    </trashed-message>
    <h2 class="mb-8 mt-16 font-bold text-2xl mt-15">Details</h2>
    <form @submit.prevent="submit">
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name"/>
        </div>
      </div>

      <div class="mt-10">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Save</loading-button>
      </div>
    </form>


    <h2 class="mt-16 mb-8 font-bold text-2xl">Orders</h2>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Reference</th>
          <th class="px-6 pt-6 pb-4">Date</th>
          <th class="px-6 pt-6 pb-4">Price Inc VAT</th>
          <th class="px-6 pt-6 pb-4">Created By</th>
          <!--th class="px-6 pt-6 pb-4">Total Inc VAT</th-->
          <!--th class="px-6 pt-6 pb-4">Total Ex VAT</th-->
        </tr>
        <tr v-for="order in orders" :key="order.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
              {{ order.name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
              {{ order.date.substring(0, 10) }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
              £{{ parseFloat(order.price_total_inc_vat).toFixed(2) }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
              {{ order.created_by }}
            </inertia-link>
          </td>
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
              £{{ parseFloat(order.price_total_vat).toFixed(2) }}
              <icon v-if="order.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td-->
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
                £{{ parseFloat(order.price_total_exvat).toFixed(2) }}
              <icon v-if="order.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td-->

          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('orders.edit', order.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="orders.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No orders found.</td>
        </tr>
      </table>
    </div>

    <form class="mt-10" @submit.prevent="addOrder">
      <loading-button :loading="sending" class="btn-indigo" type="submit">Add Order</loading-button>
    </form>

    <h2 class="mt-16 mb-8 font-bold text-2xl">Scalings</h2>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Reference</th>
          <th class="px-6 pt-6 pb-4">Date</th>
          <th class="px-6 pt-6 pb-4">Created By</th>
          <!--th class="px-6 pt-6 pb-4">Total Inc VAT</th-->
          <!--th class="px-6 pt-6 pb-4">Total Ex VAT</th-->
        </tr>
        <tr v-for="scaling in scalings" :key="scaling.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('scalings.edit', scaling.id)">
              {{ scaling.name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('scalings.edit', scaling.id)">
              {{ scaling.date.substring(0, 10) }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('scalings.edit', scaling.id)">
              {{ scaling.created_by }}
            </inertia-link>
          </td>
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
              £{{ parseFloat(order.price_total_vat).toFixed(2) }}
              <icon v-if="order.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td-->
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
                £{{ parseFloat(order.price_total_exvat).toFixed(2) }}
              <icon v-if="order.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td-->

          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('scalings.edit', scaling.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="scalings.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No scalings found.</td>
        </tr>
      </table>
    </div>

    <form class="mt-10" @submit.prevent="addScaling">
      <loading-button :loading="sending" class="btn-indigo" type="submit">Add Scaling</loading-button>
    </form>


    <div class="mt-10 flex">
      <!-- Original link to add price page
      <inertia-link class="btn-indigo" :href="route('bundles.price.create', bundle.id)">
        <span>Add</span>
        <span class="hidden md:inline">Price</span>
      </inertia-link>
      -->


      <!--inertia-link class="btn-indigo" :href="route('bundles.price.create', bundle.id)">
        <span>Add</span>
        <span class="hidden md:inline">Price 3 direct </span>
      </inertia-link-->

      <!--Not needed anymore as prices saved individually
      <form @submit.prevent="updatePrice">
        <loading-button :loading="sending" class="btn-indigo ml-10" type="submit">Save</loading-button>
      </form-->
    </div>

    <!--h2 class="mt-16 mb-8 font-bold text-2xl">Suggested Postage for {{ bundle.category_name }}</h2>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto max-w-3xl">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Channel</th>
          <th class="px-6 pt-6 pb-4">Bundle</th>
        </tr>
        <tr v-for="postage in postage_suggestions" :key="postage.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('postage')" tabindex="-1">
              {{ postage.channel_name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('postage')" tabindex="-1">
              {{ postage.unit_name }}
            </inertia-link>
          </td>
        </tr>
        <tr v-if="postage.length === 0">
          <td class="border-t px-6 py-4" colspan="13">No postage suggestions are configured for this category (see
            postage page)
          </td>
        </tr>
      </table>
    </div-->


    <div class="mt-16">
      <button v-if="!customer.deleted_at && customer.id!=1" class="btn-indigo bg-red-500" type="button" @click="destroy">Delete Customer
      </button>
    </div>


  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    customer: Object,
    orders: Object,
    scalings: Object,
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        name: this.customer.name,
      },
    }
  },
  methods: {
    addOrder() {
      this.$inertia.post(this.route('orders.create'),
          {
            customer_id: this.customer.id
          }
      )
    },
    addScaling() {
      this.$inertia.post(this.route('scalings.create'),
          {
            customer_id: this.customer.id
          }
      )
    },
    submit() {
      this.$inertia.put(this.route('customers.update', this.customer.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this customer?')) {
        this.$inertia.delete(this.route('customers.destroy', this.customer.id))
      }
    },
  }
}
</script>
