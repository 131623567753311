<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('customers')">Customers</inertia-link>
      <span class="text-indigo-400 font-medium"></span> / Orders / Create
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <form @submit.prevent="submit">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <!--select-input v-model="form.customer_id" :error="errors.customer_id" class="pr-6 pb-8 w-full lg:w-1/2"
                       label="Customer">
           <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
         </select-input-->
          <select-input v-model="form.customer_id" :error="errors.customer_id" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Customer">
            <!--option :value=null>[New Customer]</option-->
            <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
          </select-input>
          <text-input v-if="form.customer_id==null" v-model="form.new_customer_name" :error="new_customer_name"
                      class="pr-6 pb-8 w-full lg:w-1/2" label="New Customer Name"/>
            <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Reference"/>
          <label class="w-full mb-2">Date:</label>

          <v-date-picker v-model="form.date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  class="border px-2 py-3 rounded w-84 mb-10"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </v-date-picker>

        </div>
        <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex justify-end items-center">
          <loading-button :loading="sending" class="btn-indigo" type="submit">Create Order</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Order'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    customer: Object,
    customers: Array,
    //categories: Object,
    //fba_categories: Object,
    //inherit_options: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        //name: null,
        customer_id: this.customer.id,
        //new_customer_name: null,
        //category: null,
        //fba_category: null,
        //inherit: 'y'
        date: new Date() // Default to today's date
      },

    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.route('orders.store'), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
  },
}
</script>
