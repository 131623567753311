<
<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">FBA Unprinted Labels</h1>
      <div>
      </div>
    </div>
    <div class="mt-10">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Tracking ID / Box ID / SKU"/>
        </div>
        <div class="my-5 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <!--th class="px-6 pt-6 pb-4 text-left" colspan="1"></th-->
          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>
          <th class="px-6 pt-6 pb-4">Time</th>
          <th class="px-6 pt-6 pb-4">Title</th>
          <th class="px-6 pt-6 pb-4">Units Per Box</th>
          <th class="px-6 pt-6 pb-4">FBA ID</th>
          <th class="px-6 pt-6 pb-4">FBA Box ID</th>
          <th class="px-6 pt-6 pb-4">UPS Tracking ID</th>
          <th class="px-6 pt-6 pb-4">UPS Box</th>
          <th class="px-6 pt-6 pb-4">Box No</th>
          <th class="px-6 pt-6 pb-4">FBA SKU</th>
          <th class="px-6 pt-6 pb-4">LW / UPS SKU</th>
          <!--th class="px-6 pt-6 pb-4">Duplicate #</th-->
          <th class="px-6 pt-6 pb-4">LW Order ID</th>
          <th class="px-6 pt-6 pb-4">Label ID</th>
          <th class="px-6 pt-6 pb-4">Packing ID</th>
        </tr>
        <tr v-for="label in labels.data" :key="label.id" class="hover:bg-gray-100 focus-within:bg-gray-100">

          <td class="border-t">
            <div class="px-6 py-4 flex items-center justify-center">
              <i v-if="label.hazardous === 'Y'" class="fa-solid fa-radiation text-red-600"></i>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ label.due_date.substring(0, 10) }}
            </div>
          </td>

          <td class="border-t">
            <div class="py-4 px-6 flex items-center w-96" tabindex="-1">
              <span class="truncate">
              {{ label.sku_title }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="py-4 px-6 flex items-center" tabindex="-1">
              <span class="truncate">
              {{ label.units_per_box }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_tracking_id.substring(0, 12) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_tracking_id }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
                <span v-if="label.ups_tracking_id">
                  {{ label.ups_tracking_id }}
                </span>
              <span v-else>
                  N/A
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.box_number }} of {{ label.box_total }}
            </div>
          </td>


          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_sku }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.lw_sku }}
            </div>
          </td>

          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.dup_rank }}
            </div>
          </td-->
          <td class="border-t">
            <a class="px-6 py-4 flex items-center text-indigo-400" tabindex="-1"
               :href="'https://www.linnworks.net/#/app/ViewOrder/' +  label.lw_order_id" target="_blank">
              {{ label.lw_order_id }}
            </a>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.id }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.packing_id }}
            </div>
          </td>


        </tr>
        <tr v-if="labels.data.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No labels found.</td>
        </tr>
      </table>
    </div>
    <pagination :links="labels.links"/>
  </div>

</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import {adjustDate, setToToday, setToTomorrow, copyToClipboard} from '@/Services/genericDataService';
import {retrieveLabelPDF} from '@/Services/genericApiService';

export default {
  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    labels: Object,
    filters: Object,
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        search: this.filters.search,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.get(this.route('labels.unprinted'), this.form, {preserveState: false})
    },
    /* Removed due to naughtiness
    handleRetrieveLabelPDF(label) {
      retrieveLabelPDF(this.route, label)
    },
     */
    /*
    handleAdjustDate(days) {
      this.form.date = adjustDate(this.form.date, days);
      this.submit();
    },
    handleSetToToday() {
      this.form.date = setToToday()
      this.submit();
    },
    handleSetToTomorrow() {
      this.form.date = setToTomorrow()
      this.submit();
    },
    handleCopyToClipboard(text) {
       copyToClipboard(text)
    },
    */
  },
  computed: {
    isAllowedEmail() {
      const allowedEmails = ['andrew.j.stainer@gmail.com', 'thijs@nikura.com', 'philip@nikura.com', 'admin@nikura.com'];
      return allowedEmails.includes(this.auth.user.email);
      //return true
    }
  },
}
</script>
