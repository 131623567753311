<template>
  <div>
    <portal-target name="dropdown" slim/>
    <div class="xl:flex xl:flex-col">
      <div class="xl:h-screen xl:flex xl:flex-col" @click="hideDropdownMenus">
        <div class="xl:flex xl:flex-shrink-0">
          <div
              class="bg-gray-800 xl:flex-shrink-0 xl:w-56 px-6 py-4 flex items-center justify-between xl:justify-center">
            <inertia-link class="mt-1 text-gray-500" href="/">
              <!--logo class="fill-white" width="120" height="28" /-->
              <!--i class="fa-solid fa-leaf fa-xl mr-2"></i-->
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" viewBox="0 0 74 75">
                <path fill="currentColor"
                      d="M54 20h-1a24 24 0 01-4 0h-1v1l1 2a226 226 0 011 22L28 20h-1-5l-1 1h1l4 5 24 28h3a445 445 0 011-33v-1zM27 44l-3-6v-1 1l-2 5-1 3c-2 5 0 7 1 7l2 1 3-1c1 0 3-2 1-7l-1-2z"></path>
                <path fill="currentColor" d="M74 74H0V0h74v74zM3 72h68V3H3v69z"></path>
              </svg>
            </inertia-link>
            <dropdown class="xl:hidden" placement="bottom-end">
              <svg class="fill-white w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
              </svg>
              <div slot="dropdown" class="mt-2 px-8 py-4 shadow-lg bg-gray-800 rounded">
                <main-menu :url="url()"/>
              </div>
            </dropdown>
          </div>
          <div class="bg-white border-b w-full p-4 xl:py-0 xl:px-12 text-sm xl:text-md flex justify-end items-center">
            <!--div v-if="is_superuser" class="mt-1 py-2 pr-4 text-sm">
              <a class="mt-1" :href="route('prices')">
                <i class="fa fa-sterling-sign fa-lg mr-4"></i>
              </a>
            </div>
            <div class="mt-1 py-2 pr-4 text-sm">
              <a class="mt-1" :href="route('assemblies.admin')">
                <i class="fa fa-box fa-lg mr-4"></i>
              </a>
            </div>
            <div class="mt-1 py-2 pr-4 text-sm">
              <a class="mt-1" :href="route('FBA.orderconverter')">
                <i class="fa-brands fa-amazon fa-lg mr-4"></i>
              </a>
            </div>
            <div v-if="is_superuser" class="mt-1 py-2 pr-4 text-sm">
              <a class="mt-1" :href="route('customers')">
                <i class="fa-solid fa-users fa-lg mr-4"></i>
              </a>
            </div>
               <div v-if="is_superuser" class="mt-1 py-2 pr-4 text-sm">
              <a class="mt-1" :href="route('productions.admin')">
                <i class="fa-solid fa-bottle-droplet fa-lg mr-4"></i>
              </a>
            </div-->
            <div v-if="is_superuser" class="mt-1 py-2 pr-4 text-sm">
              <a class="mt-1" href="/admin">
                <i class="fa-solid fa-gear fa-lg mr-4"></i>
              </a>
            </div>
            <!--div class="mt-1 py-2 pr-4 text-sm">
              <a class="mt-1" href="/packings">
                <i class="fa-brands fa-amazon fa-2x w-4 h-4 mr-2"></i>
              </a>
            </div-->
            <!--div slot="dropdown" class="mt-1 py-2 pr-4 text-sm">
              <inertia-link class="mt-1" :href="route('assemblies')">
                <i class="fa fa-box fa-2x w-4 h-4 mr-2"></i>
              </inertia-link>
            </div-->
            <!--dropdown class="mt-1 pr-10 focus:outline-none" placement="bottom-end">
              <div class="flex items-center cursor-pointer select-none group">
                <div class="text-gray-700 group-hover:text-indigo-600 focus:text-indigo-600 mr-1 whitespace-no-wrap">
                  <i class="fa fa-bars fa-2x w-4 h-4 mr-2"></i>
                </div>
              </div>
              <div slot="dropdown" class="mt-2 py-2 shadow-xl bg-white rounded text-sm">
                <inertia-link class="block px-6 py-2 hover:bg-indigo-500 hover:text-white" :href="route('prices')">Prices</inertia-link>
                <inertia-link class="block px-6 py-2 hover:bg-indigo-500 hover:text-white" :href="route('assemblies')">Assemblies</inertia-link>
             </div>
            </dropdown-->
            <dropdown class="mt-1  focus:outline-none" placement="bottom-end">
              <div class="flex items-center cursor-pointer select-none group">
                <div class="text-gray-700 group-hover:text-indigo-600 focus:text-indigo-600 mr-1 whitespace-no-wrap">
                  <i class="fa fa-user fa-lg mr-4"></i>
                </div>
              </div>
              <div slot="dropdown" class="mt-2 py-2 shadow-xl bg-white rounded text-sm">
                <!--inertia-link class="block px-6 py-2 hover:bg-indigo-500 hover:text-white" :href="route('users.edit', $page.props.auth.user.id)">My Profile</inertia-link>
                <inertia-link class="block px-6 py-2 hover:bg-indigo-500 hover:text-white" :href="route('users')">Manage Users</inertia-link-->
                 <div class="block px-6 py-2 text-gray-700">{{ auth.user.account.name }}</div>
                <inertia-link class="block px-6 py-2 hover:bg-indigo-500 hover:text-white mt-5" :href="route('logout')"
                              method="post">Logout
                </inertia-link>
              </div>
            </dropdown>
          </div>
        </div>
        <div class="xl:flex xl:flex-grow xl:overflow-hidden">
          <main-menu :url="url()" class="hidden xl:block bg-gray-800 flex-shrink-0 w-56 p-12 overflow-y-auto"/>
          <div class="xl:flex-1 px-4 py-8 xl:p-12 xl:overflow-y-auto" scroll-region>
            <flash-messages/>
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/Shared/Dropdown'
import FlashMessages from '@/Shared/FlashMessages'
import Icon from '@/Shared/Icon'
import Logo from '@/Shared/Logo'
import MainMenu from '@/Shared/MainMenu'

export default {
  components: {
    Dropdown,
    FlashMessages,
    Icon,
    Logo,
    MainMenu,
  },
  data() {
    return {
      showUserMenu: false,
      accounts: null,
      is_superuser: this.$parent.$props.initialPage.props.auth.user.is_superuser,
      auth: this.$parent.$props.initialPage.props.auth
    }
  },
  methods: {
    url() {
      return location.pathname.substr(1)
    },
    hideDropdownMenus() {
      this.showUserMenu = false
    },
  },
}
</script>
