<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Currencies</h1>
      <div class="flex justify-end">
        <button class="btn-indigo" @click="updateCurrencies">
          <span>Update currencies</span>
        </button>
        <inertia-link class="btn-indigo ml-4" :href="route('currencies.create')">
          <span>Create</span>
          <span class="hidden md:inline">Currency</span>
        </inertia-link>
      </div>
    </div>

    <!--div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
    </div-->
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4" colspan="2">Conversion to GBP</th>
        </tr>
        <tr v-for="currency in currencies.data" :key="currency.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('currencies.edit', currency.id)">
              {{ currency.name }}
              <icon v-if="currency.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('currencies.edit', currency.id)">
              {{ currency.conversion }}
              <icon v-if="currency.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td>
          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('currencies.edit', currency.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="currencies.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No currencies found.</td>
        </tr>
      </table>
    </div>
    <pagination :links="currencies.links"/>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'

export default {
  metaInfo: {title: 'currencies'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
  },
  props: {
    auth: Object,
    currencies: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        avatar: null,
      },
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    updateCurrencies() {
       this.$inertia.put(this.route('currencies.set.rates'), {}, {preserveState: true})
    }
  },
}
</script>
