<
<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Machines Rates (in Seconds) For Estimated Batch Times</h1>
      <div>
      </div>
    </div>
    <MachineTable :machines="machines.data" @save-machine="handleSaveMachine" />
  </div>
  <!--pagination :links="labels.links"/-->
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import {adjustDate, setToToday, setToTomorrow, copyToClipboard, secondsToTime} from '@/Services/genericDataService';
import {productionTimeSeconds} from '@/Services/productionsDataService';
import Multiselect from "vue-multiselect";
import MachineTable from '@/Components/ProductionsMachineTable';

export default {
  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput,
    'multiselect': Multiselect,
    MachineTable
  },
  props: {
    auth: Object,
    machines: Object
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
    }
  },
  methods: {
    handleSaveMachine(machine) {
      this.$inertia.put(this.route('productions.machines.save'),
          {
            machine: machine,
          },
          {
            preserveScroll: true,
            preserveState: false
          })
    },
  },
}
</script>
