<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Sandbox</h1>
    <p class="mb-8 leading-normal">Welcome to the sandbox</p>
    <!--div>
    <form @submit.prevent="submit">
    <input type="text" v-model="form.name" />
    <input type="file" @input="form.avatar = $event.target.files[0]" />
    <progress v-if="form.progress" :value="form.progress.percentage" max="100">
      {{ form.progress.percentage }}%
    </progress>
    <button type="submit">Submit</button>
  </form>
      </div-->
    <form action="/sandbox" method="POST" enctype="multipart/form-data" class="form-horizontal"><!---->
      <div class="form-group">
        <label for="name" class="col-md-3 col-sm-3 col-xs-12 control-label">File: </label>
        <div class="col-md-8">
          <input type="file" name="csv_file" id="csv_file" required="True" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-3 col-sm-3 col-xs-12 col-md-offset-3" style="margin-bottom:10px;">
          <button class="btn btn-primary"><span class="glyphicon glyphicon-upload" style="margin-right:5px;"></span>Upload
          </button>
        </div>
      </div>
    </form>


  </div>


</template>

<script>
import Layout from '@/Shared/Layout'

export default {
  metaInfo: {title: 'Dashboard'},
  layout: Layout,
  props: {
    auth: Object,
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        //search: this.filters.search,
        //categoryFilters: this.filters.categoryFilters,
        name: null,
        avatar: null,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.route('sandbox'), this.form, {preserveState: true})
    },
  },
}
</script>
