<
<template>
  <div>

    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">FBA Labels Archive</h1>

      <div>
        <button class="btn-indigo ml-2" @click="handleExportTracking">
          <span>Export Hazardous Tracking IDs</span>
        </button>
      </div>

    </div>
    <div class="mt-10 flex justify-between items-center">
      <div>
        <div class="mb-5">
          Due Date:
        </div>
        <i class="fa-solid fa-calendar-days"></i>
        <v-date-picker v-model="form.date" @input="submit">
          <template v-slot="{ inputValue, inputEvents }">
            <input
                :value="inputValue"
                class="w-40 text-center bg-gray-100 cursor-pointer"
                v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <button @click="handleAdjustDate(-1)" class="btn-indigo px-3">
          <i class="fa-solid fa-minus"></i>
        </button>
        <button @click="handleAdjustDate(1)" class="btn-indigo ml-2 px-3">
          <i class="fa-solid fa-plus"></i>
        </button>
        <button class="btn-indigo ml-2" @click="handleSetToToday">Today</button>
        <!--button class="btn-indigo ml-2" @click="handleSetToTomorrow">Tomorrow</button-->
        <!--button class="btn-indigo ml-2" @click="incrementDate(2) ">+2 Days</button-->
      </div>
    </div>
    <div class="mt-10">
      ----- OR -----
    </div>
    <div class="mt-10">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class=""
                      label="Batch Code / FBA Tracking ID / UPS Tracking ID / SKU / Name"/>
        </div>
        <div class="my-5 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <!--th class="px-6 pt-6 pb-4 text-left" colspan="1"></th-->


          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>

          <th class="px-6 pt-6 pb-4">Descramble <br> Date</th>
          <!--th class="px-6 pt-6 pb-4">Descramble <br> Time</th-->
          <th class="px-6 pt-6 pb-4">Print Date</th>
          <th class="px-6 pt-6 pb-4">Audit Date</th>
          <th class="px-6 pt-6 pb-4">Title</th>
          <th class="px-6 pt-6 pb-4">Units Per Box</th>
          <th class="px-6 pt-6 pb-4">FBA ID</th>
          <th class="px-6 pt-6 pb-4">FBA Box ID</th>
          <th class="px-6 pt-6 pb-4">UPS Tracking ID</th>
          <th class="px-6 pt-6 pb-4">UPS Box</th>
          <th class="px-6 pt-6 pb-4">FBA SKU</th>
          <th class="px-6 pt-6 pb-4">LW / UPS SKU</th>
          <!--th class="px-6 pt-6 pb-4">Duplicate #</th-->
          <th class="px-6 pt-6 pb-4">LW Order ID</th>
          <th class="px-6 pt-6 pb-4">Batch Code</th>
          <th class="px-6 pt-6 pb-4">Label ID</th>
          <th class="px-6 pt-6 pb-4">Packing ID</th>
          <th class="px-6 pt-6 pb-4">Assembly ID</th>
          <th class="px-6 pt-6 pb-4 text-left" colspan="1">Emergency Print</th>
        </tr>
        <tr v-for="label in labels.data"
            :key="label.id"
            :class="{'bg-gray-300': label.status === 2}">
          <!--td class="border-t">
            <div class="px-6 py-2 flex items-center focus:text-indigo-500">
              <div v-if="isAllowedEmail">
                <button v-if="label.status===0" class="btn-indigo w-32" @click="handleRetrieveLabelPDF(label)">
                  Print
                </button>
                <button v-else-if="label.status===1" class="btn-indigo w-32 bg-gray-600"
                        @click="handleRetrieveLabelPDF(label)">
                  Reprint
                </button>
                <button v-if="label.status!=2" class="btn-indigo ml-2"
                        @click="markLabelAsShipped(label)">
                  Mark as Shipped
                </button>
              </div>
            </div>
          </td-->


          <td class="border-t">
            <div class="px-6 py-4 flex items-center justify-center">
              <i v-if="label.hazardous === 'Y'" class="fa-solid fa-radiation text-red-600"></i>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ label.status_name }}
            </div>
          </td>

                <!-- New due date field -->
          <td class="border-t">
            <div class="px-6 flex items-center">
              <v-date-picker
                  v-model="label.due_date"
                  :masks="{ input: ['YYYY-MM-DD'] }"
                  @input="handleSaveLabel(label)"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      :value="inputValue"
                      class="border p-2 rounded w-32 bg-gray-100"
                      v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ label.created.substring(0, 10) }}
            </div>
          </td>
          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ label.created.substring(11, 16) }}
            </div>
          </td-->

          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              <span v-if="label.printed_timestamp">
              {{ label.printed_timestamp.substring(0, 10) }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              <span v-if="label.audit_timestamp">
              {{ label.audit_timestamp.substring(0, 10) }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="py-4 px-6 flex items-center w-96" tabindex="-1">
              <span class="truncate">
              {{ label.sku_title }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="py-4 px-6 flex items-center" tabindex="-1">
              <span class="truncate">
              {{ label.units_per_box }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_tracking_id.substring(0, 12) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_tracking_id }}
            </div>
          </td>
          <td class="border-t">
            <div v-if="label.ups_tracking_id"
                 class="px-6 py-4 flex items-center text-indigo-600 cursor-pointer"
                 @click="copyToClipboard(label.ups_tracking_id )">
              <i class="fa-regular fa-copy mr-2"></i>
              {{ label.ups_tracking_id }}
            </div>
            <div v-else
                 class="px-6 py-4 flex items-center">
              N/A
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.box_number }} of {{ label.box_total }}
            </div>
          </td>


          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_sku }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.lw_sku }}
            </div>
          </td>

          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.dup_rank }}
            </div>
          </td-->
          <td class="border-t">
            <a class="px-6 py-4 flex items-center text-indigo-400" tabindex="-1"
               :href="'https://www.linnworks.net/#/app/ViewOrder/' +  label.lw_order_id" target="_blank">
              {{ label.lw_order_id }}
            </a>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.batch_code }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.id }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.packing_id }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.assembly_id }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-2 flex items-center focus:text-indigo-500">
              <button v-if="isAllowedEmail" class="btn-indigo bg-red-400"
                      @click="handleEmergencyRetrieveLabelPDF(label)">
                Emergency Print
              </button>
            </div>
          </td>


        </tr>
        <tr v-if="labels.data.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No labels found.</td>
        </tr>
      </table>
    </div>
  </div>
  <!--pagination :links="labels.links"/-->
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import {adjustDate, setToToday, setToTomorrow, copyToClipboard} from '@/Services/genericDataService';
import {saveLabelIndex} from '@/Services/labelsApiService';
import {retrieveLabelPDF, retrieveEmergencyLabelPDF} from '@/Services/genericApiService';

export default {

  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    labels: Object,
    filters: Object,
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        search: this.filters.search,
        date: this.filters.date,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.get(this.route('labels'), this.form, {preserveState: false})
    },
    handleExportTracking() {
      const params = new URLSearchParams({
        date: this.form.date,  // Assuming `this.date` holds the date value you want to pass
      }).toString();
      window.location.href = this.route('labels.export-tracking') + '?' + params;
    },
    /* Removed due to naughtiness...
    markLabelAsShipped(label) {
      if (confirm(
          'WARNING! \n\n' +
          'Labels should be marked as shipped via the Audit screen. This should be a last a resort.  \n\n' +
          'Are you sure you want to mark this label as shipped?'
      )) {
        this.$inertia.put(this.route('labels.mark-as-shipped', label.id), {filters: this.filters}, {preserveState: false})
      }
    },
     */
    handleRetrieveLabelPDF(label) {
      if (label.status > 0) {
        if (confirm('WARNING!!! \n\n' +
            'This label has already been printed. Are you sure you want to reprint it?'
        )) {
          retrieveLabelPDF(this.route, label);
        }
      } else {
        retrieveLabelPDF(this.route, label);
      }
    },
    handleEmergencyRetrieveLabelPDF(label) {
      if (confirm('WARNING!!! \n\n' +
          'This button should only be used in emergencies! This bypasses the audit, and will tie it to a packing batch!'
      )) {
        retrieveEmergencyLabelPDF(this.route, label);
      }
    },
    handleAdjustDate(days) {
      this.form.date = adjustDate(this.form.date, days);
      this.submit();
    },
    handleSetToToday() {
      this.form.date = setToToday()
      this.submit();
    },
    handleSetToTomorrow() {
      this.form.date = setToTomorrow()
      this.submit();
    },
    handleCopyToClipboard(text) {
      copyToClipboard(text)
    },
    handleSaveLabel(label) {
      saveLabelIndex(label, this.filters);
    }
    /*
    adjustDate(days) {
      let currentDate = new Date(this.filters.date);
      currentDate.setDate(currentDate.getDate() + days);
      this.filters.date = this.formatDate(currentDate);
      this.submitDate();
    },
    formatDate(date) {
      return date.toISOString().substr(0, 10);
    },
    setToToday() {
      let today = new Date();
      this.filters.date = this.formatDate(today);
      this.submitDate();
    },
    setToTomorrow() {
      let today = new Date();
      today.setDate(today.getDate() + 1); // this adds a day to the current date
      this.filters.date = this.formatDate(today);
      this.submitDate();
    },
    copyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log(`Copying text command was ${msg}`);
      } catch (err) {
        console.error('Could not copy text: ', err);
      }

      document.body.removeChild(textArea);
    }
    */
  },
  computed: {
    isAllowedEmail() {
      const allowedEmails = ['andrew.j.stainer@gmail.com', 'thijs@nikura.com', 'philip@nikura.com', 'admin@nikura.com'];
      return allowedEmails.includes(this.auth.user.email);
      //return true
    }
  },
}
</script>
