import { App, plugin } from '@inertiajs/inertia-vue'
import Vue from 'vue'
import axios from "axios";
import PortalVue from 'portal-vue'
import { InertiaProgress } from '@inertiajs/progress/src'
// AJS additions
import VCalendar from 'v-calendar';


axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

Vue.config.productionTip = true;

Vue.use(plugin);
Vue.use(PortalVue);
//AJS additions
Vue.use(VCalendar, {
  //componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  // ...other defaults
});

let customRoute = (...args) => {
    args[0] = 'meerkat:' + args[0];
    return window.reverseUrl(...args);
}

Vue.mixin({ methods: { route: customRoute } });

InertiaProgress.init();

const app = document.getElementById('app');
// we are getting the initialPage from a rendered json_script
const page = JSON.parse(document.getElementById("page").textContent);

import Index from "./Pages/Core/Index";
import Sandbox from "./Pages/Core/Sandbox";
import Suppliers from "./Pages/Suppliers/Index";
import SupplierCreate from "./Pages/Suppliers/Create";
import SupplierEdit from "./Pages/Suppliers/Edit";
import Currencies from "./Pages/Currencies/Index";
import CurrencyCreate from "./Pages/Currencies/Create";
import CurrencyEdit from "./Pages/Currencies/Edit";
import Reviews from "./Pages/Reviews/Index";
import Units from "./Pages/Units/Index";
import UnitCreate from "./Pages/Units/Create";
import UnitEdit from "./Pages/Units/Edit";
import Postage from "./Pages/Postage/Index";
import PostageCreate from "./Pages/Postage/Create";
import PostageEdit from "./Pages/Postage/Edit";
import Bundles from "./Pages/Bundles/Index";
import BundlesLocation from "./Pages/Bundles/Location.vue";
import BundleEdit from "./Pages/Bundles/Edit";
import BundleAddcomponent from "./Pages/Bundles/Addcomponent";
import Customers from "./Pages/Customers/Index";
import CustomerEdit from "./Pages/Customers/Edit";
import CustomerCreate from "./Pages/Customers/Create";
import OrderCreate from "./Pages/Orders/Create";
import OrderEdit from "./Pages/Orders/Edit";
import OrderAddcomponent from "./Pages/Orders/Addcomponent";
import Scalings from "./Pages/Scalings/Index";
import ScalingCreate from "./Pages/Scalings/Create";
import ScalingEdit from "./Pages/Scalings/Edit";
import ScalingAddcomponent from "./Pages/Scalings/Addcomponent";
import Prices from "./Pages/Prices/Index";
import Batches from "./Pages/Batches/Index";
import Assemblies from "./Pages/Assemblies/Index";
import AssembliesAdmin from "./Pages/Assemblies/Admin";
import AssembliesView from "./Pages/Assemblies/View";
import AssembliesArchive from "./Pages/Assemblies/Archive";
import AssembliesUpload from "./Pages/Assemblies/Upload";
import AssemblyAddcomponent from "./Pages/Assemblies/Addcomponent";
import AssemblyEdit from "./Pages/Assemblies/Edit";
import AssemblyComplete from "./Pages/Assemblies/Complete";
import AssemblyPrintpicklist from "./Pages/Assemblies/Printpicklist";
import Packings from "./Pages/Packings/Index";
import PackingsAdmin from "./Pages/Packings/Admin";
import PackingsView from "./Pages/Packings/View";
import PackingsArchive from "./Pages/Packings/Archive";
import PackingsShipped from "./Pages/Packings/Shipped";
import PackingAddcomponent from "./Pages/Packings/Addcomponent";
import PackingEdit from "./Pages/Packings/Edit";
import ProductionsAdmin from "./Pages/Productions/Admin";
import ProductionAddcomponent from "./Pages/Productions/Addcomponent";
import ProductionsCompleted from "./Pages/Productions/Completed";
import ProductionsView from "./Pages/Productions/View";
import ProductionsUpload from "./Pages/Productions/Upload";
import ProductionMachines from "./Pages/Productions/Machines";
import ShippingsView from "./Pages/Shippings/View";
import ShippingsPlanner from "./Pages/Shippings/Planner";
import CustomLabelCreate from "./Pages/CustomLabels/Create";
import CustomLabelAddcomponent from "./Pages/CustomLabels/Addcomponent";

import DescramblerHazardous from "./Pages/Descrambler/Descrambler";
import DescramblerNonHazardous from "./Pages/Descrambler/NHDescrambler";
import DescramblerEUHazardous from "./Pages/Descrambler/EUHDescrambler";

import LabelsSearch from "./Pages/Labels/Search";
import LabelsAudit from "./Pages/Labels/Audit";
import Labels from "./Pages/Labels/Index";
import LabelsUnprinted from "./Pages/Labels/Unprinted";

import FBAOrderconverter from "./Pages/FBA/Orderconverter";
import FBASplitpackaging from "./Pages/FBA/Splitpackaging";
import FBAAvask from "./Pages/FBA/Avask";

import FBADgngenerator from "./Pages/FBA/Dgngenerator";
import Login from "./Pages/Auth/Login";
import QuickAssemblyCreate from "./Pages/QuickAssemblies/Create";

import Checklists from "./Pages/Checklists/Index";
import ChecklistCreate from "./Pages/Checklists/Create";

import Inventory from "./Pages/Inventory/Index";


  //!!!! Only add stuff here if there is need for specific vue page



const pages = {
  'Login': Login,
  'Index': Index,
  'Sandbox': Sandbox,
  'Suppliers': Suppliers,
  'Suppliers.Edit': SupplierEdit,
  'Suppliers.Create':SupplierCreate,
  'Currencies': Currencies,
  'Currencies.Edit': CurrencyEdit,
  'Currencies.Create':CurrencyCreate,
  'Reviews': Reviews,
  'Units': Units,
  'Units.Edit': UnitEdit,
  'Units.Create':UnitCreate,
  'Postage': Postage,
  'Postage.Edit': PostageEdit,
  'Postage.Create':PostageCreate,
  'Bundles': Bundles,
  'Bundles.Location': BundlesLocation,
  'Bundles.Edit': BundleEdit,
  //'Bundles.Create': BundleCreate,
  'Bundles.Addcomponent': BundleAddcomponent,
  'Orders.Edit': OrderEdit,
  'Orders.Create': OrderCreate,
  'Orders.Addcomponent': OrderAddcomponent,
  'Scalings': Scalings,
  'Scalings.Edit': ScalingEdit,
  'Scalings.Create': ScalingCreate,
  'Scalings.Addcomponent': ScalingAddcomponent,
  'Customers': Customers,
  'Customers.Edit': CustomerEdit,
  'Customers.Create': CustomerCreate,
  'Prices': Prices,
  //'Prices.Edit': PriceEdit,
  //'Prices.Create': PriceCreate,
  'Batches': Batches,
  'Assemblies': Assemblies,
  'Assemblies.Admin': AssembliesAdmin,
  'Assemblies.View': AssembliesView,
  'Assemblies.Addcomponent': AssemblyAddcomponent,
  'Assemblies.Edit': AssemblyEdit,
  //'Assemblies.Create': AssemblyCreate,
  'Assemblies.Complete': AssemblyComplete,
  'Assemblies.Printpicklist': AssemblyPrintpicklist,
  'Assemblies.Archive': AssembliesArchive,
  'Assemblies.Upload': AssembliesUpload,
  'Packings': Packings,
  'Packings.Admin': PackingsAdmin,
  'Packings.View': PackingsView,
  'Packings.Addcomponent': PackingAddcomponent,
  'Packings.Edit': PackingEdit,
  'Packings.Archive': PackingsArchive,
  'Packings.Shipped': PackingsShipped,
  'Shippings.View': ShippingsView,
  'Shippings.Planner': ShippingsPlanner,
  'Productions.Admin': ProductionsAdmin,
  'Productions.Addcomponent': ProductionAddcomponent,
  'Productions.Completed': ProductionsCompleted,
  'Productions.View': ProductionsView,
  'Productions.Upload': ProductionsUpload,
  'Productions.Machines': ProductionMachines,
  'CustomLabel.Create': CustomLabelCreate,
  'CustomLabel.Addcomponent': CustomLabelAddcomponent,

  'Descrambler.Hazardous': DescramblerHazardous,
  'Descrambler.Non-Hazardous': DescramblerNonHazardous,
  'Descrambler.EU-Hazardous': DescramblerEUHazardous,

  'Labels.Search': LabelsSearch,
  'Labels.Audit': LabelsAudit,
  'Labels': Labels,
  'Labels.Unprinted': LabelsUnprinted,

  'FBA.Orderconverter': FBAOrderconverter,
  'FBA.Splitpackaging': FBASplitpackaging,
  'FBA.Avask': FBAAvask,
  'FBA.Dgngenerator': FBADgngenerator,

  'QuickAssemblies.Create': QuickAssemblyCreate,

  'Checklists': Checklists,
  'Checklists.Create':ChecklistCreate,

  'Inventory': Inventory,

  //!!!! Only add stuff here if there is need for specific vue page (you can have URL with no vue page)

}

new Vue({
  render: h => h(App, {
    props: {
      initialPage: page,
      resolveComponent: (name) => {
        console.log("resolveComponent ", name, pages[name])
        return pages[name];
      },
    },
  }),
}).$mount(app)
