<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Assembly Admin</h1>
      <div class="flex">
        <div class="btn-indigo mr-4 cursor-pointer" @click="handleUploadAssemblies">
          <span>Bulk Upload</span>
        </div>
        <div class="btn-indigo cursor-pointer" @click="handleCreateAssembly">
          <span>Add Batch</span>
        </div>
      </div>
    </div>

    <CategoryToggleHeader
        :filters="filters"
        :categoryCounts="categoryCounts"
        @category-toggled="handleToggleCategory"
    />
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <!--div class="my-15">
          <div class="mb-5">Category:</div>
          <span v-for="category in categories">
                <input type="checkbox" :id="category[1]" :value="category[0]" v-model="form.categoryFilters">
                <label class="mr-5" :for="category[1]">{{ category[1] }}</label>
            </span>
        </div-->
        <div class="my-5">
          <div class="my-5">Assignment:</div>
          <span v-for="user in users">
                <input type="checkbox" :id=user.username :value=user.id v-model="form.userFilters">
                <label class="mr-5" :for=user.username>{{ user.username }}</label>
            </span>
        </div>
        <div class="my-10">
          <div class="my-5">Due date:</div>
          <input type="checkbox" :id="1" value="today" v-model="form.dateFilters">
          <label class="mr-5" :for="1">Today</label>
          <input type="checkbox" :id="1" value="tomorrow" v-model="form.dateFilters">
          <label class="mr-5" :for="1">Tomorrow</label>
        </div>

        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
          <!--button class="btn-indigo ml-4" @click="resetFilters">
            <span>Remove Filters</span>
          </button-->
        </div>
      </form>
    </div>
    <!--div>
      <i class="fa-solid fa-truck"></i> - Ship Today
      <i class="fa-solid fa-box"></i> - Push To Packing
      <i class="fa-solid fa-ban"></i>  - Stock Block Override
    </div-->
    <!--div>
      * Hover over checkbox headers for prompts
    </div-->
    <AssembliesAdminTable :assemblies="assemblies.data" :users="users" :boxes="boxes" :categories="categories"
                          :options="options" :filters="filters"/>
    <pagination :links="assemblies.links"/>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import draggable from 'vuedraggable'
import AssembliesAdminTable from '@/Components/AssembliesAdminTable';
import CategoryToggleHeader from '@/Components/CategoryToggleHeader';
import {toggleCategoryAdmin, createAssemblyAdmin} from '@/Services/assembliesApiService';

export default {
  metaInfo: {title: 'assemblies'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    draggable,
    AssembliesAdminTable,
    CategoryToggleHeader
  },
  props: {
    auth: Object,
    assemblies: Object,
    users: Object,
    filters: Object,
    categories: Object,
    boxes: Array,
    categoryCounts: Array
  },
  data() {
    return {
      items: '',
      user_id: this.auth.user.id,
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
        categoryFilters: this.filters.categoryFilters,
        userFilters: this.filters.userFilters,
        dateFilters: this.filters.dateFilters,
        category: this.filters.category
      },
    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
  */
  methods: {
    submit() {
      this.form.page = 1
      //Below must be get, not reload
      this.$inertia.get(this.route('assemblies.admin'), this.form)
    },
    deleteAssembly(assembly) {
      if (confirm('Are you sure you want to delete this assembly?')) {
        this.$inertia.delete(this.route('assemblies.destroy', assembly.id),
            {
              preserveScroll: true
            })
      }
    },
    handleCreateAssembly() {
      createAssemblyAdmin(this.filters);
    },
    handleUploadAssemblies() {
       this.$inertia.get(this.route('assemblies.upload'))
    },
    handleToggleCategory(categoryKey) {
      toggleCategoryAdmin(categoryKey, this.filters);
    }
  },
}
</script>
