<script>

export function savePacking($inertia, route, packing, filters) {
  $inertia.put(route('shippings.planner.packing.update'),
      {
        packing,
        filters
      },
      {
        preserveScroll: true,
        preserveState: true,
      });
}

export function saveAssembly($inertia, route, assembly, filters) {
  $inertia.put(route('shippings.planner.assembly.update'),
      {
        assembly,
        filters
      },
      {
        preserveScroll: true,
        preserveState: false
      });
}

export function shipAll($inertia, route, packings, filters) {
  $inertia.put(route('shippings.planner.ship.all'),
      {
        packings,
        filters
      },
      {
        preserveScroll: true,
        preserveState: false
      });
}
</script>

