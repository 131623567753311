<template>
  <div class="">
    <div class="mb-4">
      <div class="flex items-center group py-3" :href="route('packings.admin')">
        <div class="text-gray-500">ADMIN</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('productions.admin','small')">
        <i :class="['fas fa-gear mr-2 ', isUrl('productions/admin/small') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('productions/admin/small') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Small
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('productions.admin','big')">
        <i :class="['fas fa-gears mr-2 ', isUrl('productions/admin/big') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('productions/admin/large') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Big
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('productions.admin','hand')">
        <i :class="['fas fa-hand mr-2 ', isUrl('productions/admin/hand') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('productions/admin/hand') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Hand
        </div>
      </inertia-link>
    </div>
     <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('checklists')">
        <i :class="['fas fa-square-check mr-2 ', isUrl('checklists') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('checklists') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Logs
        </div>
      </inertia-link>
    </div>
    <div class="mt-10 mb-4">
      <div class="flex items-center group py-3" :href="route('packings.admin')">
        <div class="text-gray-500">USER VIEWS</div>
      </div>
    </div>
     <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('productions.view')">
        <i :class="['fas fa-user mr-2 ', isUrl('productions/view') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('productions/view') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Tablet View
        </div>
      </inertia-link>
    </div>
    <div class="mt-10 mb-4">
      <div class="flex items-center group py-3" :href="route('packings.admin')">
        <div class="text-gray-500">AUDIT</div>
      </div>
    </div>
     <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('productions.completed')">
        <i :class="['fas fa-check mr-2 ', isUrl('productions/completed') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('productions/completed') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Completed
        </div>
      </inertia-link>
    </div>
      <div class="mt-10 mb-4">
      <div class="flex items-center group py-3" :href="route('packings.admin')">
        <div class="text-gray-500">MACHINES</div>
      </div>
    </div>
     <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('productions.machines')">
        <i :class="['fas fa-gears mr-2 ', isUrl('productions/machines') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('productions/machines') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Rates
        </div>
      </inertia-link>
    </div>

  </div>
</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl'],
  methods: {},
}
</script>
