<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('units')">Units</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="unit.deleted_at" class="mb-6" @restore="restore">
      This unit has been deleted.
    </trashed-message>

    <form @submit.prevent="submit">
      <h2 class="mb-8 font-bold text-2xl">Details</h2>
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name"/>
          <text-input v-model="form.sku" :error="errors.sku" class="pr-6 pb-8 w-full lg:w-1/2" label="SKU"/>
          <select-input v-model="form.supplier_id" :error="errors.supplier_id" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Supplier">
            <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{ supplier.name }}</option>
          </select-input>
          <select-input v-model="form.category" :error="errors.category" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Category">
            <option v-for="category in categories" :key="category[0]" :value="category[0]">{{ category[1] }}</option>
          </select-input>
          <select-input v-model="form.grade" :error="errors.grade" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Grade">
            <option v-for="grade in grades" :key="grade[0]" :value="grade[0]">{{ grade[1] }}</option>
          </select-input>

          <!--text-input v-model="form.freight_cost" :error="errors.freight_cost"
                     class="pr-6 pb-8 w-full lg:w-1/2" label="Freight Cost (not used at unit level yet)"/-->
          <text-input v-model="form.barcode" :error="errors.barcode" class="pr-6 pb-8 w-full lg:w-1/2" label="Barcode"/>
        </div>
      </div>
      <h2 class="mt-8 mb-8 font-bold text-2xl">Purchase Quantity & Cost</h2>
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
                    <text-input v-model="form.cost" :error="errors.cost" class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Cost Ex VAT (In Supplier's Currency)"/>
          <text-input v-model="form.purchase_quantity" :error="errors.purchase_quantity"
                      class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Quantity"/>
          <select-input v-model="form.measurement" :error="errors.measurement" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Units of Measure">
            <option v-for="measurement in measurements" :key="measurement[0]" :value="measurement[0]">{{
                measurement[1]
              }}
            </option>
          </select-input>

          <text-input  v-if="['g','ml'].includes(form.measurement)" v-model="form.density" :error="errors.density" class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Density (kg/L)"/>

          <div class = "w-full mb-8">
            * Density is only required if UoM is grams or ml<br>
            * Example purchase quantities (theoretical): <br>
              &nbsp&nbsp - Magnificent Moksha Oil - $22 / 1000 grams <br>
              &nbsp&nbsp - Liberating Lebermuth Oil - $34 / 454 grams <br>
              &nbsp&nbsp - Beautiful Buck Oil - £19 / 1000 grams <br>
          </div>


        </div>
      </div>
      <div class="mt-10">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Update Details</loading-button>
      </div>
    </form>
    <div class="mt-10">
      <button v-if="!unit.deleted_at" class="btn-indigo bg-red-500" type="button" @click="destroy">Delete Unit</button>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    unit: Object,
    suppliers: Array,
    categories: Object,
    measurements: Object,
    grades: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        name: this.unit.name,
        sku: this.unit.sku,
        category: this.unit.category,
        supplier_id: this.unit.supplier_id,
        cost: this.unit.cost,
        grade: this.unit.grade,
        density: this.unit.density,
        measurement: this.unit.measurement,
        purchase_quantity: this.unit.purchase_quantity,
        barcode: this.unit.barcode,
        //freight_cost: this.unit.freight_cost,
      }
    }
  },
  methods: {
    submit() {
      this.$inertia.put(this.route('units.update', this.unit.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this unit?')) {
        this.$inertia.delete(this.route('units.destroy', this.unit.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this unit?')) {
        this.$inertia.put(this.route('units.restore', this.unit.id))
      }
    },
  },
}
</script>
