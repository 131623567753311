<template>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Batch #</th>
          <th class="px-6 pt-6 pb-4">Completed</th>
          <th class="px-6 pt-6 pb-4">Estimated</th>
          <th class="px-6 pt-6 pb-4">Actual</th>
          <!--th class="px-6 pt-6 pb-4">Excess Time</th-->
          <th class="px-6 pt-6 pb-4">User</th>
          <th class="px-6 pt-6 pb-4">Helper</th>
          <th class="px-6 pt-6 pb-4">Machine</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Oil</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">10ml</th>
          <th class="px-6 pt-6 pb-4">30ml</th>
          <th class="px-6 pt-6 pb-4">50ml</th>
          <th class="px-6 pt-6 pb-4">100ml</th>
          <th class="px-6 pt-6 pb-4">250</th>
          <th class="px-6 pt-6 pb-4">500</th>
          <th class="px-6 pt-6 pb-4">1000</th>
          <th class="px-6 pt-6 pb-4">5000</th>
          <th class="px-6 pt-6 pb-4">Issues</th>
          <th class="px-6 pt-6 pb-4">Comments</th>
          <th class="px-6 pt-6 pb-4">ID</th>
        </tr>
        <tr v-for="production in productions" :key="production.id"
            :class="{'bg-red-200': production.timer > handleSecondsToTime(handleProductionTimeSeconds(production))}">
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.batch_code }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if=" production.completed_timestamp  != null &&  production.completed_timestamp  != '' ">
                  {{ production.completed_timestamp.substring(0, 16) }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ handleSecondsToTime(handleProductionTimeSeconds(production)) }}
              <!--{{Math.floor(productionTime(production) / 60) }}:{{(parseFloat(productionTime(production) % 60).toFixed() + '00').substring(0,2)}}-->
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.timer }}
            </div>
          </td>
          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ minutesToTime(production.total_seconds / 60 - productionMins(production)) }}
            </div>
          </td-->
          <td class="border-t w-40">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.user }}
            </div>
          </td>
           <td class="border-t w-40">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.helper }}
            </div>
          </td>
          <td class="border-t w-40">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.machine_type_name }} #{{ production.list.slice(-1) }}
            </div>
          </td>
          <td class="border-t w-40">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.oil_sku }}
            </div>
          </td>
          <td class="border-t w-96">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.oil_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.category_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_10 }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_30 }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_50 }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_100 }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_250 }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_500 }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_1000 }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.size_5000 }}
            </div>
          </td>
            <td class="border-t">
          <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
          <div class="align-middle px-2">
            <multiselect class="cursor-pointer" v-model="production.issue_tags" :options="issue_tag_options"
                         :multiple="true"
                         :searchable="false"
                          :disabled="true">
            </multiselect>
          </div>
        </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.comments }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ production.id }}
            </div>
          </td>


        </tr>
        <tr v-if="productions.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No completed production batches found.</td>
        </tr>
      </table>
    </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import {adjustDate, setToToday, setToTomorrow, copyToClipboard, secondsToTime} from '@/Services/genericDataService';
import {productionTimeSeconds} from '@/Services/productionsDataService';
import Multiselect from "vue-multiselect";

export default {
  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput,
    'multiselect': Multiselect,
  },
  props: {
    productions: Array,
    machine_times: Object
  },
  data() {
    return {
      issue_tag_options: [
        'Label Printer',
        'Machine Failure',
        'Oil Stock',
        'Machine Failure',
        'Spillage',
      ],
    }
  },
  methods: {
    // Estimated Time Methods
    handleSecondsToTime(seconds) {
      return secondsToTime(seconds)
    },
    handleProductionTimeSeconds(production) {
      return productionTimeSeconds(production, this.machine_times)
    },
  },
}
</script>
