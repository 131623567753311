<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('postage')">Postage</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="postage.deleted_at" class="mb-6" @restore="restore">
      This postage has been deleted.
    </trashed-message>
    <h2 class="mb-8 font-bold text-2xl">Details</h2>
    <form @submit.prevent="submit">
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <select-input v-model="form.channel" :error="errors.channel" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Channel">
            <option v-for="channel in channels" :key="channel[0]" :value="channel[0]">{{ channel[1] }}</option>
          </select-input>
          <select-input v-model="form.category" :error="errors.category" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Category">
            <option v-for="category in categories" :key="category[0]" :value="category[0]">{{ category[1] }}</option>
          </select-input>
          <select-input v-model="form.unit_id" :error="errors.unit_id" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Postage unit">
            <option v-for="unit in postage_units" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
          </select-input>
        </div>
      </div>
      <div class="mt-10">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Update Details</loading-button>
      </div>
    </form>
    <div class="mt-10">
      <button v-if="!postage.deleted_at" class="btn-indigo bg-red-500" type="button" @click="destroy">Delete Postage
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    postage_units: Object,
    postage: Object,
    categories: Object,
    channels: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        channel: this.postage.channel,
        category: this.postage.category,
        unit_id: this.postage.unit_id,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.put(this.route('postage.update', this.postage.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this postage?')) {
        this.$inertia.delete(this.route('postage.destroy', this.postage.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this postage?')) {
        this.$inertia.put(this.route('postage.restore', this.postage.id))
      }
    },
  },
}
</script>
