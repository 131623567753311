<template>
  <div class="mt-12 bg-white rounded shadow overflow-x-auto">
    <table class="w-full whitespace-no-wrap">
      <tr class="text-left font-bold">
        <th class="border-l" colspan="3"><!-- Complete batch--></th>
        <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-truck cursor-help" title="Ship Today"></i></th>
        <th class="px-6 pt-6 pb-4 border-l">Tags</th>
        <th class="px-6 pt-6 pb-4">Due Date</th>
        <th class="px-6 pt-6 pb-4">Username</th>
        <th class="px-6 pt-6 pb-4">Category</th>
        <th class="px-6 pt-6 pb-4">Name</th>
        <th class="px-6 pt-6 pb-4">SKU</th>
        <th class="px-6 pt-6 pb-4">Quantity Required</th>
        <th class="px-6 pt-6 pb-4">Quantity To Scan</th>
        <th class="px-6 pt-6 pb-4" colspan="1">% Verified</th>
        <th class="px-6 pt-6 pb-4" colspan="1"># Verified</th>
        <th class="px-6 pt-6 pb-4" colspan="1">Created</th>
        <th class="px-6 pt-6 pb-4" colspan="1">Started</th>
        <th class="px-6 pt-6 pb-4" colspan="1">Assembled</th>
        <th class="px-6 pt-6 pb-4" colspan="1">Audited</th>
        <th class="px-6 pt-6 pb-4" colspan="1">Batch Code</th>
        <th class="px-6 pt-6 pb-4" colspan="1">Assembly ID</th>


        <!--th class="px-6 pt-6 pb-4" colspan="2">Status</th-->

      </tr>
      <!--tr v-for="assembly in assemblies" :key="assembly.id" v-if="assembly.user_id != null"-->
      <tr v-for="assembly in assemblies" :key="assembly.id">
        <td class="border-t" colspan="1">
          <!-- Start of status logic -->
          <div v-if="assembly.bundle_sku == null || assembly.category == ''"
               class="px-6 flex items-center focus:text-indigo-500">
            <!-- Empty if no SKU or category selected -->
          </div>
          <div v-else-if="assembly.status == 3 && assembly.category == 'ih'"
               class="px-6 flex items-center focus:text-indigo-500">
            <i class="fas fa-check text-gray-600"></i>
          </div>
          <!-- If packing ID has been created -->
          <div v-else-if="assembly.packing_id != null"
               class="px-6 flex items-center focus:text-indigo-500">
            <i class="fa-solid fa-boxes-stacked text-gray-400"></i>
          </div>
          <div v-else-if="assembly.status == 3 && assembly.category != 'ih'  && assembly.packing_id == null"
               class="px-6 flex items-center focus:text-indigo-500 cursor-pointer" @click="pushToPacking(assembly)">
            <i class="fa-solid fa-boxes-stacked text-indigo-400"></i>
          </div>
          <div v-else-if="assembly.status == 2"
               class="px-6 flex items-center focus:text-indigo-500 cursor-pointer" tabindex="-1"
               @click="finalAudit(assembly)">
            <i class="fas fa-barcode text-indigo-400"></i>
          </div>
          <inertia-link v-else class="px-6 flex items-center focus:text-indigo-500"
                        :href="route('assemblies.edit', assembly.id)" tabindex="-1">
            <i class="fas fa-play text-indigo-400"></i>
          </inertia-link>
          <!-- End of status logic -->
        <td class="border-t" colspan="1">
          <div class="px-6 flex items-center focus:text-indigo-500 cursor-pointer" tabindex="-1">
            <!--a :href="'/assemblies/printlabel/1'"-->
            <a :href="route('assemblies.printlabel', assembly.id)">
              <i class="fas fa-tag text-indigo-400"></i>
            </a>
          </div>
        </td>
        <td class="border-t" colspan="1">
          <div class="px-6 flex items-center focus:text-indigo-500 cursor-pointer" tabindex="-1">
            <!--a :href="'/assemblies/printlabel/1'"-->
            <a :href="route('assemblies.printpicklist', assembly.id)" target="_blank">
              <i class="fas fa-list text-indigo-400"></i>
            </a>
          </div>
        </td>
        <td class="border-t " colspan="1">
          <div v-if="assembly.category.indexOf('af') === 0 || assembly.category=='ae'"
               class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
            <input type="checkbox" :id="1" v-model="assembly.ship_today" @change="handleSaveAssembly(assembly)">
          </div>
        </td>
        <td class="border-t border-l w-10">
          <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
          <div class="align-middle px-2 relative px-6">
                <span v-if="handleIsDateInPast(assembly.due_date)"
                      class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
            <span v-if="assembly.is_priority"
                  class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>
          </div>
        </td>

        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
            {{ assembly.due_date.substring(0, 10) }}
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 flex items-center" tabindex="-1">
            {{ assembly.username }}
          </div>
        </td>

        <td class="border-t">
          <div class="px-6 flex items-center" tabindex="-1">
            <span v-if="assembly.category == null"> -- No category selected --</span>
            <span v-else>{{ assembly.category_name }}</span>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 flex items-center" tabindex="-1">
            <span v-if="assembly.bundle_sku == null"> -- No product selected --</span>
            <span v-else>{{ assembly.bundle_name }}</span>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 flex items-center" tabindex="-1">
            <span v-if="assembly.bundle_sku == null"> -- No product selected --</span>
            <span v-else>{{ assembly.bundle_sku }}</span>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 flex items-center" tabindex="-1">
            <div>
              {{ assembly.quantity_required }}
            </div>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
            {{ assembly.quantity_to_verify }}
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">

            {{ parseFloat(assembly.quantity_verified / assembly.quantity_to_verify * 100).toFixed(0) }}%
            <icon v-if="assembly.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
          </div>
        </td>

        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
            {{ assembly.quantity_verified }}
            <icon v-if="assembly.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
            {{ assembly.created.substring(0, 16) }}
            <icon v-if="assembly.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if="assembly.started_timestamp != null && assembly.started_timestamp != '' ">
                  {{ assembly.started_timestamp.substring(0, 16) }}
                </span>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if="assembly.assembled_timestamp != null && assembly.assembled_timestamp != ''">
                  {{ assembly.assembled_timestamp.substring(0, 16) }}
                </span>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if="assembly.audited_timestamp != null && assembly.audited_timestamp != ''">
                  {{ assembly.audited_timestamp.substring(0, 16) }}
                </span>
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
            {{ assembly.batch_code }}
          </div>
        </td>
        <td class="border-t">
          <div class="px-6 py-4 flex items-center focus:text-indigo-500">
            {{ assembly.id }}
          </div>
        </td>
      </tr>
      <!--tr v-if="filters.viewType != 'complete' && assigned_count === 0">
        <td class="border-t px-6 py-4" colspan="20">No assigned assembly batches found.</td>
      </tr-->
    </table>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import {isDateInPast} from '@/Services/genericDataService';
import {saveAssemblyView} from '@/Services/assembliesApiService';

export default {
  props: ['assemblies', 'auth', 'filters'],
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput
  },
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
    // API Calls
    handleSaveAssembly(assembly) {
      saveAssemblyView(assembly, this.filters)
    },
    pushToPacking(assembly) {
      if (this.auth.user.is_superuser) {
        if (confirm('Are you sure you want to push this to FBA packing?')) {
          this.$inertia.post(this.route('packings.admin.create'),
              {
                'assembly_id': assembly.id
              },
          )
        }
      } else {
        alert('You must be an admin to push this batch to packing')
      }
    },
    finalAudit(assembly) {
      //if (this.auth.user.is_superuser) {
      this.$inertia.get(this.route('assemblies.complete', assembly.id))
      //#} else {
      //#  alert('You must be an admin to complete the final audit scan')
      //#}
    },
    printLabel(assembly) {
      this.$inertia.get(this.route('assemblies.view', assembly.id),
          {
            'get_assembly_label': 'y',
          },
      )
    },
    /*
    saveAssembly(assembly) {
      // If channel is Shopify Or Amazon, check for LW SKU (i.e. if there is a place to push SKU to
      // If no LW SKU, ask to sync, otherwise ask to save/push
      // If channel is anything else, just ask to save
      this.$inertia.put(this.route('assemblies.view.update'),
          {
            id: assembly.id,
            ship_today: assembly.ship_today,
            filters: {
              status: this.filters.status
            }
          },
          {
            preserveScroll: true,
            //preserveState: false
          })

    },
     */
  },
}
</script>
