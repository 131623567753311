<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <!--inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('labels')">Currencies</inertia-link-->
      <!--span class="text-indigo-400 font-medium"></span--> Assembly Bulk Upload
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl pb-5">
      <!--form @submit.prevent="submit"-->
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <form method="post" enctype="multipart/form-data">
          <div class="mt-2">
            <div class="mt-6">
              <label for="amazon_csvs">Select CSV for assembly upload:</label>
              <div class="mt-5">
                <input type="file" accept=".csv" id="amazon_csvs" name="file" e>
              </div>
            </div>
            <div class="mt-10">
              <button :disabled="sending" class="btn-indigo inline-block" type="submit" name="action" value="upload">Upload
              </button>
              <div class="btn-indigo cursor-pointer inline-block ml-10 bg-gray-600" @click="handleGetCSV">
                <span>Get CSV template</span>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Currency'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    //data: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        //region: 'uk',
      },
    }
  },
  methods: {
    handleGetCSV() {
      //Below must be get, not reload
       window.location.href = this.route('assemblies.upload.get-csv');
    },
  }
}
</script>
