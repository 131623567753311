<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('suppliers')">Suppliers</inertia-link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <form @submit.prevent="submit">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name" />
          <select-input v-model="form.currency_id" :error="errors.currency_id" class="pr-6 pb-8 w-full lg:w-1/2" label="Currency">
            <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
          </select-input>
          <text-input v-model="form.freight_cost" :error="errors.freight_cost" class="pr-6 pb-8 w-full lg:w-1/2" label="Default Freight Cost (Per Unit)" />
          <text-input v-model="form.notes" :error="errors.notes" class="pr-6 pb-8 w-full" label="Notes" />
        </div>
        <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex justify-end items-center">
          <loading-button :loading="sending" class="btn-indigo" type="submit">Create Supplier</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: { title: 'Create Supplier' },
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    currencies: Array,
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        name: null,
        freight_cost: null,
        currency_id: null
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.route('suppliers.store'), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
  },
}
</script>
