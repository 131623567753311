<script>

export function saveObject($inertia, target_route, object, filters = null) {
  $inertia.put(target_route,
      {
        object,
        filters,
      },
      {
        preserveScroll: true,
        preserveState: true,
      })
}

export function retrieveLabelPDF(route, label) {
  //Update label status on vue page (update it also done on server side)
  label.status = 1
  window.open(route('labels.pdf', label.id), '_blank');
}


export function retrieveEmergencyLabelPDF(route, label) {
  //Update label status on vue page (update it also done on server side)
  label.status = 1
  window.open(route('labels.emergency-pdf', label.id), '_blank');
}




</script>

