<template>
  <div>
    <h1 class="mb-20 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('currencies')">Currencies</inertia-link>
      <span class="font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="currency.deleted_at" class="mb-6" @restore="restore">
      This currency has been deleted.
    </trashed-message>
    <h2 class="mb-8 font-bold text-2xl">Details</h2>
    <form @submit.prevent="submit">
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name" />
          <text-input v-model="form.symbol" :error="errors.symbol" class="pr-6 pb-8 w-full lg:w-1/2" label="Symbol" />
          <text-input v-model="form.conversion" :error="errors.conversion" class="pr-6 pb-8 w-full lg:w-1/2" label="Conversion to GBP" />
        </div>
      </div>
      <div class="mt-10">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Update Details</loading-button>
      </div>
    </form>
    <div class = "mt-20">
       <button v-if="!currency.deleted_at" class="btn-red" type="button" @click="destroy">Delete Currency</button>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return { title: this.form.name }
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    currency: Object,
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        name: this.currency.name,
        symbol: this.currency.symbol,
        conversion: this.currency.conversion,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.put(this.route('currencies.update', this.currency.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this currency?')) {
        this.$inertia.delete(this.route('currencies.destroy', this.currency.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this currency?')) {
        this.$inertia.put(this.route('currencies.restore', this.currency.id))
      }
    },
  },
}
</script>
