<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('assemblies')">Assemblies</inertia-link>
      {{ bundle.name }} / Complete

    </h1>
    <h2 class="mt-16 mb-8 font-bold text-2xl">Batch Details</h2>
    <div class="rounded bg-white shadow overflow-x-auto mt-10">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Assembler</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Title</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <!--th class="px-6 pt-6 pb-4">In House Barcode</th>
          <th class="px-6 pt-6 pb-4">FBA Barcode</th>
          <th class="px-6 pt-6 pb-4">FBA DE Barcode</th>
          <th class="px-6 pt-6 pb-4">FBA FR Barcode</th>
                      <th class="px-6 pt-6 pb-4">Bundle URL</th-->
        </tr>
        <tr>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ assembly.username }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ assembly.category_name }}
            </div>
          </td>
          <td class="border-t">
            <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
              {{ bundle.name }}
            </a>
          </td>
          <td class="border-t">
            <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
              {{ bundle.sku }}
            </a>
          </td>
          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ bundle.nikura_barcode }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ bundle.amazon_barcode }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ bundle.amazon_de_barcode }}
            </div>
          </td>
           <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ bundle.amazon_fr_barcode }}
            </div>
          </td-->
        </tr>
      </table>
    </div>
    <div>
      <h2 class="mt-16 mb-8 font-bold text-2xl">Stock in Pre Cobalt Bin</h2>
      <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->
      <div class="rounded bg-white shadow overflow-x-auto mt-10">
        <table class="w-full whitespace-no-wrap ">
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4">Name</th>
            <th class="px-6 pt-6 pb-4">Tool SKU</th>
            <th class="px-6 pt-6 pb-4">LW SKU</th>
            <th class="px-6 pt-6 pb-4">Stock Required</th>
            <th class="px-6 pt-6 pb-4">Stock In Pre Cobalt</th>
          </tr>
           <tr :class="{'bg-red-200': assembly.quantity_required > bundle.stock_level}">
            <td class="border-t">
              <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
                {{ bundle.name }}
              </a>
            </td>
            <td class="border-t">
              <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
                {{ bundle.sku }}
              </a>
            </td>
              <td class="border-t">
                <div class="px-6 py-4 flex items-center">
                {{ bundle.lw_sku }}
                  </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ assembly.quantity_required }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ bundle.stock_level }}
              </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="assembly.quantity_required <= bundle.stock_level || assembly.insufficient_stock_override">
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl mt-20">
        <form @submit.prevent="submit">
          <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
            <div class="pr-6 pb-6 w-full">
            <span v-if="bundle.parent_barcode != null && bundle.parent_barcode != ''">
            Scan barcode to complete batch.
            </span>
              <span v-else>
              ERROR: {{ bundle.parent_barcode_field }} is empty for this product. Add this
              <a target="_blank" rel='noopener noreferrer' class="text-indigo-400"
                 :href="route('bundles.edit', assembly.bundle_id)">
                here
              </a>
              and refresh this page to proceed.
            </span>
            </div>
            <text-input v-model="form.final_barcode_scan" :error="errors.final_barcode_scan"
                        class="pr-6 pb-8 w-full lg:w-1/2" autofocus ref="barcode"/>
          </div>
          <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex  items-center">
            <loading-button :loading="sending" class="btn-indigo" type="submit">Scan</loading-button>
          </div>
        </form>
      </div>
      <div class="mt-10 ml-6">
        * {{ assembly.category_name }} is currently setup to use {{ bundle.parent_barcode_field }} for final scan -->
        {{ bundle.parent_barcode }}.
        If this is wrong, please correct on <a target="_blank" rel='noopener noreferrer' class="text-indigo-400"
                                               :href="route('bundles.edit', assembly.bundle_id)">
        product page.
      </a>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Unit'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    assembly: Object,
    bundle: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        final_barcode_scan: null,
      },
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.barcode.focus();
    },
    submit() {
      if (this.form.final_barcode_scan == '' || this.form.final_barcode_scan == null) {
        alert('Barcode cannot be blank')
      } else if (this.form.final_barcode_scan == this.bundle.parent_barcode) {
        this.form.status = 3
        this.$inertia.put(this.route('assemblies.complete', this.assembly.id), this.form, {
          onStart: () => this.sending = true,
          onFinish: () => this.sending = false,
        })
      } else {
        alert(
            'Wrong barcode scanned - ' + this.form.final_barcode_scan + '\n' +
            'Expected barcode - ' + this.bundle.parent_barcode + '\n\n' +
            'Bundle field used - ' + this.bundle.parent_barcode_field
        )
      }
    },
  },
}
</script>
