<template>
  <div class="">
    <div class="mt-10 mb-4">
      <div class="flex items-center group py-3">
        <div class="text-gray-500">FBA PACKING</div>
      </div>
    </div>
    <div v-if="is_superuser" class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('packings.admin')">
        <i :class="['fas fa-users mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('packings/admin') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Admin</div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('packings.view')" :data="{status: '0'}">
        <i :class="['fas fa-box-open mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('packings/view') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Pack
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('labels.search')">
        <i :class="['fas fa-print mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('labels/search') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Print Shipping Labels
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('labels.audit')">
        <i :class="['fa-regular fa-square-check mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('labels/audit') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Audit
        </div>
      </inertia-link>
    </div>
    <div class="mt-10 mb-4">
      <div class="flex items-center group py-3" :href="route('packings.admin')">
        <div class="text-gray-500">FBA SHIPMENT ADMIN</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('shippings.planner')">
        <i :class="['fas fa-calendar mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('shippings/planner') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Shipment Tracker
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('shippings.view','ship_today')">
        <i :class="['fas fa-truck mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('shippings/view/ship_today') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Ship Today
        </div>
      </inertia-link>
    </div>


  </div>

</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl', 'auth'],
  methods: {},
  /*
  computed: {
    isAllowedEmail() {
      const allowedEmails = ['andrew.j.stainer@gmail.com', 'thijs@nikura.com', 'philip@nikura.com', 'admin@nikura.com'];
      return allowedEmails.includes(this.auth.user.email);
      //return true
    }
  },
   */
}
</script>
