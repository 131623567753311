<template>
  <div class="grid">
    <div class="pr-10">
      <div class="flex justify-between items-center">
        <h1 class="font-bold text-3xl">Production Batches</h1>
      </div>
      <form>
        <select-input v-model="form.user" class="pr-6 pb-8 w-48 mt-10"
                      label="User">
          <option v-for="user in users" :key="user[0]" :value="user[0]">{{ user[1] }}</option>
        </select-input>
      </form>
      <!-- Message displayed when there are no productions at all -->
  <div v-if="noProductionsOverall" class="px-2 py-5">
    <p class="">No productions batches to show.</p>
  </div>
      <div v-for="day in days">
         <div v-if="hasProductions[day]" :class="productionsAll[day].colour + ' rounded text-white p-5 mt-10'">
          {{ productionsAll[day].title }}
        </div>
        <div v-for="(productions, list) in productionsAll[day].lists"
             class="grid grid-flow-col auto-cols-max gap-10">
          <ProductionsViewTable
              v-if="hasProductions[day]"
              :view_type=view_type
              :productions="productions"
              :machine_times="machine_times"
              :auth="auth"
              :user="form.user"
              :users="users"
              :auditors="auditors"
              :categories="categories"
              :machine_types="machine_types"
              :label_status_choices="label_status_choices"
              @update-item-order="updateItemOrder"
              @update-time="updateTime"
              :machineNumber="`${list}`"
              :listName="`${day}-${list}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import ProductionsViewTable from '@/Components/ProductionsViewTable'

export default {
  metaInfo: {title: 'productions'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
    ProductionsViewTable

  },
  props: {
    auth: Object,
    productions: Object,
    users: Object,
    auditors: Object,
    filters: Object,
    categories: Object,
    label_status_choices: Object,
    machine_types: Object,
    view_type: Object,
    user: Object
  },
  data() {
    return {
      days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
      productionsAll: this.initializeProductionsAll(),
      form: {
        user: this.user,
      },
    }
  },
  methods: {
    initializeProductionsAll() {
      const days = {
        'mon': {'title': 'Monday', 'colour': 'bg-blue-400'},
        'tue': {'title': 'Tuesday', 'colour': 'bg-indigo-400'},
        'wed': {'title': 'Wednesday', 'colour': 'bg-green-400'},
        'thu': {'title': 'Thursday', 'colour': 'bg-orange-400'},
        'fri': {'title': 'Friday', 'colour': 'bg-pink-400'},
        'sat': {'title': 'Saturday', 'colour': 'bg-gray-500'}
      };
      Object.keys(days).forEach(day => {
        days[day].lists = {
          'All': this.productions.data.filter(p => p.list.includes(`${day}`)),
        };
      });
      return days;
    },
    updateTime(newTime) {
      this.timer = newTime;
    },
    selectUser() {
      this.$inertia.get(this.route('productions.view'),
          {
            user: this.form.user
          },
          {
            preserveState: false,  // Can't set to true or prices don't refresh with filters/search
          })
    },
  },
    computed: {
    // Computed property to check if there are productions for each day
    hasProductions() {
      return this.days.reduce((acc, day) => {
        acc[day] = this.productionsAll[day].lists['All'].length > 0;
        return acc;
      }, {});
    },
      noProductionsOverall() {
    return this.days.every(day => Object.values(this.productionsAll[day].lists).every(list => list.length === 0));
  }
  },
  watch: {
    'form.user'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectUser();
      }
    }
  },
}
</script>
