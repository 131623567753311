<
<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Label Search</h1>
    </div>
    <div class="mt-10 flex justify-between items-center">
    </div>
    <div class="mt-5">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" ref="focusElement" class="" label="Batch Code"/>
        </div>
        <div class="my-5 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <h2 class="mt-16 mb-8 font-bold text-2xl"> Packing Batch </h2>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Batch Code</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Product Quantity</th>

          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <!--th class="px-6 pt-6 pb-4">Packer</th-->
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Outer Box #</th>
          <th class="px-6 pt-6 pb-4">Outer Box Type</th>
          <th class="px-6 pt-6 pb-4">Inner Box #</th>
          <th class="px-6 pt-6 pb-4">Inner Box Type</th>
          <th class="px-6 pt-6 pb-4">Packing ID</th>


          <!--th class="px-6 pt-6 pb-4" colspan="2">Status</th-->

        </tr>
        <!--tr v-for="packing in packing" :key="packing.id"-->
        <tr v-if="isPackingEmpty">
          <td class="border-t px-6 py-4" colspan="20">No packing batches found.</td>
        </tr>
        <tr v-else :key="packing.id">
 <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>{{ packing.batch_code }}</span>
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.due_date.substring(0, 10) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>{{ packing.bundle_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <div>
                {{ packing.quantity_required }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ packing.status_name }}
            </div>
          </td>
          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ packing.username }}
            </div>
          </td-->
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>{{ packing.bundle_hazard }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span v-if="packing.category == null"> -- No category selected --</span>
              <span v-else>{{ packing.category_name }}</span>
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span v-if="packing.bundle_sku == null"> -- No product selected --</span>
              <span v-else>{{ packing.bundle_sku }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <div>
                {{ packing.box_quantity }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <div>
                {{ packing.box_name }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <div>
                {{ packing.inner_box_quantity }}
              </div>
            </div>
          </td>


          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <div>
                {{ packing.inner_box_name }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <div>
                {{ packing.id }}
              </div>
            </div>
          </td>

        </tr>
        <!--tr v-if="packing.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No packing batches found</td>
        </tr-->
      </table>
    </div>
    <h2 class="mt-16 mb-8 font-bold text-2xl">Matching Labels</h2>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4 text-left" colspan="1"><!-- Buttons --></th>
          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>
          <th class="px-6 pt-6 pb-4">Title</th>
          <th class="px-6 pt-6 pb-4">Units Per Box</th>
          <th class="px-6 pt-6 pb-4">FBA ID</th>
          <th class="px-6 pt-6 pb-4">FBA Box ID</th>
          <th class="px-6 pt-6 pb-4">UPS Tracking ID</th>
          <th class="px-6 pt-6 pb-4">UPS Box</th>
          <th class="px-6 pt-6 pb-4">FBA SKU</th>
          <th class="px-6 pt-6 pb-4">LW / UPS SKU</th>
          <!--th class="px-6 pt-6 pb-4">Duplicate #</th-->
          <th class="px-6 pt-6 pb-4">LW Order ID</th>
           <th class="px-6 pt-6 pb-4">Label ID</th>
          <th class="px-6 pt-6 pb-4">Packing ID</th>
          <!--th class="px-6 pt-6 pb-4">Packing ID</th-->
        </tr>
        <tr v-for="label in labels.data" :key="label.id"
              :class="{'bg-gray-300': label.status === 2}">
          <td class="border-t">
            <div class="px-6 py-2 flex items-center focus:text-indigo-500">
              <button v-if="label.status===0" class="btn-indigo w-32" @click="matchLabelToBundleAndRetrievePDF(label)">
                Print
              </button>
              <button v-else-if="label.status===1" class="btn-indigo w-32 bg-gray-600"
                      @click="matchLabelToBundleAndRetrievePDF(label)">
                Reprint
              </button>
            </div>
          </td>
               <td class="border-t">
            <div class="px-6 py-4 flex items-center justify-center">
              <i v-if="label.hazardous === 'Y'" class="fa-solid fa-radiation text-red-600"></i>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ label.status_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ label.due_date.substring(0, 10) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center w-96" tabindex="-1">
              <span class="truncate">
              {{ label.sku_title }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-centerå" tabindex="-1">
              <span class="truncate">
              {{ label.units_per_box }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_tracking_id.substring(0, 12) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_tracking_id }}
            </div>
          </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <span v-if="label.ups_tracking_id">
                  {{ label.ups_tracking_id }}
                </span>
                <span v-else>
                  N/A
                </span>
              </div>
            </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.box_number }} of {{ label.box_total }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.fba_sku }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.lw_sku }}
            </div>
          </td>
          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.dup_rank }}
            </div>
          </td-->
          <td class="border-t">
            <a class="px-6 py-4 flex items-center text-indigo-400" tabindex="-1"
               :href="'https://www.linnworks.net/#/app/ViewOrder/' +  label.lw_order_id" target="_blank">
              {{ label.lw_order_id }}
            </a>
          </td>
               <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.id }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.packing_id }}
            </div>
          </td>

          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ label.packing_id }}
            </div>
          </td-->
        </tr>
        <tr v-if="labels.data.length === 0">
          <td class="border-t px-6 py-4" colspan="20">
             <span v-if="isPackingEmpty">
                No labels found.
             </span>
            <span v-else>
                 No labels found. Have they been uploaded via <a target="_blank" class="text-indigo-400"
                                                                 :href="this.route('labels')">descrambler</a>?
              Note that only hazardous labels are currently uploaded.
               </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <!--pagination :links="labels.links"/-->
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    labels: Object,
    filters: Object,
    packing: Object,
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        //search: this.filters.search,
        //categoryFilters: [],
        search: this.filters.search,
        date: this.filters.date,
      },
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('labels.search'), this.form, {preserveState: true})
    },
    matchLabelToBundleAndRetrievePDF(label) {
        this.$inertia.put(this.route('labels.search.match-print'), {
          label: label,
          packing: this.packing,
          filters: this.filters
        }, {
          preserveState: false, // Can't set to true or prices don't refresh with filters/search
          onFinish: () => {
            window.open(this.route('labels.pdf', label.id), '_blank');
          },
        });
    }

  },
  computed: {
    isPackingEmpty() {
      return Object.keys(this.packing).length === 0;
    }
  },
  mounted() {
    this.$refs.focusElement.focus();
  },
}
</script>
