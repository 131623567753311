<script>


export function productionTimeSeconds(production, machine_times) {

  // Extract the last character from the production.list field and convert it to a number
  var machineIdentifier = parseInt(production.list.slice(-1));

  // OLD Assuming this.machine_times is an array of objects
  //var machineTimeEntry = machine_times.find(entry => entry.category === production.machine_type);

  // AJS 30/9/24 --> Find the machine time entry that matches BOTH category and the last character of list (as identifier)
  var machineTimeEntry = machine_times.find(entry =>
    entry.category === production.machine_type &&
    entry.identifier == machineIdentifier  // Using loose comparison to handle potential string/number mismatch
  );

  // If an entry is found, get its setup value; otherwise, default to 0 or some other fallback value
  var time_setup = machineTimeEntry ? parseFloat(machineTimeEntry.setup) : 0;
  var time_size_10 = machineTimeEntry ? parseFloat(machineTimeEntry.size_10) : 0;
  var time_size_30 = machineTimeEntry ? parseFloat(machineTimeEntry.size_30) : 0;
  var time_size_50 = machineTimeEntry ? parseFloat(machineTimeEntry.size_50) : 0;
  var time_size_100 = machineTimeEntry ? parseFloat(machineTimeEntry.size_100) : 0;
  var time_size_250 = machineTimeEntry ? parseFloat(machineTimeEntry.size_250) : 0;
  var time_size_500 = machineTimeEntry ? parseFloat(machineTimeEntry.size_500) : 0;
  var time_size_1000 = machineTimeEntry ? parseFloat(machineTimeEntry.size_1000) : 0;
  var time_size_5000 = machineTimeEntry ? parseFloat(machineTimeEntry.size_5000) : 0;

  // Calculate the sum of production sizes multiplied by their respective time sizes
  var sum_batch =
      production.size_10 * time_size_10 +
      production.size_30 * time_size_30 +
      production.size_50 * time_size_50 +
      production.size_100 * time_size_100 +
      production.size_250 * time_size_250 +
      production.size_500 * time_size_500 +
      production.size_1000 * time_size_1000 +
      production.size_5000 * time_size_5000;

  // Add setup time if sum_batch is greater than 0
  if (sum_batch > 0) {
    sum_batch += time_setup;
  }

  return sum_batch;
}

export function productionStartTimer(production) {
  //let production = this.productions[index];
  if (!production.timerID) {
    production.timerID = setInterval(() => {
      let timeParts = production.timer.split(':');
      let hours = +timeParts[0], minutes = +timeParts[1], seconds = +timeParts[2];
      seconds++;
      if (seconds >= 60) {
        minutes++;
        seconds = 0;
      }
      if (minutes >= 60) {
        hours++;
        minutes = 0;
      }
      production.timer = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      this.$emit('update-time', production.timer);
      //console.log(production.timer)
    }, 1000);
  }
}



</script>

