<template>
  <div>
    <table class="mt-10 whitespace-no-wrap bg-white rounded shadow col-auto text-xs">
      <tr class="text-left font-bold" slot="header">
        <th class="pt-6 pb-4" colspan="2"></th>

        <th class="px-2 pt-6 pb-4">Timer</th>
        <!--th class="px-4 pt-6 pb-4">Estimated</th-->
        <!--th class="px-4 pt-6 pb-4">Status</th-->


        <!--th class="px-4 pt-6 pb-4">Machine</th-->
        <th class="px-2 pt-6 pb-4">Category</th>
        <th class="px-2 pt-6 pb-4">Oil</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">10</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">30</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">50</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">100</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">250</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">500</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">1*</th>
        <th class="w-8 pt-6 pb-4 border-l text-center">5*</th>


        <!--th class="px-4 pt-6 pb-4">Pause #</th-->
        <th class="px-2 pt-6 pb-4 border-l">Tags</th>
        <th class="px-2 pt-6 pb-4 border-l">Issue Tags</th>
        <th class="px-2 pt-6 pb-4 border-l">Label Status</th>
        <th class="px-2 pt-6 pb-4">Auditor</th>
        <th class="px-2 pt-6 pb-4">Comments</th>
        <!--th class="px-4 pt-6 pb-4 border-l">Elapsed</th>
        <th class="px-4 pt-6 pb-4">Ongoing</th>
        <th class="px-4 pt-6 pb-4">Total</th-->
      </tr>


      <tr v-for="(production, index) in productions" :key="production.id"

          :class="getRowClass(production)">
        <!--class="item cursor-move m-4"-->

        <!--tr v-for="(production, index) in list1" :key="production.id" class="item cursor-move m-4"-->


        <td class="border-t">
          <!--div class="px-2 flex items-center cursor-pointer" @click="startTimer(index)" tabindex="-1"-->
          <div v-if="production.is_complete" class="px-4 flex items-center cursor-pointer">
            <!-- Empty if complete -->
          </div>
          <div v-else-if="production.status==0" class="px-4 flex items-center cursor-pointer"
               @click="startProduction(production)" tabindex="-1">
            <i class="fa fa-play text-indigo-400 text-xs"></i>
          </div>
          <div v-else-if="production.status==1" class="px-4 flex items-center cursor-pointer"
               @click="pauseProduction(production)" tabindex="-1">
            <i class="fa fa-pause text-indigo-400 text-xs"></i>
          </div>
          <div v-else-if="production.status==2" class="px-4 flex items-center cursor-pointer"
               @click="resumeProduction(production)" tabindex="-1">
            <i class="fa fa-arrow-rotate-right text-indigo-400 text-xs"></i>
          </div>
          <div v-else class="px-4 flex items-center">
          </div>
        </td>
        <td class="border-t">
          <!--div class="px-2 flex items-center cursor-pointer" @click="startTimer(index)" tabindex="-1"-->
          <div v-if="production.status==1 || production.status==2" class="px-6 flex items-center cursor-pointer"
               @click="completeProduction(production)" tabindex="-1">
            <i class="fa fa-check text-indigo-400 text-xs"></i>
          </div>
          <div v-else class="px-6 flex items-center">
          </div>
        </td>

        <td class="border-t">
          <div class="px-2 flex items-center" tabindex="-1">
            <div>{{ production.timer }}</div>
          </div>
        </td>


        <!--td class="border-t">
          <div class="px-4 flex items-center" tabindex="-1">
            {{ minutesToTime(productionMins(production)) }}

          </div>
        </td-->
        <!--td class="border-t">
          <div class="px-4 flex items-center" tabindex="-1">
            <div>{{ production.status_name }}</div>
          </div>
        </td-->

        <!--td class="border-t">
       <div class="px-4 flex items-center" tabindex="-1">
         <div>{{ production.machine_type_name }}</div>
       </div>
     </td-->

        <td class="border-t">
          <div class="px-2 flex items-center" tabindex="-1">
            <div>{{ production.category_name }}</div>
          </div>
        </td>
        <td class="border-t">
          <div class="px-2 flex items-center" tabindex="-1">
            <div>{{ production.oil_name }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_10 }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_30 }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_50 }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_100 }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_250 }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_500 }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_1000 }}</div>
          </div>
        </td>
        <td class="border-t border-l">
          <div class="flex items-center justify-center w-8" tabindex="-1">
            <div>{{ production.size_5000 }}</div>
          </div>
        </td>


        <!--td class="border-t">
         <div class="px-2 flex items-center justify-center" tabindex="-1">
           <div>{{ production.pause_count }}</div>
         </div>
       </td-->


        <td class="border-t border-l">
          <div v-if="production.oil_name" class="flex flex-row flex-wrap align-middle px-2">
            <!-- Static tags -->
            <span v-if="production.oil_line" class="auto-tag bg-orange-400 mt-2 mb-3 text-xs">
           {{ production.oil_line }}
          </span>
            <span v-if="production.oil_name.includes('Carrier')" class="auto-tag bg-orange-400 mt-2 mb-3 text-xs">
            Litre
          </span>
            <span v-if="production.oil_name.includes('Fragrance')" class="auto-tag bg-pink-400 mt-2 mb-3  text-xs">
            Frag
          </span>
            <span v-else-if="production.oil_name.includes('Blend')" class="auto-tag bg-purple-400 mt-2 mb-3  text-xs">
           Blend
          </span>
            <span v-if="production.oil_big_cap" class="auto-tag bg-indigo-400 mt-2 mb-3 text-xs">
            Big Cap
          </span>
            <!-- Multiselect Component -->
            <div class="flex-grow">
              <multiselect class="cursor-pointer text-xs" v-model="production.tags" :options="options" :multiple="true"
                           :searchable="false"
                           :close-on-select="true" :show-labels="false" @close="saveProduction(production)">
              </multiselect>
            </div>
          </div>
        </td>
        <td class="border-t border-l">
          <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
          <div class="align-middle px-2">
            <multiselect class="cursor-pointer" v-model="production.issue_tags" :options="issue_tag_options"
                         :multiple="true"
                         :searchable="false"
                         :close-on-select="true" :show-labels="false" @close="saveProduction(production)">
            </multiselect>
          </div>
        </td>


        <td class="border-t border-l">
          <div class="px-2 py-2 flex items-center" tabindex="-1">
            <select v-model="production.label_status" class="w-22 border rounded p-2 bg-gray-100"
                    @change="saveProduction(production)">
              <option :value="null"></option>
              <option v-for="label_status in label_status_choices" :key="label_status[0]" :value="label_status[0]">{{
                  label_status[1]
                }}
              </option>
            </select>
          </div>
        </td>
        <td class="border-t">
          <div class="px-2 flex items-center" tabindex="-1">
            <select v-model="production.auditor" class="w-22 border rounded p-2 bg-gray-100"
                    @change="saveProduction(production)">
              <option v-for="auditor in auditors" :key="auditor[0]" :value="auditor[0]">{{
                  auditor[1]
                }}
              </option>
            </select>
          </div>
        </td>
        <td class="border-t">
          <div class="px-2 flex items-center w-60" tabindex="-1">
            <input
                v-model="production.comments"
                class="w-120 border rounded p-2 bg-gray-100"
                @input="production.change_status='Y'"
                v-on:keyup.enter="saveProduction(production)"
            />
          </div>
        </td>

      </tr>
      <tr v-if="productions.length === 0">
        <td class="border-t px-6 py-4" colspan="20">No productions allocated.</td>
      </tr>
    </table>

  </div>
</template>

<script>

import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import {adjustDate, setToToday, setToTomorrow, copyToClipboard, secondsToTime} from '@/Services/genericDataService';
import {productionTimeSeconds, productionStartTimer} from '@/Services/productionsDataService';


export default {
  props: ['productions', 'auth', 'users', 'auditors', 'categories', 'machine_types', 'view_type', 'machineNumber', 'listName', 'label_status_choices', 'user', 'machine_times'],
  components: {
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
  },
  data() {
    return {
      options: [
        'Open Order',
        'No / Low Raw',
      ],
      issue_tag_options: [
        'Label Printer',
        'Machine Failure',
        'Oil Stock',
        'Machine Failure',
        'Spillage',
      ],
      timers: [], // each production will have a timer ID stored here
    }
  },
  methods: {
    saveProduction(production) {
      this.$inertia.put(this.route('productions.view.save-batch'),
          {
            user: this.user,
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false
          })
    },
    startProduction(production) {
      this.$inertia.put(this.route('productions.view.start-batch'),
          {
            user: this.user,
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false,
            onSuccess: () => {
              alert('Show time! Your batch has now started. Go forth and make some oil!')
            },
          }
      )
    },
    resumeProduction(production) {
      this.$inertia.put(this.route('productions.view.start-batch'),
          {
            user: this.user,
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false,
          }
      )
    },
    pauseProduction(production) {
      this.$inertia.put(this.route('productions.view.pause-batch'),
          {
            user: this.user,
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false
          })
    },
    completeProduction(production) {
      if (confirm('Are you sure you want to mark this batch as complete?')) {
        this.$inertia.put(this.route('productions.view.complete-batch'),
            {
              user: this.user,
              production: production,
            },
            {
              preserveScroll: true,
              preserveState: false
            })
      }
    },
    getRowClass(production) {
      if (production.bulkSelect) {
        return 'bg-indigo-100 item m-4'; // Replace 'bg-purple-500' with your actual purple class
      } else if (production.status == 3) {
        return 'bg-gray-200 item m-4';
      } else if (production.status == 2) {
        return 'bg-yellow-100 item m-4';
      } else if (production.status == 1) {
        return 'bg-green-100 item m-4';
      } else {
        return 'item m-4';
      }
    },
    // Estimated Time Methods
    handleSecondsToTime(seconds) {
      secondsToTime(seconds)
    },
    handleProductionTimeSeconds(production) {
      productionTimeSeconds(production, this.machine_times)
    },
  },
  mounted() {
    // Very smart mount
    // If in progress, calculate elapsed time (using timestamp and delta to now)
    // If not in progress, just use elapsed time
    for (let i = 0; i < this.productions.length; i++) {
      //if (this.productions[i].id === 2) {
      if (this.productions[i].in_progress) {
          productionStartTimer(this.productions[i]);
      }
    }
  }
}
</script>
