<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Logs</h1>
      <div class="flex justify-end">
        <inertia-link class="btn-indigo ml-4" :href="route('checklists.create')">
          <span>Create Log</span>
        </inertia-link>
      </div>
    </div>

    <!--div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
    </div-->
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Date</th>
          <th class="px-6 pt-6 pb-4">Type</th>
          <th class="px-6 pt-6 pb-4">Machine</th>
          <th class="px-6 pt-6 pb-4">User</th>
        </tr>
        <tr v-for="checklist in checklists.data" :key="checklist.id" class="">
            <td class="border-t">
            <div class="px-6 py-4 flex items-center">
                  {{ checklist.created.substring(0, 16) }}
            </div>
          </td>
           <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ checklist.type_name }}
            </div>
          </td>
            <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ checklist.machine_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ checklist.user }}
            </div>
          </td>

        </tr>
        <tr v-if="checklists.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">None found.</td>
        </tr>
      </table>
    </div>
    <pagination :links="checklists.links"/>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'

export default {
  metaInfo: {title: 'checklists'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
  },
  props: {
    auth: Object,
    checklists: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        avatar: null,
      },
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
  methods: {
  },
}
</script>
