<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <span>Order / </span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="order.deleted_at" class="mb-6" @restore="restore">
      This order has been deleted.
    </trashed-message>
    <form @submit.prevent="submit">
      <h2 class="mb-8 mt-16 font-bold text-2xl mt-15">Details</h2>
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Reference"/>
          <select-input v-model="form.customer_id" :error="errors.customer_id" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Customer">
            <!--option :value=null>[New Customer]</option-->
            <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
          </select-input>
          <!--select-input v-model="form.postage_id" class="pr-6 pb-8 w-full lg:w-1/2" label="Postage">
            <option value=''>None</option>
            <option v-for="postage in postage" :key="postage.id" :value="postage.id">{{ postage.name }}</option>
          </select-input-->
          <!--text-input v-if="form.customer_id==null" v-model="form.new_customer_name" :error="new_customer_name"
                      class="pr-6 pb-8 w-full lg:w-1/2" label="New Customer Name"/-->
          <!-- https://vcalendar.io/datepicker.html -->
          <label class="w-full mb-2">Date:</label>
          <v-date-picker v-model="form.date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  class="border px-2 py-3 rounded w-84 mb-10"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
      </div>
      <!--div class="mt-10">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Save</loading-button>
      </div-->


      <!--h2> Stars: {{ bundle.amazon_stars }}</h2-->


      <!-- Start of components -->
      <h2 class="mt-16 mb-8 font-bold text-2xl">Components</h2>
      <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->
      <div class="rounded bg-white shadow overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4"></th>
            <th class="px-6 pt-6 pb-4">Name</th>
            <th class="px-6 pt-6 pb-4 border-l">Quantity</th>
            <th class="px-6 pt-6 pb-4">Unit Price Inc VAT</th>
            <th class="px-6 pt-6 pb-4">Unit Price Ex VAT</th>
            <th class="px-6 pt-6 pb-4 border-l">Total Price Inc VAT</th>
            <th class="px-6 pt-6 pb-4">Total Price Ex VAT</th>
            <th class="px-6 pt-6 pb-4">Total Margin Ex VAT</th>
            <th class="px-6 pt-6 pb-4">Margin %</th>
            <th class="px-6 pt-6 pb-4 border-l">Unit Cost</th>
            <th class="px-6 pt-6 pb-4">Unit Margin</th>
            <!--th class="px-6 pt-6 pb-4 border-l">Unit Cost</th>
            <th class="px-6 pt-6 pb-4">Margin per Unit</th-->
            <th class="px-6 pt-6 pb-4 border-l">Original Unit Price Inc VAT</th>
            <!--th class="px-6 pt-6 pb-4 border-l">Cost per Unit</th-->
          </tr>
          <tr v-for="component_bundle in component_bundles" :key="component_bundle.component_object_id"
              class="hover:bg-gray-100 focus-within:bg-gray-100">
            <!-- Start of bundle section -->
            <!--td class="border-t">
              <inertia-link class="px-6 py-4 flex items-center"
                            method="delete"
                            :href="route('orders.component.destroy', order.id)"
                            :data="{
                              id: component_bundle.id
                            }"
                            preserve-scroll
                            tabindex="-1">
                <i class="fa fa-minus-circle text-gray-600"></i>
              </inertia-link>
            </td-->
            <td class="border-t">
              <div class="px-6 py-4 flex items-center " tabindex="-1">
                <div @click="deleteComponent(component_bundle)" class="cursor-pointer">
                  <i class="fa fa-minus-circle text-gray-600"></i>
                </div>
              </div>
            </td>
            <td class="border-t">
              <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                            :href="route('bundles.edit', component_bundle.component_object_id)">
                {{ component_bundle.component_name }}
              </inertia-link>
            </td>
            <td class="border-t border-l">
              <div class="mx-4">
                <input
                    class="w-20 border rounded p-2"
                    v-model="component_bundle.component_quantity"/>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-2 flex items-center"
                   tabindex="-1">
                <span class="pr-2">£</span>
                <input
                    class="w-20 border rounded p-2"
                    v-model="component_bundle.component_wholesale_price_override"
                    v-on:keyup="component_bundle.component_wholesale_price_override_ex_vat=getPriceExVat(component_bundle.component_wholesale_price_override)"
                />
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-2 flex items-center"
                   tabindex="-1">
                <span class="pr-2">£</span>
                <input
                    class="w-20 border rounded p-2"
                    v-model="component_bundle.component_wholesale_price_override_ex_vat"
                    v-on:keyup="component_bundle.component_wholesale_price_override=getPriceIncVat(component_bundle.component_wholesale_price_override_ex_vat)"
                />
              </div>
            </td>
            <td class="border-t border-l">
              <div class="px-6 py-4 flex items-center"
                   tabindex="-1">
                <!-- total price inc vat -->
                £{{ parseFloat(component_bundle.component_wholesale_price_override * component_bundle.component_quantity).toFixed(2) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center"
                   tabindex="-1">
                <!-- total price ex vat -->
                £{{
                  parseFloat(component_bundle.component_wholesale_price_override / 1.2 * component_bundle.component_quantity).toFixed(2)
                }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center"
                   tabindex="-1">
                <!-- total margin -->
                £{{
                  parseFloat(component_bundle.component_quantity * (component_bundle.component_wholesale_price_override / 1.2 -
                      component_bundle.component_goods_cost)).toFixed(2)
                }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center"
                   tabindex="-1">
                <!-- margin % -->
                {{
                  parseFloat(100 * ((component_bundle.component_wholesale_price_override / 1.2 - component_bundle.component_goods_cost) /
                      (component_bundle.component_wholesale_price_override / 1.2))).toFixed(2)
                }}%
              </div>
            </td>
            <td class="border-t border-l">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <!-- unit cost-->
                £{{ parseFloat(component_bundle.component_goods_cost).toFixed(2) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <!-- unit margin -->
                £{{
                  parseFloat((component_bundle.component_wholesale_price_override / 1.2 - component_bundle.component_goods_cost)).toFixed(2)
                }}
              </div>
            </td>
            <!--td class="border-t border-l">
              <div class="px-6 py-4 flex items-center"
                   tabindex="-1">
                £{{ parseFloat(component_bundle.goods_cost).toFixed(2) }}

              </div>
            </td-->
            <td class="border-t border-l">
              <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', component_bundle.component_object_id)"
                            tabindex="-1">
                £{{ parseFloat(component_bundle.component_wholesale_price).toFixed(2) }}
              </inertia-link>
            </td>
          </tr>
          <!-- Start of postage -->
          <tr class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              </div>
            </td>
            <!--td class="border-t">
              <div class="mx-2">
                <select-input v-model="order.postage_id" class="w-72">
                  <option value=''>None</option>
                  <option v-for="postage in postage" :key="postage.id" :value="postage.id">{{ postage.name }}</option>
                </select-input>
              </div>
            </td-->
            <!--td class="border-t">
              <div class="px-6 py-4 flex items-center">
                Postage
              </div>
            </td-->
            <td class="border-t">
              <div class="ml-5">
                <select class="bg-transparent" v-model="form.postage_id" class="w-72"
                        @change="form.postage_price_inc_vat=getPostagePriceIncVat(form.postage_id),
                        postage_price_ex_vat=getPostagePriceExVat(form.postage_id)">
                  <option :value=null>[Select Postage]</option>
                  <option v-for="postage in postage" :key="postage.id" :value="postage.id">{{ postage.name }}</option>
                </select>
              </div>
            </td>
            <td class="border-t border-l" colspan="1">
            </td>
            <td class="border-t">
              <div class="px-6 py-2 flex items-center" tabindex="-1">
                <span class="pr-2">£</span>
                <input
                    class="w-20 border rounded p-2"
                    v-model="form.postage_price_inc_vat"
                    v-on:keyup="postage_price_ex_vat=getPriceExVat(form.postage_price_inc_vat)"
                />
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-2 flex items-center" tabindex="-1">
                <span class="pr-2">£</span>
                <input
                    class="w-20 border rounded p-2"
                    v-model="postage_price_ex_vat"
                    v-on:keyup="form.postage_price_inc_vat=getPriceIncVat(postage_price_ex_vat)"
                />
              </div>
            </td>
            <td class="border-t border-l">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                £{{ parseFloat(form.postage_price_inc_vat).toFixed(2) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                £{{ parseFloat(form.postage_price_inc_vat / 1.2).toFixed(2) }}
              </div>
            </td>
            <td class="border-t" colspan="2"></td>
            <!--td class="border-t border-l" colspan="2">
            </td-->
            <td class="border-t border-l" colspan="2"></td>
            <td class="border-t border-l" colspan="2"></td>
          </tr>
          <!-- Start of total -->
          <tr
              class="hover:bg-gray-100 focus-within:bg-gray-100">
            <!-- Start of bundle section -->
            <td class="border-t">
              <div class="px-6 py-4 flex items-center">
              </div>
            </td>
            <td class="border-t" colspan="1">
              <div class="px-6 py-4 flex items-center">
                Total
              </div>
            </td>

            <td class="border-t border-l" colspan="3">
            <td class="border-t border-l">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                £{{ parseFloat(total_inc_vat).toFixed(2) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                £{{ parseFloat(total_ex_vat).toFixed(2) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                £{{ parseFloat(total_margin).toFixed(2) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ parseFloat(total_margin_perc).toFixed(2) }}%
              </div>
            </td>
            <td class="border-t border-l" colspan="2"></td>
            <td class="border-t border-l"></td>
            <td class="border-t border-l" colspan="1"></td>
          </tr>
          <!--tr v-if="component_bundles.length === 0">
            <td class="border-t px-6 py-4" colspan="4">No components found.</td>
          </tr-->
        </table>
      </div>

      <div class="mt-10 flex">
        <!--inertia-link class="btn-indigo" :href="route('bundles.unit.create', bundle.id)">
          <span>Add</span>
          <span class="hidden md:inline">Unit</span>
        </inertia-link>
        <inertia-link class="btn-indigo ml-10" :href="route('bundles.child.create', bundle.id)">
          <span>Add</span>
          <span class="hidden md:inline">Bundle</span>
        </inertia-link-->

        <div>
          <loading-button :loading="sending" class="btn-indigo" type="submit">Save</loading-button>
        </div>

        <inertia-link class="btn-indigo ml-10" :href="route('orders.component.create', order.id)">
          <span>Add</span>
          <span class="hidden md:inline">Component</span>
        </inertia-link>
        <!--form @submit.prevent="updateComponents">
          <loading-button :loading="sending" class="btn-indigo ml-10" type="submit">Save</loading-button>
        </form-->
        <a class="btn-indigo ml-10" :href="'/orders/edit/'+order.id+'?get_incvat_csv=y'">
          <span>Export Inc VAT</span>
        </a>
        <a class="btn-indigo ml-10" :href="'/orders/edit/'+order.id+'?get_exvat_csv=y'">
          <span>Export Ex VAT</span>
        </a>
        <a class="btn-indigo ml-10" :href="'/orders/edit/'+order.id+'?get_fullvat_csv=y'">
          <span>Export Full VAT</span>
        </a>
      </div>


      <!--h2 class="mt-16 mb-8 font-bold text-2xl">Suggested Postage for {{ bundle.category_name }}</h2>
      <div class="mt-12 rounded bg-white shadow overflow-x-auto max-w-3xl">
        <table class="w-full whitespace-no-wrap">
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4">Channel</th>
            <th class="px-6 pt-6 pb-4">Bundle</th>
          </tr>
          <tr v-for="postage in postage_suggestions" :key="postage.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t">
              <inertia-link class="px-6 py-4 flex items-center" :href="route('postage')" tabindex="-1">
                {{ postage.channel_name }}
              </inertia-link>
            </td>
            <td class="border-t">
              <inertia-link class="px-6 py-4 flex items-center" :href="route('postage')" tabindex="-1">
                {{ postage.unit_name }}
              </inertia-link>
            </td>
          </tr>
          <tr v-if="postage.length === 0">
            <td class="border-t px-6 py-4" colspan="13">No postage suggestions are configured for this category (see
              postage page)
            </td>
          </tr>
        </table>
      </div-->
    </form>


    <div class="mt-16">
      <button v-if="!order.deleted_at" class="btn-indigo bg-red-500" type="button" @click="destroy">Delete Order
      </button>
    </div>


  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    order: Object,
    component_bundles: Object,
    postage: Object,
    customers: Array,
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      // Form contains order level details. Both form/order and bundlecomponents are sent on submit
      form: {
        name: this.order.name,
        customer_id: this.order.customer_id,
        postage_id: this.order.postage_id,
        postage_price_inc_vat: this.order.postage_price_inc_vat,
        date: this.order.date,
        //new_customer_name: null
        //sku: this.bundle.sku,
        //category: this.bundle.category,
        //fba_category: this.bundle.fba_category,
      },
      // Keep this outside form, otherwise it's submitted as field to update
      postage_price_ex_vat: parseFloat(this.order.postage_price_inc_vat / 1.2).toFixed(2)
      //date: new Date(),
    }
  },
  computed: {
    total_inc_vat: function () {
      let sum = 0;
      let postage = parseFloat(this.form.postage_price_inc_vat)
      this.component_bundles.forEach(function (component) {
        sum += (parseFloat(component.component_quantity * component.component_wholesale_price_override));
      });
      return sum + postage;
    },
    total_ex_vat: function () {
      let sum = 0;
      let postage = parseFloat(this.form.postage_price_inc_vat / 1.2)
      this.component_bundles.forEach(function (component) {
        sum += (parseFloat(component.component_quantity * component.component_wholesale_price_override / 1.2));
      });
      return sum + postage;
    },
    total_margin: function () {
      let sum = 0;
      this.component_bundles.forEach(function (component) {
        sum += (parseFloat(component.component_quantity * (component.component_wholesale_price_override / 1.2 - component.component_goods_cost)));
      });
      return sum;
    },
    total_margin_perc: function () {
      return parseFloat(this.total_margin) / (parseFloat(this.total_ex_vat) - parseFloat(this.form.postage_price_inc_vat / 1.2)) * 100;
    },
    //},
    //  margin_total: function () {
    //    let sum = 0;
    //    this.components.forEach(function (component) {
    //      sum += ((parseFloat(component.price) - parseFloat(component.price)) * parseFloat(component.quantity));
    //    });
    //    return sum;
    //  },
  },
  methods: {
    getPostagePriceIncVat(id) {
      return parseFloat(this.postage.find(postage => postage.id === id).cost * 1.2).toFixed(2);
    },
    getPostagePriceExVat(id) {
      return parseFloat(this.postage.find(postage => postage.id === id).cost).toFixed(2);
    },
    getPriceIncVat(price) {
      // Only run function if price has changed
      return parseFloat(price * 1.2).toFixed(2);
    },
    getPriceExVat(price) {
      // Only run function if price has changed
      return parseFloat(price / 1.2).toFixed(2);
    },
    submit() {
      this.$inertia.put(this.route('orders.update', this.order.id),
          {
            order: this.form, // Form contains order level details
            bundles: this.component_bundles,
          },
          {
            preserveState: false, // Required so that form updates in event of new customer being added
            onStart: () => this.sending = true,
            onFinish: () => this.sending = false,
          })
    },
    // Alternative method --> https://stackoverflow.com/questions/42694457/getting-form-data-on-submits
    updateComponents() {
      //If updating prices, no need to send specific component id. Instead send all components
      this.$inertia.put(this.route('orders.components.update', this.order.id),
          {
            bundles: this.component_bundles,
          },
          {
            onStart: () => this.sending = true,
            onFinish: () => this.sending = false,
            preserveScroll: true
          })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this order?')) {
        this.$inertia.delete(this.route('orders.destroy', this.order.id))
      }
    },
    deleteComponent(component) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$inertia.put(this.route('orders.component.destroy', this.order.id),
            {
              id: component.id,
            },
            {
              preserveScroll: true
            })
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this order?')) {
        this.$inertia.put(this.route('orders.restore', this.order.id))
      }
    },
  },
}
</script>
