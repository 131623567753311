<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 v-if="filters.viewType=='ship_today'" class="font-bold text-3xl">Ship Today</h1>
      <h1 v-else class="font-bold text-3xl">Shipped</h1>
      <div>
        <a class="btn-indigo ml-4" :href="'/shippings/view/ship_today?action=getAmazonXL&category=af-uk'">
          Export Amazon UK
        </a>
        <a class="btn-indigo ml-4" :href="'/shippings/view/ship_today?action=getAmazonXL&category=af-eu-de'">
          Export Amazon DE
        </a>
        <a class="btn-indigo ml-4" :href="'/shippings/view/ship_today?action=getAmazonXL&category=af-eu-fr'">
          Export Amazon FR
        </a>
        <a class="btn-indigo ml-4" :href="'/shippings/view/ship_today?action=getAmazonXL&category=af-eu-es'">
          Export Amazon ES
        </a>
        <a class="btn-indigo ml-4" :href="'/shippings/view/ship_today?action=getAmazonXL&category=af-eu-it'">
          Export Amazon IT
        </a>
      </div>
    </div>
    <!--div class="flex justify-end mt-5">
      <div>
        <button v-if="filters.viewType=='ship_today'" class="btn-indigo ml-2" @click="shipAllPackings(category='af-uk')">
          <span>Mark UK As Shipped</span>
        </button>
         <button v-if="filters.viewType=='ship_today'" class="btn-indigo ml-2" @click="shipAllPackings(category='af-eu-de')">
          <span>Mark DE As Shipped</span>
        </button>
          <button v-if="filters.viewType=='ship_today'" class="btn-indigo ml-2" @click="shipAllPackings(category='af-eu-fr')">
          <span>Mark FR As Shipped</span>
        </button>
          <button v-if="filters.viewType=='ship_today'" class="btn-indigo ml-2" @click="shipAllPackings(category='af-eu-es')">
          <span>Mark ES As Shipped</span>
        </button>
          <button v-if="filters.viewType=='ship_today'" class="btn-indigo ml-2" @click="shipAllPackings(category='af-eu-it')">
          <span>Mark IT As Shipped</span>
        </button>
      </div>
    </div-->
    <h2 class="mt-16 mb-8 font-bold text-2xl">Batches in Packing</h2>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th v-if="filters.viewType=='ship_today'" class="px-6 pt-6 pb-4 border-l"></th>
          <th v-if="filters.viewType=='ship_today'" class="px-6 pt-6 pb-4"><i class="fa-solid fa-truck"></i></th>
          <th v-if="filters.viewType=='ship_today'" class="px-6 pt-6 pb-4"><i class="fa-solid fa-star"></i></th>
          <th class="px-6 pt-6 pb-4 border-l">Tags</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <th class="px-6 pt-6 pb-4">Tool SKU</th>
          <th class="px-6 pt-6 pb-4">Amazon SKU</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Quantity</th>
          <th class="px-6 pt-6 pb-4">Box Type</th>
          <th class="px-6 pt-6 pb-4">No of Boxes</th>
          <th class="px-6 pt-6 pb-4">Units Per Box</th>
          <th class="px-6 pt-6 pb-4">Box Weight (kg)</th>
          <th class="px-6 pt-6 pb-4">Total Weight (kg)</th>
            <th class="px-6 pt-6 pb-4">Batch Code</th>
           <th class="px-6 pt-6 pb-4">Packing ID</th>

          <!--th class="px-6 pt-6 pb-4">Packer</th>
          <th class="px-6 pt-6 pb-4">Shipped</th-->

        </tr>
        <!--tr v-for="packing in packings.data" :key="packing.id" v-if="packing.user_id != null"
            class="focus-within:bg-gray-100"-->
        <tr v-for="packing in packings.data" :key="packing.id"
             :class="{'bg-gray-300': packing.status == '4'}">
          <td v-if="filters.viewType=='ship_today'" class="border-t border-l">
            <div class="px-6 flex items-center focus:text-indigo-500 cursor-pointer" @click="shipPacking(packing)">
              <i class="fas fa-truck-fast text-indigo-400"></i>
            </div>
          </td>
          <td v-if="filters.viewType=='ship_today'" class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="packing.ship_today" @change="savePacking(packing)">
            </div>
          </td>
          <td v-if="filters.viewType=='ship_today'" class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="packing.is_priority" @change="savePacking(packing)">
            </div>
          </td>
          <td class="border-t border-l w-10">
            <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
            <div class="align-middle px-2 relative px-6">
                 <span v-if="isPastDue(packing.due_date)"
                       class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
              <span v-if="packing.is_priority"
                    class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
          {{ packing.due_date.substring(0, 10) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">

              {{ packing.status_name }}

            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="packing.category == null"> -- No category selected --</span>
              <span v-else>{{ packing.category_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.bundle_hazard }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', packing.bundle_id)">
                {{ packing.bundle_sku }}
              </a>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', packing.bundle_id)">
                {{ packing.amazon_channel_sku }}
              </a>
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', packing.bundle_id)">
                {{ packing.bundle_name }}
              </a>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.quantity_required }}
              </div>
            </div>
          </td>
          <td v-if="filters.viewType=='ship_today'" class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <select v-model="packing.box_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="savePacking(packing)">
                <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
              </select>
            </div>
          </td>
          <td v-if="filters.viewType=='ship_today'" class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input class="w-20 border rounded p-2 bg-gray-100" v-model="packing.box_quantity"
                     @change="savePacking(packing)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseInt(packing.quantity_required / packing.box_quantity) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseFloat(packing.quantity_required / packing.box_quantity * packing.bundle_weight / 1000, 2) }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseFloat(packing.quantity_required * packing.bundle_weight / 1000, 2) }}
            </div>
          </td>
              <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <div>
                  {{ packing.batch_code }}
                </div>
              </div>
            </td>
              <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <div>
                  {{ packing.id }}
                </div>
              </div>
            </td>


          <!--td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ packing.username }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if="packing.shipped_timestamp != null && packing.shipped_timestamp != '' ">
                  {{ packing.shipped_timestamp.substring(0, 16) }}
                </span>
            </div>
          </td-->
        </tr>
        <tr v-if="packings.data.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No batches found in packing.</td>
        </tr>
      </table>
    </div>
    <pagination :links="packings.links"/>
    <div v-if="assemblies.data.length > 0">
      <h2 class="mt-16 mb-8 font-bold text-2xl">Batches in Assembly</h2>
      <div class="mt-12 bg-white rounded shadow overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4 pl-8 border-l"><i class="fa-solid fa-truck"></i></th>
            <th class="px-6 pt-6 pb-4"><i class="fa-solid fa-star"></i></th>
            <th class="px-6 pt-6 pb-4 border-l">Tags</th>
            <th class="px-6 pt-6 pb-4">Due Date</th>
            <th class="px-6 pt-6 pb-4">Status</th>
            <th class="px-6 pt-6 pb-4">Category</th>
            <th class="px-6 pt-6 pb-4">Hazardous</th>
            <th class="px-6 pt-6 pb-4">Tool SKU</th>
            <th class="px-6 pt-6 pb-4">Amazon SKU</th>
            <th class="px-6 pt-6 pb-4">Name</th>
            <th class="px-6 pt-6 pb-4">Quantity</th>
            <th class="px-6 pt-6 pb-4">Box Type</th>
            <th class="px-6 pt-6 pb-4">No of Boxes</th>
            <th class="px-6 pt-6 pb-4">Units Per Box</th>
            <th class="px-6 pt-6 pb-4">Box Weight (kg)</th>
            <th class="px-6 pt-6 pb-4">Total Weight (kg)</th>
            <th class="px-6 pt-6 pb-4">Assenbly ID</th>
            <!--th class="px-6 pt-6 pb-4">Assembler</th-->

          </tr>
          <tr v-for="assembly in assemblies.data" :key="assembly.id"
                 :class="{'bg-gray-300': assembly.status == '3'}">
            <td class="border-t border-l">
              <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
                <input type="checkbox" :id="1" v-model="assembly.ship_today" @change="saveAssembly(assembly)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
                <input type="checkbox" :id="1" v-model="assembly.is_priority" @change="saveAssembly(assembly)">
              </div>
            </td>
             <td class="border-t border-l w-10">
            <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
            <div class="align-middle px-2 relative px-6">
                 <span v-if="isPastDue(assembly.due_date)"
                       class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
              <span v-if="assembly.is_priority"
                    class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>
            </div>
          </td>
             <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
                {{ assembly.due_date.substring(0, 10) }}
            </div>
          </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">

                {{ assembly.status_name }}

              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <span v-if="assembly.category == null"> -- No category selected --</span>
                <span v-else>{{ assembly.category_name }}</span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <div>
                  {{ assembly.bundle_hazard }}
                </div>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <a target="_blank" :href="route('bundles.edit', assembly.bundle_id)">
                  {{ assembly.bundle_sku }}
                </a>

              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">

                <a target="_blank" :href="route('bundles.edit', assembly.bundle_id)">
                  {{ assembly.amazon_channel_sku }}
                </a>

              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <a target="_blank" :href="route('bundles.edit', assembly.bundle_id)">
                  {{ assembly.bundle_name }}
                </a>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <div>
                  {{ assembly.quantity_required }}
                </div>
              </div>
            </td>
            <td v-if="filters.viewType=='ship_today'" class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <select v-model="assembly.box_id" class="w-56 border rounded p-2 bg-gray-100"
                        @change="saveAssembly(assembly)">
                  <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
                </select>
              </div>
            </td>
            <td v-if="filters.viewType=='ship_today'" class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="assembly.box_quantity"
                       @change="saveAssembly(assembly)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                {{ parseInt(assembly.quantity_required / assembly.box_quantity) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                {{ parseFloat(assembly.quantity_required / assembly.box_quantity * assembly.bundle_weight / 1000, 2) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                {{ parseFloat(assembly.quantity_required * assembly.bundle_weight / 1000, 2) }}
              </div>
            </td>
                      <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <div>
                  {{ assembly.batch_code }}
                </div>
              </div>
            </td>
               <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <div>
                  {{ assembly.id }}
                </div>
              </div>
            </td>
            <!--td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                {{ assembly.username }}
              </div>
            </td-->
          </tr>
          <tr v-if="assemblies.data.length === 0">
            <td class="border-t px-6 py-4" colspan="30">No batches found in assembly.</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'packings'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput
  },
  props: {
    auth: Object,
    packings: Object,
    assemblies: Object,
    users: Object,
    filters: Object,
    boxes: Array,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
   */
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    savePacking(packing) {
      // If channel is Shopify Or Amazon, check for LW SKU (i.e. if there is a place to push SKU to
      // If no LW SKU, ask to sync, otherwise ask to save/push
      // If channel is anything else, just ask to save
      this.$inertia.put(this.route('shippings.view', 'ship_today'),
          {
            packing_id: packing.id,
            status: packing.status,
            ship_today: packing.ship_today,
            is_priority: packing.is_priority,
            box_id: packing.box_id,
            box_quantity: packing.box_quantity
          },
          {
            preserveScroll: true,
            //preserveState: false
          })
    },
    saveAssembly(assembly) {
      // If channel is Shopify Or Amazon, check for LW SKU (i.e. if there is a place to push SKU to
      // If no LW SKU, ask to sync, otherwise ask to save/push
      // If channel is anything else, just ask to save
      this.$inertia.put(this.route('shippings.view', 'ship_today'),
          {
            assembly_id: assembly.id,
            //user: assembly.user,
            //user_id: packing.user_id,
            //quantity_required: packing.quantity_required,
            //quantity_to_verify: packing.quantity_to_verify,
            //due_date: packing.due_date,
            //category: packing.category,
            status: assembly.status,
            ship_today: assembly.ship_today,
            is_priority: assembly.is_priority,
            box_id: assembly.box_id,
            box_quantity: assembly.box_quantity
          },
          {
            preserveScroll: true,
            //preserveState: false
          })
    },
    shipPacking(packing) {
      this.$inertia.put(this.route('shippings.view', 'ship_today'),
          {
            packing_id: packing.id,
            status: 4,
            ship_today: false
          },
          {
            preserveScroll: true
          })
    },
    shipAllPackings(category) {
      //if (this.assemblies.data.length === 0) {
      //console.log(this.assemblies.data.filter(item => item.category === category).length)
      if (this.assemblies.data.filter(item => item.category === category).length === 0) {
        if (confirm(
            'Are you sure you want to mark all these batches as shipped?'
        )) {
          this.$inertia.put(this.route('shippings.view', 'ship_today'),
              {
                ship_all: 'Y',
                category: category,
              },
              {
                preserveScroll: true
              })
        }
      } else {
        alert('ERROR - There are still batches in Assembly. \n\n' +
            'All batches must be in Packing before proceeding')

      }
    },
    isPastDue(dueDate) {
      const today = new Date();
      const due = new Date(dueDate);
      return due < today;
    },
  },
  computed: {
    unassigned_count() {
      return this.packings.data.filter(packing => packing.user_id == null).length;
    }
  }
}
</script>
