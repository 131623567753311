<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Customers</h1>
      <inertia-link class="btn-indigo" :href="route('customers.create')">
        <span>Create</span>
        <span class="hidden md:inline">Customer</span>
      </inertia-link>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name"/>
        </div>
        <!--div class="mt-10">
          <input type="checkbox" id="amazon" value="y" v-model="form.amazon">
          <label class="mr-5" for="amazon">Amazon</label>
          <input type="checkbox" id="amazonfba" value="y" v-model="form.amazonfba">
          <label class="mr-5" for="amazonfba">Amazon FBA</label>
          <input type="checkbox" id="shopify" value="y" v-model="form.shopify">
          <label class="mr-5" for="shopify">Shopify</label>
          <input type="checkbox" id="etsy" value="y" v-model="form.etsy">
          <label class="mr-5" for="ebay">Etsy</label>
          <input type="checkbox" id="ebay" value="y" v-model="form.ebay">
          <label class="mr-5" for="ebay">Ebay</label>
          <input type="checkbox" id="direct" value="y" v-model="form.direct">
          <label class="mr-5" for="direct">Direct</label>
        </div>
        <div class="my-10">
          <button class="btn-indigo" type="submit">Search</button>
        </div-->
      </form>
    </div>
    <!--div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
    </div-->
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Orders</th>
          <th class="px-6 pt-6 pb-4">Scaling</th>
          <th class="px-6 pt-6 pb-4">Last Order Date</th>
        </tr>
        <tr v-for="customer in customers.data" :key="customer.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('customers.edit', customer.id)">
              {{ customer.name }}
            </inertia-link>
          </td>
           <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('customers.edit', customer.id)">
              {{ customer.order_count }}
            </inertia-link>
          </td>
            <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('customers.edit', customer.id)">
              {{ customer.scaling_count }}
            </inertia-link>
          </td>
          <td v-if="customer.last_order_date" class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('customers.edit', customer.id)">
              {{ customer.last_order_date.substring(0, 10) }}
            </inertia-link>
          </td>
          <td v-else class="border-t">
          </td>



          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('customers.edit', customer.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="customers.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No customers found.</td>
        </tr>
      </table>
    </div>
    <pagination :links="customers.links"/>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'customers'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    customers: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        // this.$inertia.replace(this.route('templates', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },

  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
