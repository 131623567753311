<template>
  <div>
    <div class="mt-10 font-bold border-size-2 text-2xl">
      Under Assembly
    </div>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
                    <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-truck cursor-help" title="Ship Today"></i></th>
           <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-star cursor-help" title="Make Priority"></i>
                <th class="px-6 pt-6 pb-4 border-l">Tags</th>
          <th class="px-6 pt-6 pb-4" colspan="2">Due Date</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <th class="px-6 pt-6 pb-4">Tool SKU</th>
          <th class="px-6 pt-6 pb-4">Amazon SKU</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Quantity</th>
            <th class="px-6 pt-6 pb-4">Inner Box Type</th>
            <th class="px-6 pt-6 pb-4">Inner Box #</th>
            <th class="px-6 pt-6 pb-4">Outer Box Type</th>
            <th class="px-6 pt-6 pb-4">Outer Box #</th>
          <th class="px-6 pt-6 pb-4">Units Per Box</th>
          <th class="px-6 pt-6 pb-4">Box Weight (kg)</th>
          <th class="px-6 pt-6 pb-4">Total Weight (kg)</th>
          <th class="px-6 pt-6 pb-4">Batch Code</th>
          <th class="px-6 pt-6 pb-4">Assembly ID</th>
          <!--th class="px-6 pt-6 pb-4">Assembler</th-->

        </tr>
        <tr v-for="assembly in assemblies" :key="assembly.id"
            :class="{'bg-gray-300': assembly.status === 3}">
          <td class="border-t border-l">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="assembly.ship_today" @change="handleSaveAssembly(assembly)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="assembly.is_priority" @change="handleSaveAssembly(assembly)">
            </div>
          </td>
                      <td class="border-t border-l w-10">
              <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
              <div class="align-middle px-2 relative px-6">
                 <span v-if="handleIsDateInPast(assembly.due_date)"
                       class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
                <span v-if="assembly.is_priority"
                      class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>
              </div>
            </td>
          <td class="border-t">
            <div class="px-6 flex items-center">
              <!-- https://stackoverflow.com/questions/67866941/v-date-picker-not-calling-change-event-when-single-date-is-picked -->
              <v-date-picker v-model="assembly.due_date" @input="handleSaveAssembly(assembly)">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      :value="inputValue"
                      class="border p-2 rounded w-32 bg-gray-100"
                      v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </div>
          </td>
          <td class="border-t">
            <div class="px-8 pl-0 flex items-center focus:text-indigo-500" tabindex="-1">
              <button @click="handleAdjustDate(assembly, -1)" class="ml-2 bg-indigo-500 text-white p-2 rounded">
                <i class="fa-solid fa-minus"></i>
              </button>
              <button @click="handleAdjustDate(assembly, 1)" class="ml-2 bg-indigo-500 text-white p-2 rounded">
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">

              {{ assembly.status_name }}

            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="assembly.category == null"> -- No category selected --</span>
              <span v-else>{{ assembly.category_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ assembly.bundle_hazard }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', assembly.bundle_id)">
                {{ assembly.bundle_sku }}
              </a>

            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">

              <a target="_blank" :href="route('bundles.edit', assembly.bundle_id)">
                {{ assembly.amazon_channel_sku }}
              </a>

            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', assembly.bundle_id)">
                {{ assembly.bundle_name }}
              </a>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ assembly.quantity_required }}
              </div>
            </div>
          </td>
            <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <select v-model="assembly.inner_box_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSaveAssembly(assembly)">
                <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input class="w-20 border rounded p-2 bg-gray-100" v-model="assembly.inner_box_quantity"
                     @change="handleSaveAssembly(assembly)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <select v-model="assembly.box_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSaveAssembly(assembly)">
                <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input class="w-20 border rounded p-2 bg-gray-100" v-model="assembly.box_quantity"
                     @change="handleSaveAssembly(assembly)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseInt(assembly.quantity_required / assembly.box_quantity) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseFloat(assembly.quantity_required / assembly.box_quantity * assembly.bundle_weight / 1000, 2) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseFloat(assembly.quantity_required * assembly.bundle_weight / 1000, 2) }}
            </div>
          </td>
             <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ assembly.batch_code }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ assembly.id }}
            </div>
          </td>

          <!--td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ assembly.username }}
            </div>
          </td-->
        </tr>
        <tr v-if="assemblies.length === 0">
          <td class="border-t px-6 py-4" colspan="30">No batches found in assembly.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {saveAssembly} from '@/Services/shippingsApiService';
import {adjustDate, isDateInPast} from '@/Services/genericDataService';

export default {
  props: {
    assemblies: Object,
    filters: Object,
    boxes: Array
  },
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
    handleSaveAssembly(assembly) {
      saveAssembly(this.$inertia, this.route, assembly, this.filters);
    },
    handleAdjustDate(assembly, daysToAdd) {
      assembly.due_date = adjustDate(assembly.due_date, daysToAdd)
    },
  }
}
</script>

