<template>
  <div>
    <!--h1 class="mb-20 font-bold text-3xl"-->
    <h1 class="mb-10 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('packings.admin')">Packing
      </inertia-link>
      <span class="font-medium">/</span>
      {{ bundle.name }}
    </h1>
    <trashed-message v-if="packing.deleted_at" class="mb-6" @restore="restore">
      This packing has been deleted.
    </trashed-message>
    <h2 class="mt-16 mb-8 font-bold text-2xl">Batch Details</h2>
    <div class="rounded bg-white shadow overflow-x-auto mt-10">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Packer</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Title</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <!--th class="px-6 pt-6 pb-4">FBA Barcode</th>
          <th class="px-6 pt-6 pb-4">FBA DE Barcode</th>
          <th class="px-6 pt-6 pb-4">FBA FR Barcode</th-->
          <th class="px-6 pt-6 pb-4">Quantity Required</th>
          <th class="px-6 pt-6 pb-4">Products To Verify</th>
          <th class="px-6 pt-6 pb-4">Products Verified</th>
        </tr>
        <tr>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.username }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.category_name }}
            </div>
          </td>

          <td class="border-t">
            <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
              {{ bundle.name }}
            </a>
          </td>
          <td class="border-t">
            <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
              {{ bundle.sku }}
            </a>
          </td>
          <!--td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ bundle.amazon_barcode }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ bundle.amazon_de_barcode }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ bundle.amazon_fr_barcode }}
            </div>
          </td-->
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.quantity_required }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.quantity_to_verify }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              {{ form.quantity_verified }}
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div>
        <h2 class="mt-16 mb-8 font-bold text-2xl">Stock in Packing Bin</h2>
        <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->
        <div class="rounded bg-white shadow overflow-x-auto mt-10">
          <table class="w-full whitespace-no-wrap ">
            <tr class="text-left font-bold">
              <th class="px-6 pt-6 pb-4">Name</th>
              <th class="px-6 pt-6 pb-4">SKU</th>
              <th class="px-6 pt-6 pb-4">LW SKU</th>
              <th class="px-6 pt-6 pb-4">Stock Required</th>
              <th class="px-6 pt-6 pb-4">Stock In Packing</th>
            </tr>
            <tr>
               <tr :class="{'bg-red-200': packing.quantity_required > bundle.stock_level && !packing.insufficient_stock_override}">
              <td class="border-t">
                <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
                  {{ bundle.name }}
                </a>
              </td>
              <td class="border-t">
                <a target="_blank" class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)">
                  {{ bundle.sku }}
                </a>
              </td>
             <td class="border-t">
                <div class="px-6 py-4 flex items-center">
                  {{ bundle.lw_sku }}
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                  {{ packing.quantity_required }}
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ bundle.stock_level }}
              </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div v-if="packing.quantity_required <= bundle.stock_level || packing.insufficient_stock_override">
      <h2 class="mt-16 mb-8 font-bold text-2xl">Scanning</h2>
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">

        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <div class="pr-6 pb-6 w-full">
            <span v-if="bundle.parent_barcode != null && bundle.parent_barcode != ''">
            Scan barcode to complete batch.
            </span>
            <span v-else>
              ERROR: {{ bundle.parent_barcode_field }} is empty for this product. Add this
              <a target="_blank" rel='noopener noreferrer' class="text-indigo-400"
                 :href="route('bundles.edit', bundle.id)">
                here
              </a>
              and refresh this page to proceed.
            </span>
          </div>


          <input class="pr-6 w-full lg:w-1/2 border rounded p-3 bg-gray-100" v-model="barcode" autofocus ref="barcode"
                 @keyup.enter="scanBarcode(barcode)"
                 label="Barcode"/>
        </div>


        <div class="p-8 flex flex-wrap">
          <button class="btn-indigo mr-8" @click="scanBarcode(barcode)">
            <span>Next</span>
          </button>
        </div>

      </div>
      <div class="mt-10 ml-6">
        * {{ packing.category_name }} is currently setup to use {{ bundle.parent_barcode_field }} for packing scan.
      </div>

      <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->

      <!--div class = "mt-20">
         <button v-if="!assembly.deleted_at" class="btn-red" type="button" @click="destroy">Delete Assembly</button>
      </div-->
    </div>
  </div>

</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    packing: Object,
    bundle: Object
  },
  remember: 'form',
  data() {
    return {
      //component_quantity: this.component_bundles.length + this.box_units.length,
      //component_scan_count: 0,
      //current_box_barcode: this.box_units[0]['barcode'],
      //current_component_barcode: this.component_bundles[0]['nikura_barcode'],
      barcode: '',
      sending: false,
      form: {
        //quantity_required: this.assembly.quantity_required,
        //quantity_to_verify: this.assembly.quantity_to_verify,
        quantity_verified: this.packing.quantity_verified,
        //bundle_id: this.assembly.bundle_id,
        //user: this.assembly.user,
        //due_date: this.assembly.due_date,
        //status: this.assembly.status,
      },
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.barcode.focus();
    },
    submit() {
      this.$inertia.put(this.route('packings.update', this.packing.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    scanBarcode: function (barcode) {
      this.barcode = ''
      // 1) Query selector will find first element with scan-flag, and reduce by 1. This element holds barcode under id
      if (this.bundle.parent_barcode == barcode) {

        if (this.form.quantity_verified < this.packing.quantity_to_verify) {
          this.form.quantity_verified += 1
        }
        // 4) If all product verified, alert user. Otherwise, refresh and save progress
        if (this.form.quantity_verified >= this.packing.quantity_to_verify) {
          if (confirm('Final verification complete. Has the full batch of ' + this.packing.quantity_required + ' been packed? \n\n' +
              'IMPORTANT: All batches must be packed before clicking OK. '
          )) {
            this.form.status = 3
            this.$inertia.put(this.route('packings.update', this.packing.id), this.form, {
              //onStart: () => this.sending = true,
              //onFinish: () => this.sending = false,
              preserveScroll: true,
              preserveState: false
            })
          } else {
            //this.form.status = 1
            this.form.quantity_verified -= 1
            this.$inertia.put(this.route('packings.update', this.packing.id), this.form, {
              //onStart: () => this.sending = true,
              //onFinish: () => this.sending = false,
              preserveScroll: true,
              preserveState: false
            })
          }
        } else {
          //this.form.status = 1
          alert('Packing ' + this.form.quantity_verified + ' of ' + this.packing.quantity_to_verify + ' verified',
              this.$inertia.put(this.route('packings.update', this.packing.id), this.form, {
                //onStart: () => this.sending = true,
                //onFinish: () => this.sending = false,
                preserveScroll: true,
                preserveState: false
              }))
        }
      } else {
        alert('Wrong barcode scanned! \n\n' +
            'Wrong barcode scanned - ' + barcode + '\n' +
            'Expected barcode - ' + this.bundle.parent_barcode + '\n\n' +
            'Bundle field used - ' + this.bundle.parent_barcode_field)
      }
    }
    /*
    destroy() {
      if (confirm('Are you sure you want to delete this assembly?')) {
        this.$inertia.delete(this.route('assemblies.destroy', this.assembly.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this assembly?')) {
        this.$inertia.put(this.route('assemblies.restore', this.assembly.id))
      }
    },
     */
  },
}
</script>
