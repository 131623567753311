<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('suppliers')">Suppliers</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="supplier.deleted_at" class="mb-6" @restore="restore">
      This supplier has been deleted.
    </trashed-message>
    <h2 class="mb-8 font-bold text-2xl">Details</h2>
    <form @submit.prevent="submit">
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name" />
          <select-input v-model="form.currency_id" :error="errors.currency_id" class="pr-6 pb-8 w-full lg:w-1/2" label="Currency">
            <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
          </select-input>
          <text-input v-model="form.freight_cost" :error="errors.freight_cost" class="pr-6 pb-8 w-full lg:w-1/2" label="Default Freight Cost (Per Unit)" />
             <text-input v-model="form.notes" :error="errors.notes" class="pr-6 pb-8 w-full" label="Notes" />
        </div>
      </div>
     <div class="mt-10">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Update Details</loading-button>
      </div>
    </form>
    <div class = "mt-10">
       <button v-if="!supplier.deleted_at" class="btn-indigo bg-red-500" type="button" @click="destroy">Delete Supplier</button>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return { title: this.form.name }
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    supplier: Object,
    currencies: Array,
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        name: this.supplier.name,
        currency_id: this.supplier.currency_id,
        freight_cost: this.supplier.freight_cost,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.put(this.route('suppliers.update', this.supplier.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this supplier?')) {
        this.$inertia.delete(this.route('suppliers.destroy', this.supplier.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this supplier?')) {
        this.$inertia.put(this.route('suppliers.restore', this.supplier.id))
      }
    },
  },
}
</script>
