<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="mb-8 font-bold text-3xl">
        <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('bundles')">Bundles</inertia-link>
        <span class="text-indigo-400 font-medium">/</span>
        <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('bundles.edit', bundle.id)">
          {{ bundle.name }}
        </inertia-link>
        <span class="text-indigo-400 font-medium">/</span>
        Add Component
      </h1>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name"/>
        </div>
      </form>
    </div>
    <div class=" mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Type</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Name</th>
        </tr>
        <!-- Start of unit section -->
        <tr v-for="component in components.data" :key="component.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          method="put"
                          :href="route('bundles.component.create', bundle.id)"
                          :data="{
                            bundle_id: bundle.id,
                            component_object_id: component.id,
                            component_content_type_id: component.content_type_id,
                            component_quantity: 1
                          }"
                          preserve-scroll
                          tabindex="-1">
              {{component.content_type_name}}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          method="put"
                          :href="route('bundles.component.create', bundle.id)"
                          :data="{
                            bundle_id: bundle.id,
                            component_object_id: component.id,
                            component_content_type_id: component.content_type_id,
                            component_quantity: 1
                          }"
                          preserve-scroll
                          tabindex="-1">
              {{ component.category_name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          method="put"
                          :href="route('bundles.component.create', bundle.id)"
                          :data="{
                            bundle_id: bundle.id,
                            component_object_id: component.id,
                            component_content_type_id: component.content_type_id,
                            component_quantity: 1
                          }"
                          preserve-scroll
                          tabindex="-1">
              {{ component.sku }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          method="put"
                          :href="route('bundles.component.create', bundle.id)"
                          :data="{
                            bundle_id: bundle.id,
                            component_object_id: component.id,
                            component_content_type_id: component.content_type_id,
                            component_quantity: 1
                          }"
                          preserve-scroll
                          tabindex="-1">
              {{ component.name }}
            </inertia-link>
          </td>
        </tr>
        <tr v-if="components.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No units or bundles found.</td>
        </tr>
      </table>
    </div>
    <!--pagination :links="components.links"/-->
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
//import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'units'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    bundle: Object,
    components: Object,
    //units: Object,
    //bundles: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
