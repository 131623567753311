<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('checklists')">Logs</inertia-link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <form @submit.prevent="submit">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <select-input v-model="form.user" :error="errors.user" class="pr-6 pb-8 w-full"
                        label="User">
            <option v-for="user in users" :key="user[0]" :value="user[0]">{{ user[1] }}</option>
          </select-input>
          <select-input v-model="form.type" :error="errors.type" class="pr-6 pb-8 w-full"
                        label="Type">
            <option v-for="type in types" :key="type[0]" :value="type[0]">{{ type[1] }}</option>

          </select-input>


          <template v-if="form.type === 'am'">

            <div v-for="(label, index) in morningLabels" :key="index" class="w-full mb-8">
              <label :for="`morning-checkbox-${index + 1}`" class="block mr-5">{{ label }}</label>
              <input type="checkbox" v-model="checkboxes.morning[`checkbox${index + 1}`]"
                     :id="`morning-checkbox-${index + 1}`" class="block mt-2">
            </div>
          </template>

          <template v-if="form.type === 'pm'">
                <select-input v-model="form.machine" :error="errors.machine" class="pr-6 pb-8 w-full"
                        label="Machine">
            <option v-for="machine in machines" :key="machine[0]" :value="machine[0]">{{ machine[1] }}</option>

          </select-input>
            <div v-for="(label, index) in afternoonLabels" :key="index" class="w-full mb-8">
              <label :for="`afternoon-checkbox-${index + 1}`" class="block mr-5">{{ label }}</label>
              <input type="checkbox" v-model="checkboxes.afternoon[`checkbox${index + 1}`]"
                     :id="`afternoon-checkbox-${index + 1}`" class="block mt-2">
            </div>
          </template>
        </div>
        <div class="mt-10 px-8 py-4 bg-gray-100 border-t border-gray-200">
          <loading-button v-if="canSubmit" :disabled="!canSubmit" :loading="sending" class="btn-indigo" type="submit">
            Confirm
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Log Checklists'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    users: Object,
    types: Object,
    machines: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        user: null,
        type: null,
        machine: null,
      },
      checkboxes: {
        morning: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          checkbox4: false,
        },
        afternoon: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          checkbox4: false,
          checkbox5: false,
          checkbox6: false,
          checkbox7: false,
          checkbox8: false,
        }
      },
      morningLabels: [
        '1) Turn on all switches.',
        '2) Turn on compressor.',
        '3) Check that the drill is set to 1 (1 only).',
        '4) Make sure there is a load in the dishwasher and that it has been turned on.'
      ],
      afternoonLabels: [
        '1) Clean your machine!',
        '2) Clean up surrounding area of machine.',
        '3) Make sure turntable is switched off.',
        '4) Make sure labeller is switched off.',
        '5) Turn off compressor.',
        '6) Turn off all power switches.',
        '7) Turn off printers.',
        '8) Make sure big roll is being flattened overnight.',

      ]
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.route('checklists.store'), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
  },
  computed: {
    canSubmit() {
      if (this.form.type === 'am') {
        return Object.values(this.checkboxes.morning).every(value => value);
      } else if (this.form.type === 'pm') {
        return Object.values(this.checkboxes.afternoon).every(value => value);
      }
      return false;
    }
  },
}
</script>
