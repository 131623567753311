<template>
  <div>
    <!-- Start of assembly & packing -->
    <div v-if="isUrl('assemblies') || isUrl('packings') || isUrl('shippings') || isUrl('labels')"
         class="mb-24">
      <AssemblyMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
      />
      <FBAPackingMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
          :auth="auth"
      />
      <ArchivesMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
          :auth="auth"
      />
    </div>
    <div v-else-if="isUrl('descrambler')  || isUrl('fba')"
         class="mb-24">
      <FBADescramblerMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
          :auth="auth"
      />
    </div>
    <div v-else-if="isUrl('scalings') || isUrl('customers') "
          class="mb-24">
      <WholesaleMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
      />
    </div>
    <div v-else-if="isUrl('productions') || isUrl('checklists')"
         class="mb-24">
      <ProductionMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
      />
    </div>
    <div v-else-if="isUrl('international')"
         class="mb-24">
      <InternationalMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
      />
    </div>
    <div v-else-if="is_superuser && !isUrl('')"
         class="mb-24">
      <PricingMenu
          :is_superuser="is_superuser"
          :isUrl="isUrl"
      />
    </div>
    <AllAppsMenu
        :is_superuser="is_superuser"
        :isUrl="isUrl"
    />
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import AssemblyMenu from '@/Shared/MainMenuAssembly';
import FBAPackingMenu from '@/Shared/MainMenuFBAPacking';
import FBADescramblerMenu from '@/Shared/MainMenuFBADescrambler';
import ArchivesMenu from '@/Shared/MainMenuArchives';
import InternationalMenu from '@/Shared/MainMenuInternational';
import WholesaleMenu from '@/Shared/MainMenuWholesale';
import ProductionMenu from '@/Shared/MainMenuProduction';
import PricingMenu from '@/Shared/MainMenuPricing';
import AllAppsMenu from '@/Shared/MainMenuAllApps';

export default {
  components: {
    Icon,
    AssemblyMenu,
    FBAPackingMenu,
    FBADescramblerMenu,
    ArchivesMenu,
    InternationalMenu,
    WholesaleMenu,
    ProductionMenu,
    PricingMenu,
    AllAppsMenu,
  },
  data() {
    return {
      showUserMenu: false,
      is_superuser: this.$parent.$data.is_superuser,
      auth: this.$parent.$data.auth,
    }
  },
  props: {
    url: String,
  },
  methods: {
    isUrl(...urls) {
      if (urls[0] === '') {
        return this.url === ''
      }
      return urls.filter(url => this.url.startsWith(url)).length
    },
  },
}
</script>
