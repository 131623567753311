<template>
  <div>
    <div class="mb-4">
      <div class="flex items-center group py-3">
        <div class="text-gray-500">APPS</div>
      </div>
    </div>
    <div v-if="is_superuser" class="mb-4">
      <a class="flex items-center group py-3" :href="route('prices')">
        <i :class="['fas fa-fw fa-sterling-sign mr-2 text-gray-500 group-hover:text-white']"></i>
        <div class='text-gray-500 group-hover:text-white'>
          Pricing
        </div>
      </a>
    </div>
    <div class="mb-4">
      <a class="flex items-center group py-3" :href="route('assemblies.admin')">
        <i :class="['fas fa-fw fa-box mr-2 text-gray-500 group-hover:text-white']"></i>
        <div class='text-gray-500 group-hover:text-white'>
          Assembly & FBA Packing
        </div>
      </a>
    </div>
     <div class="mb-4">
      <a class="flex items-center group py-3" :href="route('FBA.orderconverter')">
        <i :class="['fas fa-fw fa-gears mr-2 text-gray-500 group-hover:text-white']"></i>
        <div class='text-gray-500 group-hover:text-white'>
          Descrambler
        </div>
      </a>
    </div>
    <div class="mb-4">
      <a class="flex items-center group py-3" :href="route('FBA.avask')">
        <i :class="['fa-brands fa-fw fa-amazon mr-2 text-gray-500 group-hover:text-white']"></i>
        <div class='text-gray-500 group-hover:text-white'>
          International
        </div>
      </a>
    </div>
    <div class="mb-4">
      <a class="flex items-center group py-3" :href="route('customers')">
        <i :class="['fas fa-fw fa-users mr-2 text-gray-500 group-hover:text-white']"></i>
        <div class='text-gray-500 group-hover:text-white'>
          Wholesale
        </div>
      </a>
    </div>
    <div class="mb-4">
      <a class="flex items-center group py-3" :href="route('productions.admin' , 'small')">
        <i :class="['fas fa-fw fa-bottle-droplet mr-2 text-gray-500 group-hover:text-white']"></i>
        <div class='text-gray-500 group-hover:text-white'>
          Production
        </div>
      </a>
    </div>
    <div class="mb-4">
      <a class="flex items-center group py-3" :href="route('quick_assemblies.create')">
        <i :class="['fas fa-fw fa-clock mr-2 text-gray-500 group-hover:text-white']"></i>
        <div class='text-gray-500 group-hover:text-white'>
          Quick Assembly
        </div>
      </a>
    </div>
     <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('custom-labels.create')">
        <i :class="['fas fa-file-lines mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('customlabels/create') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Custom Labels
        </div>
      </inertia-link>
    </div>
     <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('inventory')">
        <i :class="['fas fa-boxes-stacked mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('inventory') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Inventory
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('bundles.location')">
        <i :class="['fas fa-map-pin mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('bundles.location') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Inventory Location
        </div>
      </inertia-link>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl'],
  methods: {
  },
}
</script>
