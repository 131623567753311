<script>

export function formatDate(date) {
  return date.toISOString().substr(0, 10);
}

export function adjustDate(date, daysToAdjustBy) {
  let existingDate = new Date(date);
  existingDate.setDate(existingDate.getDate() + daysToAdjustBy);
  //const formattedDate = existingDate.toISOString().split('T')[0];
  return formatDate(existingDate)
}

export function setToToday() {
  let today = new Date();
  return formatDate(today);
}

export function setToTomorrow() {
  let today = new Date();
  today.setDate(today.getDate() + 1); // this adds a day to the current date
  return formatDate(today);
}

export function isDateInPast(date) {
  const today = new Date();
  const due = new Date(date);
  return due < today;
}

export function copyToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Copying text command was ${msg}`);
  } catch (err) {
    console.error('Could not copy text: ', err);
  }

  document.body.removeChild(textArea);
}

export function minutesToTime(mins) {
  let hours = Math.floor(mins / 60);
  mins = Math.round(mins % 60);

  // Converting to string and padding zeros if needed
  hours = String(hours).padStart(2, '0');
  mins = String(mins).padStart(2, '0');

  return hours + ':' + mins + ':00';
}

export function secondsToTime(seconds) {
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  let sec = seconds % 60;

  // Converting to string and padding zeros if needed
  hours = String(hours).padStart(2, '0');
  minutes = String(minutes).padStart(2, '0');
  sec = String(sec).padStart(2, '0');


  return hours + ':' + minutes + ':' + sec;

}


</script>

