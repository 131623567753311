<template>
  <div>
    <h1 class="mb-10 font-bold text-2xl">
      {{ assembly.bundle_name }}
    </h1>
    <div class="rounded bg-white shadow overflow-x-auto mt-10 text-sm">
      <table class="w-full">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Assembler</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Stock Required</th>
        </tr>
        <tr>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.username }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.category_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.quantity_required }}
            </div>
          </td>

        </tr>
      </table>
    </div>
    <!-- Start of table 2 -->
    <!--div class="rounded bg-white shadow mt-10">
      <table class="w-full">
        <tr class="text-left">
          <td class="px-6 pt-6 pb-4">
            <div class="px-6 flex items-center focus:text-indigo-500">
              Assembler
            </div>
          </td>
          <td class="px-6 pt-6 pb-4">
            <div class="px-6 flex items-center">
              {{assembly.username}}
            </div>
          </td>
        </tr>
        <tr class="text-left">
          <td class="px-6 pt-6 pb-4">
            <div class="px-6 flex items-center focus:text-indigo-500">
              Category
            </div>
          </td>
          <td class="px-6 pt-6 pb-4">
            <div class="px-6 flex items-center">
              {{assembly.category_name}}
            </div>
          </td>
        </tr>
        <tr class="text-left">
          <td class="px-6 pt-6 pb-4">
            <div class="px-6 flex items-center focus:text-indigo-500">
              Quantity
            </div>
          </td>
          <td class="px-6 pt-6 pb-4">
            <div class="px-6 flex items-center">
              {{assembly.quantity_required}}
            </div>
          </td>
        </tr>
      </table>
    </div-->
    <h1 class="mt-20 font-bold text-2xl">
      Components
    </h1>


    <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->
    <div class="rounded bg-white shadow overflow-x-auto mt-10 text-sm">
      <!--table class="w-full whitespace-no-wrap"-->
      <table class="w-full">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Primary Bin Rack</th>
          <th class="px-6 pt-6 pb-4">Routing Sequence</th>
          <th class="px-6 pt-6 pb-4">Stock Required</th>
          <th class="px-6 pt-6 pb-4">Stock In Picking, Production & Overflow</th>

        </tr>
        <!--tr v-for="box_unit in box_units" :key="box_unit.id">
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ box_unit.name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center"
                 tabindex="-1">
              1
            </div>
          </td>
        </tr-->
        <tr v-for="component_bundle in component_bundles"
            :key="component_bundle.id"
            :class="{'bg-red-300': component_bundle.quantity * assembly.quantity_required > component_bundle.stock_level}">
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ component_bundle.name }}
            </div>

          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ component_bundle.sku }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center w-32" tabindex="-1">
              <span>
                 {{ component_bundle.bin_rack }}
              </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ component_bundle.routing_sequence }}
              </span>
            </div>
          </td>


          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ component_bundle.quantity * assembly.quantity_required }}
              </span>
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ component_bundle.stock_level }}
              </span>
            </div>
          </td>
        </tr>
        <tr v-if="component_bundles.length + box_units.length == 0">
          <td class="border-t px-6 py-4" colspan="10">No components found.</td>
        </tr>
      </table>
    </div>

    <!--div class = "mt-20">
       <button v-if="!assembly.deleted_at" class="btn-red" type="button" @click="destroy">Delete Assembly</button>
    </div-->
  </div>

</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    assembly: Object,
    bundle: Object,
    component_bundles: Object,
    box_units: Object,
    barcode_to_scan: Object,
    name_to_scan: Object,
  },
  remember: 'form',
  data() {
    return {
      //component_quantity: this.component_bundles.length + this.box_units.length,
      //component_scan_count: 0,
      //current_box_barcode: this.box_units[0]['barcode'],
      //current_component_barcode: this.component_bundles[0]['nikura_barcode'],
      barcode: '',
      i: 0,
      sending: false,
      barcodes_scanned: [],
      form: {
        //quantity_required: this.assembly.quantity_required,
        //quantity_to_verify: this.assembly.quantity_to_verify,
        quantity_verified: this.assembly.quantity_verified,
        //bundle_id: this.assembly.bundle_id,
        //user: this.assembly.user,
        //due_date: this.assembly.due_date,
        //status: this.assembly.status,
      },
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    isScanned(barcode) {
      return this.barcodes_scanned.filter(n => n === barcode).length === 0 ? false : true;
    },
    focusInput() {
      this.$refs.barcode.focus();
    },
    submit() {
      this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    scanBarcode: function (barcode) {
      this.barcode = ''
      // 1) Query selector will find first element with scan-flag, and reduce by 1. This element holds barcode under id
      if (document.querySelector(".scan-flag").id == barcode) {
        document.querySelector(".scan-flag").innerText = document.querySelector(".scan-flag").innerText - 1

        // 2) If all components have been scanned, remove scan-flag element
        if (document.querySelector(".scan-flag").innerText == 0) {
          // Add barcode scanned to list and remove scan flag
          this.barcodes_scanned.push(barcode)
          document.querySelector(".scan-flag").remove()
          //document.querySelector(".scan-flag").replaceWith('Scanned')
          //document.querySelector(".scan-flag").replaceWith("<i class='fas fa-ban text-gray-600 ml-5'></i>")

          // 3) Check if all elements have been scanned (i.e. scan flag removed)
          if (document.querySelectorAll(".scan-flag").length == 0) {
            //this.component_scan_count = 0
            if (this.form.quantity_verified < this.assembly.quantity_to_verify) {
              this.form.quantity_verified += 1
            }
            // 4) If all product verified, alert user. Otherwise, refresh and save progress
            if (this.form.quantity_verified >= this.assembly.quantity_to_verify) {
              /*
              alert('All assemblies verified.',
                  this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                    //onStart: () => this.sending = true,
                    //onFinish: () => this.sending = false,
                    preserveScroll: true,
                    preserveState: false
                  }))
               */
              if (confirm('Final verification complete. Has the full batch of ' + this.assembly.quantity_required + ' been assembled? \n\n' +
                  'IMPORTANT: All batches must be assembled before clicking OK. '
              )) {
                this.form.status = 2
                this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                  //onStart: () => this.sending = true,
                  //onFinish: () => this.sending = false,
                  preserveScroll: true,
                  preserveState: false
                })
              } else {
                this.form.status = 1
                this.form.quantity_verified -= 1
                this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                  //onStart: () => this.sending = true,
                  //onFinish: () => this.sending = false,
                  preserveScroll: true,
                  preserveState: false
                })
              }
            } else {
              this.form.status = 1
              alert('Assembly ' + this.form.quantity_verified + ' of ' + this.assembly.quantity_to_verify + ' verified',
                  this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                    //onStart: () => this.sending = true,
                    //onFinish: () => this.sending = false,
                    preserveScroll: true,
                    preserveState: false
                  }))
            }
          } else {
            // If there is still an element, get latest barcode that needs scanning (used in highlight logic)
            this.barcode_to_scan = document.querySelector(".scan-flag").id
            this.name_to_scan = document.querySelector(".scan-flag").getAttribute('name')
            this.assembly.scan_status = 'x'
          }
        }
      } else {
        alert('Wrong barcode scanned! \n\n' +
            'Barcode Scanned: ' + barcode + '\n' +
            'Expected Barcode: ' + this.barcode_to_scan + '\n')
      }

      //if (this.form.quantity_to_verify == this.form.quantity_verified) {
      //  this.form.status = 'Verified'
      //  alert('Verification complete!')
      //    //window.location.href = '/prices/?get_csv_email=y&search=' + form.search;
      //    this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
      //    //onStart: () => this.sending = true,
      //    //onFinish: () => this.sending = false,
      //    preserveScroll: true,
      //    preserveState: false
      //  })
      //}
    }
    /*
    destroy() {
      if (confirm('Are you sure you want to delete this assembly?')) {
        this.$inertia.delete(this.route('assemblies.destroy', this.assembly.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this assembly?')) {
        this.$inertia.put(this.route('assemblies.restore', this.assembly.id))
      }
    },
     */
  },
}
</script>
