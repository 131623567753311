<script>

import {route} from './baseApiService';
import {Inertia} from '@inertiajs/inertia'

// -------------------
// Core functionality
// -------------------

function toggleCategoryCore(target_route, categoryKey = null, status = null) {
  Inertia.get(route(target_route),
      {
        category: categoryKey,
        status: status
      },
      {
        preserveScroll: true,
        preserveState: false
      })
}

function createPackingCore(target_route, filters = null) {
  Inertia.post(route(target_route),
      {
        filters
      },
      {
        preserveScroll: true,
        preserveState: false
      })
}

// Core function with shared logic
function savePackingCore(target_route, packing, filters = null) {
  Inertia.put(route(target_route),
      {
        object: packing,
        filters,
      },
      {
        preserveScroll: true,
        preserveState: true
      }
  );
}

// Have to do put visit, as delete doesn't allow data (filters) to be sent
// router.delete(url, options)
function deletePackingCore(target_route, packingId, filters = null) {
  Inertia.put(route(target_route, packingId), // Assuming the route takes the packing ID as a parameter
    {
      filters,
    },
    {
      preserveScroll: true,
    }
  );
}

function updateItemOrderCore(target_route, items, filters = null) {
  Inertia.put(route(target_route),
    {
      items: items,
      filters
    },
    {
      preserveScroll: true,
      preserveState: false
    }
  );
}



// -------------------
// Packing Admin API Calls
// -------------------

// No need for own URL? (only toggling category via GET)
export function toggleCategoryAdmin(categoryKey = null, filters = null) {
  toggleCategoryCore('packings.admin', categoryKey, filters);
}

export function createPackingAdmin(filters = null) {
  createPackingCore('packings.admin.create', filters);
}

export function savePackingAdmin(packing, filters = null) {
  savePackingCore('packings.admin.update', packing, filters);
}

export function deletePackingAdmin(packingId, filters = null) {
  deletePackingCore('packings.admin.destroy', packingId, filters);
}

export function updateItemOrderAdmin(items, filters = null) {
  updateItemOrderCore('packings.admin.reorder', items, filters);
}



// -------------------
// Packing View API Calls
// -------------------

export function toggleCategoryView(categoryKey = null, filters = null) {
  toggleCategoryCore('packings.view', categoryKey, filters);
}

export function savePackingView(packing, filters = null) {
  savePackingCore('packings.view.update', packing, filters);
}






// -------------------
// (Hopefully) redundant functions
// -------------------

/*
export function toggleCategory($inertia, target_route, categoryKey = null, status = null) {
  $inertia.get(target_route,
      {
        category: categoryKey,
        status: status
      },
      {
        preserveScroll: true,
        preserveState: false
      })
}

export function savePacking($inertia, target_route, packing, filters = null) {
  $inertia.put(target_route,
      {
        packing,
        filters,
      },
      {
        preserveScroll: true,
        preserveState: true,
      })
}
 */




</script>

