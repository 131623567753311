<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('postage')">Postage</inertia-link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <form @submit.prevent="submit">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <select-input v-model="form.channel" :error="errors.channel" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Channel">
            <option v-for="channel in channels" :key="channel[0]" :value="channel[0]">{{ channel[1] }}</option>
          </select-input>
          <select-input v-model="form.category" :error="errors.category" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Category">
            <option v-for="category in categories" :key="category[0]" :value="category[0]">{{ category[1] }}</option>
          </select-input>
          <select-input v-model="form.unit_id" :error="errors.unit_id" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Postage unit">
            <option v-for="unit in postage_units" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
          </select-input>
        </div>
        <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex justify-end items-center">
          <loading-button :loading="sending" class="btn-indigo" type="submit">Create Postage</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Unit'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    postage_units: Object,
    channels: Object,
    categories: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        channel: null,
        category: null,
        unit_id: null,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.route('postage.store'), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
  },
}
</script>
