<script>

import {route} from './baseApiService';
import {Inertia} from '@inertiajs/inertia'

// -------------------
// Core functionality
// -------------------

// Core function with shared logic
function saveLabelCore(target_route, label, filters = null) {
  Inertia.put(route(target_route),
      {
        object: label,
        filters,
      },
      {
        preserveScroll: true,
        preserveState: true
      }
  );
}


// -------------------
// Label Index API Calls
// -------------------


export function saveLabelIndex(label, filters = null) {
  saveLabelCore('labels.update', label, filters);
}


// -------------------
// Packing View API Calls
// -------------------

//export function saveLabelView(packing, filters = null) {
//  savePackingCore('packings.view.update', packing, filters);
//}





</script>

