<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="mb-8 font-bold text-3xl">
        <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('productions.admin')">Production
        </inertia-link>
        <span class="font-medium">/</span>
        Add Oil
      </h1>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU"/>
        </div>
        <!--div class="my-10">
          <div>
            <input type="checkbox" id="essential" value="Essential" v-model="form.searchFilters">
            <label for="essential">Essential</label>
            <input class="ml-5" type="checkbox" id="fragrance" value="Fragrance" v-model="form.searchFilters">
            <label for="fragrance">Fragrance</label>
            <input class="ml-5" type="checkbox" id="blend" value="Blend" v-model="form.searchFilters">
            <label for="blend">Blend</label>
            <input class="ml-5" type="checkbox" id="premium" value="Premium" v-model="form.searchFilters">
            <label for="premium">Fragrance</label>
            <input class="ml-5" type="checkbox" id="fine" value="Fine" v-model="form.searchFilters">
            <label for="premium">Fine</label>


          </div>

          <span>
                <input type="checkbox" :id=1 :value="['[1 x 10ml']" v-model="form.searchFilters">
                <label class="mr-5" :for=1>[1 x 10ml]</label>
            </span>
          <span>
                <input type="checkbox" :id=2 :value="['[2 x 10ml']" v-model="form.searchFilters">
                <label class="mr-5" :for=2>[2 x 10ml]</label>
            </span>
          <select-input v-model="form.category" :error="errors.category" class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Category">
          <option v-for="category in categories" :key="category[0]" :value="category[0]">{{ category[1] }}</option>
        </select-input>
        </div-->
        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>

      </form>
    </div>
    <div class=" mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Type</th>
        </tr>
        <tr v-for="component in oils.data" :key="component.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          method="put"
                          :href="route('productions.component.create', production.id)"
                          :data="{
                            oil_id: component.id,
                          }"
                          preserve-scroll
                          tabindex="-1">
              {{ component.sku }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          method="put"
                          :href="route('productions.component.create', production.id)"
                          :data="{
                            oil_id: component.id,
                          }"
                          preserve-scroll
                          tabindex="-1">
              {{ component.name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          method="put"
                          :href="route('productions.component.create', production.id)"
                          :data="{
                            oil_id: component.id,
                          }"
                          preserve-scroll
                          tabindex="-1">
              {{ component.category_name }}
            </inertia-link>
          </td>
        </tr>
        <tr v-if="oils.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No oils found.</td>
        </tr>
      </table>
    </div>
    <pagination :links="oils.links"/>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
//import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'oils'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    production: Object,
    oils: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        searchFilters: [],
        search: this.filters.search,
        trashed: this.filters.trashed,

      },
    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
   */
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('productions.component.create', this.production.id), this.form, {preserveState: true})
    },
  },
}
</script>
