<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Prices</h1>
      <div>
        <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search">
          Export to CSV
        </a-->
        <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv_email=y&search='+form.search">
          Email CSVxxxxx
        </a-->
        <button
            v-if="auth.user.is_superuser && (auth.user.email=='andrew.j.stainer@gmail.com' || auth.user.email=='lloyd@nikura.com' || auth.user.email=='admin@nikura.com')"
            class="btn-indigo" @click="saveAllPrices">
          <span>Save All</span>
        </button>
        <button class="btn-indigo ml-2" @click="getAmazonPrices">
          <span>Amazon Sync</span>
        </button>
        <button class="btn-indigo ml-2" @click="getLWPrices">
          <span>LW Sync</span>
        </button>
        <button class="btn-indigo ml-2" @click="exportCSV">
          <span>Email CSV</span>
        </button>
      </div>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU"/>
        </div>
        <div class="my-10">
            <span v-for="channel in channels">
                <input type="checkbox" :id="channel[1]" :value="channel[0]" v-model="form.channelFilters">
                <label class="mr-5" :for="channel[1]">{{ channel[1] }}</label>
            </span>
          <!--select-input v-model="form.category" :error="errors.category" class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Category">
          <option v-for="category in categories" :key="category[0]" :value="category[0]">{{ category[1] }}</option>
        </select-input-->
        </div>
        <div class="my-10">
            <span>
                <input type="checkbox" :id="1" :value="live" v-model="form.statusFilter">
                <label class="mr-5" :for="1">Live Prices</label>
            </span>
          <span>
                <input type="checkbox" :id="3" :value="missing" v-model="form.missingFilter">
                <label class="mr-5" :for="3">Missing LW Price</label>
            </span>
          <span>
                <input type="checkbox" :id="2" :value="unequal" v-model="form.unequalLWFilter">
                <label class="mr-5" :for="2">LW ≠ Tool</label>
            </span>
          <span>
                <input type="checkbox" :id="2" :value="unequal" v-model="form.unequalAmazonFilter">
                <label class="mr-5" :for="2">Amazon ≠ Tool</label>
            </span>

          <!--select-input v-model="form.category" :error="errors.category" class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Category">
          <option v-for="category in categories" :key="category[0]" :value="category[0]">{{ category[1] }}</option>
        </select-input-->
        </div>
        <div>
          <select-input v-model="form.sortBy" class="pr-6 pb-8 w-56">
            <option value="name_asc">Sort by name asc</option>
            <option value="name_desc">Sort by name desc</option>
            <!--option  value="sku_asc">Sort by sku asc</option>
            <option  value="sku_desc">Sort by sku desc</option-->
            <option value="price_asc">Sort by price asc</option>
            <option value="price_desc">Sort by price desc</option>
            <option value="margin_perc_asc">Sort by margin % asc</option>
            <option value="margin_perc_desc">Sort by margin % desc</option>
            <option value="price_push_desc">Price push desc</option>
          </select-input>
        </div>
        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
          <button class="btn-indigo ml-4" @click="resetFilters">
            <span>Remove Filters</span>
          </button>
        </div>
      </form>
    </div>
    <!--div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
    </div-->
    <!--div>
      <br>
      To calculate price from margin (or vice versa), input data and
      <span class="font-bold">hit Enter/Return</span> or
      <span class="font-bold">hit Calculate button</span>.
      <br>
    </div-->
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <!--th class="px-6 pt-6 pb-4 text-left" colspan="1"></th-->
          <th class="px-6 pt-6 pb-4 text-center"><i class="fas fa-shopping-cart cursor-help"
                                                    title="Is product being sold on Linnworks (i.e. is it mapped)?"></i>
          </th>
          <th class="px-6 pt-6 pb-4 text-center border-l cursor-help"
              title="Does tool price match Linnworks?">LW
          </th>
          <th class="px-6 pt-6 pb-4 text-center"><i class="fa-brands fa-amazon cursor-help"
                                                    title="Does tool price match Amazon? (DB on Azure)"></i></th>
          <th class="border-l" colspan="2"><!-- Save + Undo --></th>
          <th class="px-6 pt-6 pb-4 text-left">Name</th>
          <th class="pr-6 pt-6 pb-4 text-left">Channel</th>
          <th class="px-6 pt-6 pb-4 text-left">Margin %</th>
          <th class="px-6 pt-6 pb-4 text-left" colspan="1">Price (inc VAT)</th>
          <!--th class="px-6 pt-6 pb-4 border-l">Price</th-->
          <!--th class="px-6 pt-6 pb-4 text-right border-l"></th--><!--Placeholder for save -->
          <!--th class="pl-10 pt-6 pb-4 text-right">Margin %</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Price Inc VAT</th-->
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Price (ex VAT)</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Margin</th>
          <!--th class="pl-10 pt-6 pb-4 text-right" colspan="1">Platform Fees (Django)</th-->
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Platform Fees</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Components</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Postage Cost</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">SNL Discount</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">SNL Threshold</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Postage Name</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Bundle SKU</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Tool Price</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">LW Price</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Amazon Price</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">LW SKU</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">LW ChannelSKU</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">LW Stock ID</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Pushed Timestamp</th>

          <!--th class="pl-10 pt-6 pb-4 text-right" colspan="1">Component Costs %</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Platform Fees %</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Postage Costs %</th>
          <th class="pl-10 pt-6 pb-4 text-right">Postage Name</th-->
        </tr>

        <tr v-for="price in this.pricesData" :key="price.id"
            :class="Math.abs(price.price - price.price_original) > 0.01 ? 'bg-indigo-100' : ''">
          <!-- Status - Is there a LW SKU? -->
          <td class="border-t" class="border-t" colspan="1">
            <div v-if="price.lw_channel_sku != null || price.lw_channel_sku != ''" class="px-6 py-4 flex items-center "
                 tabindex="-1">
              <i class="fas fa-check text-gray-600"></i>
            </div>
            <div v-else class="px-6 py-4 flex items-center " tabindex="-1">
            </div>
          </td>
          <!-- Status - Do prices match? -->
          <td class="border-t border-l"
              colspan="1">
            <div v-if="price.lw_channel_sku == null || price.lw_channel_sku == ''" class="px-6 py-4 flex items-center "
                 tabindex="-1">
            </div>
            <div v-else-if="price.price == price.lw_price" class="px-6 py-4 flex items-center " tabindex="-1">
              <i class="fas fa-check text-gray-600"></i>
            </div>
            <div v-else-if="price.lw_price==null || price.lw_price==''" class="px-6 py-4 flex items-center "
                 tabindex="-1">
              <div @click=" Price(price)" class="cursor-pointer">
                <i class="fas fa-ban text-gray-600"></i>
              </div>
            </div>
            <div v-else class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="savePrice(price)" class="cursor-pointer">
                <i class="fas fa-not-equal text-gray-600"></i>
              </div>
            </div>
          </td>
          <!-- Status - Does Amazon price match? -->
          <td class="border-t"  colspan="1">
            <div
                v-if="(price.channel.includes('am') || price.channel.includes('af'))
                && price.lw_channel_sku != '' && price.price == price.amazon_price"
                class="px-6 py-4 flex items-center ">
              <i class="fas fa-check text-gray-600"></i>
            </div>
            <div
                v-else-if="(price.channel.includes('am') || price.channel.includes('af'))
                && price.lw_channel_sku != '' && price.price != price.amazon_price &&
                price.amazon_price != null && price.amazon_price != ''"
                class="px-6 py-4 flex items-center ">
              <div @click="saveAmazonPrice(price)" class="cursor-pointer">
                <i class="fas fa-not-equal text-gray-600"></i>
              </div>
            </div>
            <div v-else class="px-6 py-4 flex items-center ">
            </div>
          </td>
          <!-- Save + Undo logic -->
          <td v-if="Math.abs(price.price - price.price_original) > 0.01" class="border-t border-l">
            <div class="px-6 py-5 flex items-center " tabindex="-1">
              <div @click="price.price=price.price_original, price.margin_perc=getMarginPerc(price)">
                <i class="fas fa-undo text-gray-600"></i>
              </div>
            </div>
          </td>
          <td v-if="Math.abs(price.price - price.price_original) > 0.01" class="border-t border-l">
            <div class="px-6 py-5 flex items-center " tabindex="-1">
              <div @click="savePrice(price)" class="cursor-pointer">
                <i class="fas fa-save text-gray-600"></i>
              </div>
            </div>
          </td>
          <td v-else class="border-t border-l" colspan="2">
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('bundles.edit', price.bundle_id)" tabindex="-1">
              {{ price.bundle_name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="pr-6 py-4 flex items-center" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              {{ price.channel_name }}
            </inertia-link>
          </td>
          <!-- margin perc input (beta) -->
          <td class="border-t">
            <div class="px-6 flex items-center">
              <input
                  class="w-20 border rounded p-2"
                  v-model="price.margin_perc"
                  v-on:keyup="price.price=getPrice(price)"
              />
              <span class="pl-2">%</span>
            </div>
          </td>
          <!-- Price -->
          <td class="border-t">
            <div class="px-6 flex items-center">
              <span class="pr-2">{{ price.currency_symbol }}</span>
              <input tabindex="1"
                     class="w-20 border rounded p-2"
                     v-model="price.price"
                     v-on:keyup="price.margin_perc=getMarginPerc(price)"
                     v-on:keyup.enter="savePrice(price)"
              />
            </div>
          </td>
          <!-- Price exvat -->
          <td class="border-t text-right">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              £{{ parseFloat(price.price * price.currency_conversion / 1.2).toFixed(2) }}
            </inertia-link>
          </td>

          <!-- Margin £ calculated via Django -->
          <!--td class="border-t text-right text-red-400">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              £{{ parseFloat(price.margin).toFixed(2) }}
            </inertia-link>
          </td-->
          <!-- Margin £ Calc -->
          <td class="border-t text-right">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              £{{ getMargin(price) }}
            </inertia-link>
          </td>
          <!-- Platform fees calculated via Django -->
          <!--td class="border-t text-right text-red-400">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              £{{ parseFloat(price.platform_fees).toFixed(2) }}
            </inertia-link>
          </td-->
          <!-- Platform fees calculalted via vue -->
          <td class="border-t text-right">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              £{{ parseFloat(getPlatformFees(price)).toFixed(2) }}
            </inertia-link>
          </td>
          <td class="border-t text-right">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              £{{ parseFloat(price.goods_cost).toFixed(2) }}
            </inertia-link>
          </td>
          <!--td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ parseFloat(price.postage_service_cost).toFixed(2) }}
            </div>
          </td-->
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ parseFloat(price.postage_bundle_cost).toFixed(2) }}
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1" v-if="parseFloat(getSnlDiscount(price)) !== 0">
              £{{ parseFloat(getSnlDiscount(price)).toFixed(2) }}
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1" v-if="parseFloat(price.snl_discount_threshold) !== 0">
              <span>{{ `${price.currency_symbol}${parseFloat(price.snl_discount_threshold).toFixed(2)}` }}</span>
            </div>
          </td>

          <!--td class="border-t text-right">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              {{ parseFloat(price.goods_cost_perc).toFixed(2) }}%
            </inertia-link>
          </td>
          <td class="border-t text-right">
            <inertia-link class="py-4" :href="route('bundles.edit', price.bundle_id)"
                          tabindex="-1">
              {{ parseFloat(price.platform_fees_perc).toFixed(2) }}%
            </inertia-link>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              {{ parseFloat(price.postage_service_cost_perc).toFixed(2) }}%
            </div>
          </td-->
          <!--td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.postage_service_name }}
            </div>
          </td-->
          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.postage_bundle_name }}
            </div>
          </td>
          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.bundle_sku }}
            </div>
          </td>
          <td class="border-t text-right pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.currency_symbol }}{{ price.price }}
            </div>
          </td>
          <td class="border-t text-right pl-10">
            <div class="py-4" tabindex="-1">
              <span v-if="price.lw_price != 0 && price.lw_price != '' && price.lw_price != null"
                    class="pr-2">{{ price.currency_symbol }}{{ price.lw_price }}</span>
            </div>
          </td>
          <td class="border-t text-right pl-10">
            <div class="py-4" tabindex="-1">
              <span v-if="price.amazon_price" class="pr-2">{{ price.currency_symbol }}{{ price.amazon_price }}</span>
            </div>
          </td>
          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.lw_sku }}
            </div>
          </td>
          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.lw_channel_sku }}
            </div>
          </td>
          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.lw_stock_id }}
            </div>
          </td>
          <td class="border-t">
            <div class="py-4 flex text-left px-10 ">
                <span v-if="price.pushed_timestamp != null && price.pushed_timestamp != '' ">
                  {{ price.pushed_timestamp.substring(0, 16) }}
                </span>
            </div>
          </td>
        </tr>
        <tr v-if="pricesData.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No prices found.</td>
        </tr>
      </table>
    </div>
    <!-- Removed template pagination. Below is same, but with form sent as well (for filters) -->
    <!--pagination :links="pricesLinks"/-->
    <div class="mt-6 -mb-1 flex flex-wrap">
      <template v-for="(link, key) in pricesLinks">
        <div v-if="link.url === null" :key="key" class="mr-1 mb-1 px-4 py-3 text-sm border rounded text-gray-400"
             :class="{ 'ml-auto': link.label === 'Next' }">{{ link.label }}
        </div>
        <inertia-link v-else :key="key"
                      class="mr-1 mb-1 px-4 py-3 text-sm border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
                      :class="{ 'bg-white': link.active, 'ml-auto': link.label === 'Next' }"
                      :href="link.url"
                      :data="form"
                      :preserveState="false"> <!-- Important this is false for prices -->
          {{ link.label }}
        </inertia-link>
      </template>
    </div>

  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import SelectInput from '@/Shared/SelectInput'

export default {
  metaInfo: {title: 'prices'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput,
    SelectInput
  },
  props: {
    auth: Object,
    prices: Object,
    filters: Object,
    channels: Object,
    //page: Object
  },
  data() {
    return {
      pricesData: this.prices.data,
      pricesLinks: this.prices.links,
      form: {
        search: this.filters.search,
        channelFilters: this.filters.channelFilters,
        statusFilter: this.filters.statusFilter,
        unequalLWFilter: this.filters.unequalLWFilter,
        unequalAmazonFilter: this.filters.unequalAmazonFilter,
        missingFilter: this.filters.missingFilter,
        sortBy: this.filters.sortBy,
        is_superuser: this.$parent.$data.is_superuser
        // Annoyingly can't make these blank, as preserveState = true screws everything up (prly due to having inputs there)
        //search: '',
        //channelFilters: [],
        //statusFilter: [],
        //unequalLWFilter: [],
        //unequalAmazonFilter: [],
        //missingFilter: [],
        //sortBy: [],
        //is_superuser: this.$parent.$data.is_superuser
      },
    }
  },
  //https://stackoverflow.com/questions/42133894/vue-js-how-to-properly-watch-for-nested-data
  /*
  watch: {
    'price.price': function (newVal, oldVal) {
      //to work with changes in someOtherProp
    },
    'price.testee': function (newVal, oldVal) {
      //to work with changes in prop
    }
  },
  */

  /*
    watch: {
      form: {
        handler: throttle(function () {
          let query = pickBy(this.form)
          this.$inertia.reload({
            data: {
              'search': query.search
            },
          })
        }, 150),
        deep: true,
      },
    },
   */
  mounted() {
    // Set platform fees for each price on page load
    this.pricesData.forEach(price => {
      this.setMargin(price);
      this.setMarginPerc(price);
    });
  },
  methods: {
    getSnlDiscount(price) {
      // Threshold is in own currency
      if (parseFloat(price.price) < parseFloat(price.snl_discount_threshold)) {
        // Return discount converted to £
        return parseFloat(price.snl_discount) * parseFloat(price.currency_conversion);
      } else {
        return 0;
      }
    },
    setMargin: function (price) {
      let margin = this.getMargin(price); // Use a separate method to calculate fees
      // Set the platform fees directly in the price object
      price.margin = margin;
    },
    setMarginPerc: function (price) {
      let marginPerc = this.getMarginPerc(price); // Use a separate method to calculate fees
      // Set the platform fees directly in the price object
      price.margin_perc = marginPerc;
    },
    // Amazon UK Fees (health & personal care) --> https://sell.amazon.co.uk/pricing#referral-fees
    //   # Amazon DE Fees (health & personal care) --> https://sellercentral.amazon.de/help/hub/reference/external/GGYND54PM5WB84UM?ref=efph_200336920_bred_201744070&locale=en-DE
    getPlatformFees: function (price) {
      // ** Amazon ** (includes 2% digital tax)
      // https://sell.amazon.co.uk/pricing?ref_=sduk_soa_pr_h#fulfillment-fees
      let platformFees
      if (price.channel == 'am') {
        if (price.price <= 10) {
          platformFees = Math.max(0.0816 * price.price, 0.25)
        } else {
          platformFees = 0.153 * price.price
        }
        // ** Amazon FBA ** (includes 2% digital tax)
      } else if (price.channel.includes('af-uk')) {
        var hazard_fee = 0
        if (price.bundle_hazard == 'Y') {
          hazard_fee = 0.1
        }
        if (price.price <= 10) {
          return Math.max(0.0816 * price.price, 0.25) + hazard_fee
        } else {
          platformFees = 0.153 * price.price + hazard_fee
        }
      } else if (price.channel.includes('af-eu')) {
        var hazard_fee = 0
        if (price.bundle_hazard == 'Y') {
          hazard_fee = 0.1
        }
        if (price.price <= 10) {
          platformFees = Math.max(0.08 * price.price, 0.3) + hazard_fee
        } else {
          platformFees = 0.15 * price.price + hazard_fee
        }
        // ** Ebay ** (includes 2% digital tax)
      } else if (price.channel == 'eb' || price.channel == 'ebm' || price.channel == 'ebs') {
        //return 0.0816 * price.price
        platformFees = 0.109 * price.price + 0.3
        // ** Etsy ** (includes 2% digital tax)
        // Listing fee (15p) + Transaction fee (5%) + Sale Fee (4% + 20p)
      } else if (price.channel == 'et' || price.channel == 'etm') {
        platformFees = 0.15 + 0.05 * price.price + 0.04 * price.price + 0.2
        // ** Shopify **
      } else if (price.channel == 'sh')
        platformFees = 0.019 * price.price + 0.2
      else {
        platformFees = 0
      }
      // Include currency conversion, as platform fees are given in euros
      return platformFees * price.currency_conversion
    },
    getMargin: function (price) {
      let margin = price.price * price.currency_conversion / 1.2 - price.goods_cost - this.getPlatformFees(price) - (price.postage_bundle_cost - this.getSnlDiscount(price) * price.currency_conversion)
      return parseFloat(margin).toFixed(2)
    },
    getMarginPerc: function (price) {
      //let margin = price.price/1.2 - price.goods_cost - price.postage_service_cost - this.getPlatformFees(price)
      let margin = this.getMargin(price)
      let margin_perc = margin / (price.price * price.currency_conversion / 1.2) * 100

      return parseFloat(margin_perc).toFixed(0)
    },
    getPrice: function (price) {
      // Method takes margin entered, and finds price to achieve this margin
      let target_margin_perc = price.margin_perc
      let goods_cost = parseFloat(price.goods_cost)
      let postage_bundle_cost = parseFloat(price.postage_bundle_cost)

      // Variables calculated within loop based on price.price
      var platform_fees_calc
      var margin_calc
      var price_calc

      // Calculate max margin achievable
      price.price = 999999
      let margin_perc_max = ((999999 / 1.2) - this.getPlatformFees(price)) / (999999 / 1.2) * 100

      // If margin entered exceeds max, give error
      if (Math.abs(target_margin_perc > margin_perc_max)) {
        return "too high"
      }

      // Start with low value, and work upwards until price achieves margin requested
      price.price = 0.01


      while (true) {
        platform_fees_calc = this.getPlatformFees(price)
        margin_calc = target_margin_perc / 100 * (price.price / 1.2)
        price_calc = 1.2 * (margin_calc + platform_fees_calc + goods_cost + postage_bundle_cost)
        if (Math.abs(price.price - price_calc) < 0.001) {
          return parseFloat(price.price).toFixed(2)
        } else {
          price.price += 0.001
        }
        if (Math.abs(price.price) > 1000) {
          return 'error'
        }
      }
    },
    savePrice(price) {
      // If channel is Shopify Or Amazon, check for LW SKU (i.e. if there is a place to push SKU to
      // If no LW SKU, ask to sync, otherwise ask to save/push
      // If channel is anything else, just ask to save
      // if (price.channel == 'sh' || price.channel == 'am' || price.channel == 'af' ) {

      if (price.lw_sku != null && price.lw_sku != '') {
        if (confirm(
            'Are you sure you want save and push this price to Linnworks? ' +
            'Please review details below before proceeding. ' +
            'If anything looks incorrect, try refreshing data via LW Sync. \n\n' +
            price.bundle_name + ' --> ' + price.channel_name + '\n\n' +
            'Linnworks SKU - ' + price.lw_sku + '\n' +
            'Current LW Price - £' + price.lw_price + '\n' +
            'New Price - £' + price.price
        )) {
          this.$inertia.put(this.route('prices'),
              {
                action: 'pushSave',
                search: this.form.search,
                channelFilters: this.form.channelFilters,
                statusFilter: this.form.statusFilter,
                unequalLWFilter: this.filters.unequalLWFilter,
                unequalAmazonFilter: this.filters.unequalAmazonFilter,
                missingFilter: this.filters.missingFilter,
                price: price,
              },
              {
                preserveScroll: true,
                preserveState: true,
                only: ['testee'],
                //onError: (error) => alert('Boo. Something went wrong'),
                onSuccess: () => {
                  if (this.$page.props.flash.error) {
                    alert(this.$page.props.flash.error)
                  } else {
                    // pricesData change. Need to set original AND LW price as this is no longer pulled from django
                    price.price_original = price.price
                    price.lw_price = price.price
                  }
                },
              })
        }
      } else {
        if (confirm(
            'Are you sure you want save this price? \n\n' +
            'Note that this Price is not currently mapped to a Linnworks SKU, so nothing will be pushed.'
        )) {
          // pricesData change. Need to set original price as this is no longer refreshed.
          this.$inertia.put(this.route('prices'),
              {
                //page: this.form.page,
                action: 'save',
                search: this.form.search,
                channelFilters: this.form.channelFilters,
                statusFilter: this.form.statusFilter,
                unequalLWFilter: this.filters.unequalLWFilter,
                unequalAmazonFilter: this.filters.unequalAmazonFilter,
                missingFilter: this.filters.missingFilter,
                price: price,
              },
              {
                preserveScroll: true,
                preserveState: true,
                only: ['testee'],  // For some reason channelflters disappear when saving without this...
                onSuccess: () => {
                  price.price_original = price.price
                },
              })
        }
      }
    },
    saveAmazonPrice(price) {
      if (confirm(
          'Do you want to align everything with the price used on Amazon? \n\n' +
          'Amazon Price --> £' + price.amazon_price + '\n\n' +
          'LW Price --> £' + price.lw_price + '\n' +
          'Tool Price --> £' + price.price
      )) {
        // Update price to use Amazon price
        price.price = price.amazon_price
        // pricesData change. Need to set original price as this is no longer refreshed.
        price.price_original = price.amazon_price
        this.$inertia.put(this.route('prices'),
            {
              //page: this.form.page,
              action: 'pushSave',
              search: this.form.search,
              channelFilters: this.form.channelFilters,
              statusFilter: this.form.statusFilter,
              unequalLWFilter: this.filters.unequalLWFilter,
              unequalAmazonFilter: this.filters.unequalAmazonFilter,
              missingFilter: this.filters.missingFilter,
              price: price,
            },
            {
              preserveScroll: true,
              preserveState: true,
              only: ['testee'], // For some reason channelflters disappear when saving without this...
              onSuccess: () => {
                price.price_original = price.price,
                    price.lw_price = price.price
              },
            })
      }
    },
    getLWPrices() {
      if (confirm('Are you sure you want to refresh sync status for all prices? This may take a few minutes. \n\n' +
          'Note that you may see a timeout error message after 30s. This will not impact the sync and can be ignored.')) {
        this.$inertia.put(this.route('prices'),
            {
              //page: this.form.page,
              action: 'getLWPrices',
              search: this.form.search,
              channelFilters: this.form.channelFilters,
              statusFilter: this.form.statusFilter,
              unequalLWFilter: this.filters.unequalLWFilter,
              unequalAmazonFilter: this.filters.unequalAmazonFilter,
              missingFilter: this.filters.missingFilter,
            },
            {
              preserveScroll: true,
              preserveState: true
            })
      }
    },
    getAmazonPrices() {
      if (confirm('Are you sure you want to sync with Amazon? This may take a few minutes. \n\n' +
          'Note that you may see a timeout error message after 30s. This will not impact the sync and can be ignored.')) {
        this.$inertia.put(this.route('prices'),
            {
              //page: this.form.page,
              action: 'getAmazonPrices',
              search: this.form.search,
              channelFilters: this.form.channelFilters,
              statusFilter: this.form.statusFilter,
              unequalLWFilter: this.filters.unequalLWFilter,
              unequalAmazonFilter: this.filters.unequalAmazonFilter,
              missingFilter: this.filters.missingFilter,
            },
            {
              preserveScroll: true,
              preserveState: true
            })
      }
    },
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('prices'), this.form,
          {
            preserveState: false,  // Can't set to true or prices don't refresh with filters/search
          })
    },
    resetFilters(e) {
      this.form.channelFilters = []
      e.preventDefault();
    },
    exportCSV() {
      if (confirm('Your CSV is being processed. Check your email and junk folder. ' +
          'If processing a large amount of data, you may see a timeout error, ' +
          'however you CSV will still be sent once generated.')) {
        //window.location.href = '/prices/?get_csv_email=y&search=' + form.search;
        this.$inertia.get(this.route('prices'),
            {
              'get_csv_email': 'y',
              'search': this.form.search,
            },
        )
      }
    },
    saveAllPrices() {
      //let changedPriceObjectsLEGACY = Object.entries(this.pricesData.filter(price => price.price != price.price_original))
      let changedPriceObjects = this.pricesData.filter(price => price.price != price.price_original)
      let changedLWPriceObjects = changedPriceObjects.filter(price => price.price != price.price_original && price.lw_price != null && price.lw_price != '')
      let confirmMsg = 'USE WITH CAUTION - Please review all changes before confirming (scroll down): \n\n'
      if (changedPriceObjects.length === 0) {
        alert('No prices have been changed.')
      } else {
        // LEGACY
        //for (let price of changedPriceObjectsVue) {
        //  confirmMsg += price[1]['bundle_name'] + '\n'
        //      + price[1]['channel_name'] + '\n'
        //      + '£' + price[1]['price_original'] + ' --> £' + price[1]['price'] + '\n\n'
        //}
        for (let price of changedPriceObjects) {
          confirmMsg += price.bundle_name + '\n'
              + price.channel_name + '\n'
              + '£' + price.price_original + ' --> £' + price.price + '\n\n'
        }
        if (confirm(
            confirmMsg
        )) {
          this.$inertia.put(this.route('prices'),
              {
                action: 'pushSaveAll',
                prices: changedPriceObjects,
                search: this.form.search,
                channelFilters: this.form.channelFilters,
                statusFilter: this.form.statusFilter,
                unequalLWFilter: this.filters.unequalLWFilter,
                unequalAmazonFilter: this.filters.unequalAmazonFilter,
                missingFilter: this.filters.missingFilter,
              },
              {
                preserveScroll: true,
                preserveState: true,
                only: ['testee'], // For some reason channelflters disappear when saving without this...
                onSuccess: () => {
                  if (this.$page.props.flash.error) {
                    alert(this.$page.props.flash.error)
                  } else {
                    // pricesData change. Need to set original price as this is no longer refreshed.
                    changedPriceObjects.forEach((price) => price.price_original = price.price)
                    // Only update LW price where LW prices already existed
                    // (If it didn't exist, it wouldn't push)
                    changedLWPriceObjects.forEach((price) => price.lw_price = price.price)
                  }
                },
              })

        }
      }
    },
  }
  ,
  computed: {
    changed_prices() {
      return this.pricesData.filter(price => price.price >= 0);
    },
    updateOriginalPrice() {
      return this.pricesData.map((price) => price.price_original);
    },
  },
}
</script>

