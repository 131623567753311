<template>
  <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Machine</th>
          <th class="px-6 pt-6 pb-4">Identifier</th>
            <th class="px-6 pt-6 pb-4">Setup</th>
            <th class="px-6 pt-6 pb-4">Size 10</th>
            <th class="px-6 pt-6 pb-4">Size 30</th>
            <th class="px-6 pt-6 pb-4">Size 50</th>
            <th class="px-6 pt-6 pb-4">Size 100</th>
            <th class="px-6 pt-6 pb-4">Size 250</th>
            <th class="px-6 pt-6 pb-4">Size 500</th>
              <th class="px-6 pt-6 pb-4">Size 1000</th>
          <th class="px-6 pt-6 pb-4">Size 5000</th>

        </tr>
        <tr v-for="machine in machines" :key="machine.id">
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ machine.category_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ machine.identifier }}
            </div>
          </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.setup"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>

            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_10"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_30"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_50"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>
              <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_100"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_250"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_500"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_1000"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="machine.size_5000"
                       @change="handleSaveMachine(machine)">
              </div>
            </td>


        </tr>
        <tr v-if="machines.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No machines found.</td>
        </tr>
      </table>
    </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import {adjustDate, setToToday, setToTomorrow, copyToClipboard, secondsToTime} from '@/Services/genericDataService';
import {productionTimeSeconds} from '@/Services/productionsDataService';
import Multiselect from "vue-multiselect";

export default {
  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput,
    'multiselect': Multiselect,
  },
  props: {
    auth: Object,
    machines: Object
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
    }
  },
  methods: {
    handleSaveMachine(machine) {
      this.$inertia.put(this.route('productions.machines.save'),
          {
            machine: machine,
          },
          {
            preserveScroll: true,
            preserveState: false
          })
    },
  },
}
</script>

