<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Quick Assembly Create</h1>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <!--form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU"/>
        </div>
      </form-->
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Barcode"/>
        </div>
        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4 text-left" colspan="1"></th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Pre Boxed LW SKU</th>
          <th class="px-6 pt-6 pb-4">Boxed LW SKU</th>
          <th class="px-6 pt-6 pb-4">Pre Boxed Barcode</th>
          <th class="px-6 pt-6 pb-4">Boxed Barcode</th>
        </tr>
        <tr v-for="bundle in bundles.data" :key="bundle.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t border-l">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="copyBarcode(bundle)" class="cursor-pointer">
                <i class="far fa-copy text-gray-600"></i>
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500 cursor-pointer"
                 @click="runQuickAssembly(bundle)">
              {{ bundle.name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500 cursor-pointer"
                 @click="runQuickAssembly(bundle)">
              {{ bundle.sku }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500 cursor-pointer"
                 @click="runQuickAssembly(bundle)">
              {{ 'FBA_BOX-' + bundle.sku }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500 cursor-pointer"
                 @click="runQuickAssembly(bundle)">
              {{ bundle.pre_boxed_barcode }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500 cursor-pointer"
                 @click="runQuickAssembly(bundle)">
              {{ bundle.boxed_barcode }}
            </div>
          </td>
        </tr>
        <tr v-if="bundles.data.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No bundles found.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'bundles'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    bundles: Object,
    filters: Object,
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        search: this.filters.search,
      },
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('quick_assemblies.create'), this.form, {preserveState: true})
    },
    runQuickAssembly(bundle) {
      this.copyBarcode(bundle)
      if (confirm(
          'Are you sure you want to move this item? \n\n' +
          bundle.sku + ' --> FBA_BOX-' + bundle.sku + '\n' +
          '0-POSTPROD --> 0-PICK_DEFAULT \n')) {
        this.$inertia.put(this.route('quick_assemblies.create'),
            {
              bundle_id: bundle.id,
            },
            {
              preserveScroll: true,
              preserveState: true, // post, put, patch, delete, and reload methods all set the preserveState option to true by default.
              //only: ['testee'],
            })
      }
    },
    copyBarcode(bundle) {
      //if (confirm('Are you sure you want to duplicate this bundle?')) {
      const textToCopy = bundle.boxed_barcode; // The string you want to copy

      // Create a temporary textarea to hold the text
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);

      // Select the text
      textarea.select();
      document.execCommand('copy'); // Copy the text

      // Clean up: Remove the temporary textarea
      document.body.removeChild(textarea);
      //}
    }
  },
}
</script>
