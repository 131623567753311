<template>
  <div>
    <div class="m-10 border-size-2">
      <div class="font-bold">
        Machine {{ machineNumber }}
      </div>
      <div class="mt-2">
        Estimated - {{ handleSecondsToTime(total_time) }}
      </div>
      <div>
        Completed - {{ total_elapsed_time }}
      </div>
    </div>
    <table class="whitespace-no-wrap bg-white rounded shadow col-auto">

      <draggable @start="drag=true" group="batches" :list="productions" @end="updateItemOrder"
                 draggable=".item" :data-list-name=listName>

        <tr v-for="(production, index) in productions"
            :key="production.id"
            :class="[getRowClass(production), 'hover:bg-indigo-200']"
        >
          <!--:class="getRowClass(production)"-->
          <!--class="item cursor-move m-4"-->
          <!--tr v-for="(production, index) in list1" :key="production.id" class="item cursor-move m-4"-->
          <td class="border-t">
            <div class="px-4 py-4 flex items-center " tabindex="-1">
              <div @click="saveProduction(production)">
                <i class="fa fa-grip-vertical text-indigo-400"></i>
              </div>
            </div>
          </td>
          <td class="border-t">
            <div
                class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" v-model="production.bulkSelect">
            </div>
          </td>
          <!-- Start/pause-->
          <td class="border-t">
            <!--div class="px-2 flex items-center cursor-pointer" @click="startTimer(index)" tabindex="-1"-->
            <div v-if="production.is_complete" class="px-6 flex items-center cursor-pointer">
              <!-- Empty if complete -->
            </div>
            <div v-else-if="production.status==0" class="px-6 flex items-center cursor-pointer"
                 @click="startProduction(production)" tabindex="-1">
              <i class="fa fa-play text-indigo-400"></i>
            </div>
            <div v-else-if="production.status==1" class="px-6 flex items-center cursor-pointer"
                 @click="pauseProduction(production)" tabindex="-1">
              <i class="fa fa-pause text-indigo-400"></i>
            </div>
            <div v-else-if="production.status==2" class="px-6 flex items-center cursor-pointer"
                 @click="resumeProduction(production)" tabindex="-1">
              <i class="fa fa-arrow-rotate-right text-indigo-400"></i>
            </div>
            <div v-else class="px-4 flex items-center">
            </div>
          </td>

          <!-- Complete / archive -->
          <td class="border-t">
            <!--div class="px-2 flex items-center cursor-pointer" @click="startTimer(index)" tabindex="-1"-->
            <div v-if="production.status==1 || production.status==2" class="px-6 flex items-center cursor-pointer"
                 @click="completeProduction(production)" tabindex="-1">
              <i class="fa fa-check text-indigo-400"></i>
            </div>
            <div v-else-if="production.status==3" class="px-6 flex items-center cursor-pointer"
                 @click="archiveProduction(production)" tabindex="-1">
              <i class="fa fa-box-archive text-indigo-400"></i>
            </div>
            <div v-else class="px-4 flex items-center">
            </div>
          </td>
          <!-- Split batch -->
          <td class="border-t">
            <div v-if="production.status==1 || production.status==2" class="px-6 flex items-center cursor-pointer"
                 @click="splitProduction(production)" tabindex="-1">
              <i class="fa fa-diagram-predecessor text-indigo-400"></i>
            </div>
            <div v-else class="px-4 flex items-center">
            </div>
          </td>

          <!-- Create assembly batches -->
          <td class="border-t">
            <div v-if="production.category!=''" class="px-6 flex items-center cursor-pointer"
                 @click="createAssemblyBatches(production)" tabindex="-1">
              <i class="fa fa-boxes-stacked text-indigo-400"></i>
            </div>
          </td>


          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>{{ production.timer }}</div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ handleSecondsToTime(handleProductionTimeSeconds(production)) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>{{ production.status_name }}</div>
            </div>
          </td>

          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <select v-model="production.label_status" class="w-48 border rounded p-2 bg-gray-100"
                      @change="saveProduction(production)">
                <option :value="null"></option>
                <option v-for="label_status in label_status_choices" :key="label_status[0]" :value="label_status[0]">{{
                    label_status[1]
                  }}
                </option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <select v-model="production.user" class="w-48 border rounded p-2 bg-gray-100"
                      @change="saveProduction(production)">
                <option :value="null"></option>
                <option v-for="user in users" :key="user[0]" :value="user[0]">{{
                    user[1]
                  }}
                </option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <select v-model="production.helper" class="w-48 border rounded p-2 bg-gray-100"
                      @change="saveProduction(production)">
                <option :value="null"></option>
                <option v-for="user in users" :key="user[0]" :value="user[0]">{{
                    user[1]
                  }}
                </option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <select v-model="production.category" class="w-56 border rounded p-2 bg-gray-100"
                      @change="saveProduction(production)">
                <option v-for="category in categories" :key="category[0]" :value="category[0]">{{
                    category[1]
                  }}
                </option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div v-if="production.oil_name  == null" class="px-2 flex items-center" tabindex="-1">
              <inertia-link :href="route('productions.component.create', production.id)" :method="post"
                            class="w-108 border rounded py-3 p-2 bg-gray-100"
                            tabindex="-1">
                -- Select Oil --
              </inertia-link>
            </div>
            <div v-else class="px-2 flex items-center" tabindex="-1">
              <inertia-link :href="route('productions.component.create', production.id)" :method="post"
                            class="w-108 overflow-hidden border rounded py-3 p-2 bg-gray-100"
                            tabindex="-1">
                {{ production.oil_name }}
              </inertia-link>

            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center pl-5" tabindex="-1">
              <input
                  v-model="production.size_10"
                  :class="production.size_10 > 0 ? 'bg-green-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_30"
                  :class="production.size_30 > 0 ? 'bg-green-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_50"
                  :class="production.size_50 > 0 ? 'bg-green-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 pr-5 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_100"
                  :class="production.size_100 > 0 ? 'bg-green-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t border-l">
            <div class="px-2 pl-5 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_250"
                  :class="production.size_250 > 0 ? 'bg-blue-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_500"
                  :class="production.size_500 > 0 ? 'bg-blue-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>

          <td class="border-t ">
            <div class="px-2 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_1000"
                  :class="production.size_1000 > 0 ? 'bg-blue-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 pr-5 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_5000"
                  :class="production.size_5000 > 0 ? 'bg-blue-200' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t border-l">
            <div class="px-2 pl-5 pr-5 flex items-center" tabindex="-1">
              <input
                  v-model="production.size_5"
                  :class="production.size_5 > 0 ? 'bg-yellow-100' : 'bg-gray-100'"
                  class="w-16 border rounded p-2 text-center"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <select v-model="production.auditor" class="w-48 border rounded p-2 bg-gray-100"
                      @change="saveProduction(production)">
                <option v-for="auditor in auditors" :key="auditor[0]" :value="auditor[0]">{{
                    auditor[1]
                  }}
                </option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-2 flex items-center" tabindex="-1">
              <select v-model="production.machine_type" class="w-48 border rounded p-2 bg-gray-100"
                      @change="saveProduction(production)">
                <option v-for="machine_type in machine_types" :key="machine_type[0]" :value="machine_type[0]">{{
                    machine_type[1]
                  }}
                </option>
              </select>
            </div>
          </td>


          <!--td class="border-t">
           <div class="px-2 flex items-center justify-center" tabindex="-1">
             <div>{{ production.pause_count }}</div>
           </div>
         </td-->


          <td class="border-t border-l w-10">
            <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
            <div v-if="production.oil_name" class="align-middle px-2 top-2x">
              <span v-if="production.oil_line" class="auto-tag  bg-orange-400"><span>{{
                  production.oil_line
                }}</span></span>
              <span v-if="production.oil_name.includes('Carrier')"
                    class="auto-tag bg-orange-400"><span>Litre</span></span>
              <span v-if="production.oil_name.includes('Fragrance')"
                    class="auto-tag bg-pink-400"><span>Frag</span></span>
              <span v-else-if="production.oil_name.includes('Blend')" class="auto-tag  bg-purple-400"><span>Blend</span></span>
              <span v-if="production.oil_big_cap" class="auto-tag  bg-indigo-400"><span>Big Cap</span></span>
            </div>
          </td>

          <td class="border-t border-l">
            <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
            <div class="align-middle px-2">
              <multiselect class="cursor-pointer"
                           v-model="production.tags"
                           :options="tag_options"
                           :multiple="true"
                           :searchable="false"
                           :close-on-select="true"
                           @close="saveProduction(production)"
                           :custom-label="customLabel"
                           :searchable="true"
                           :show-labels="false">
                >

                <!-- https://vue-multiselect.js.org/#sub-slots -->

                <!-- 1) Customised slot for selection. The default display before clicking and activating drop down-->
                <template #selection="{ values, search, isOpen }">
                  <!--div v-if="values.length && !isOpen"-->
                  <span v-for="value in values" :key="value"
                        :class="['ajs__multiselect__tag', getTagColorClass(value)]">
                        <span>{{ value }}</span>
                    <!-- Optionally include a button or icon to remove the tag if needed -->
                      </span>
                  <!--/div-->
                </template>

                <!-- 2) Customised slot for tags that appear above drop down -->
                <template #tag="{ option, search, remove }">

                  <span :class="['ajs__multiselect__tag', getTagColorClass(option)]">
                    {{ option }}
                    <!-- Optional: FontAwesome icon for removing the tag -->
                    <!--button @click="remove(option)" class="ml-2">
                      <i class="fas fa-times-circle"></i>
                    </button-->
                  </span>
                </template>

                <!-- No need for customised slot for dro[down options -->

                <!--template #option="{ option }">
                  <span :class="['bg-blue-500 px-2 py-1 rounded-full text-white']">{{ option }}</span>
                </template-->

              </multiselect>
            </div>
          </td>
          <td class="border-t border-l">
            <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
            <div class="align-middle px-2">
              <multiselect class="cursor-pointer" v-model="production.issue_tags" :options="issue_tag_options"
                           :multiple="true"
                           :searchable="false"
                           :close-on-select="true" :show-labels="false" @close="saveProduction(production)">
              </multiselect>
            </div>
          </td>
          <td class="border-t border-l">
            <div class="px-2 flex items-center w-120" tabindex="-1">
              <input
                  v-model="production.comments"
                  class="w-120 border rounded p-2 bg-gray-100"
                  @input="production.change_status='Y'"
                  v-on:keyup.enter="saveProduction(production)"
              />
            </div>
          </td>

          <!--td class="border-t border-l">
            <div class="px-4 flex items-center justify-center" tabindex="-1">
              <div>{{ production.elapsed_seconds }}</div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-4 flex items-center justify-center" tabindex="-1">
              <div>{{ production.ongoing_seconds }}</div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-4 flex items-center justify-center" tabindex="-1">
              <div>{{ production.total_seconds }}</div>
            </div>
          </td-->
          <td class="border-t">
            <div class="px-6 flex items-center justify-center" tabindex="-1">
              <div>{{ production.batch_code }}</div>
              <!--button @click="resetTimer(index)">Reset</button-->
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center justify-center" tabindex="-1">
              <div>{{ production.id }}</div>


              <!--button @click="resetTimer(index)">Reset</button-->

            </div>
          </td>


          <td class="border-t">
            <!--div class="px-2 flex items-center cursor-pointer" @click="startTimer(index)" tabindex="-1"-->
            <div class="px-4 flex items-center cursor-pointer"
                 @click="deleteProduction(production)" tabindex="-1">
              <i class="fa fa-xmark text-indigo-400"></i>
            </div>
          </td>
        </tr>

        <tr class="text-left font-bold" slot="header">
          <th class="px-6 pt-6 pb-4" colspan="6"></th>

          <th class="px-6 pt-6 pb-4">Timer</th>
          <th class="px-6 pt-6 pb-4">Estimated</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-2 pt-6 pb-4">Label Status</th>
          <th class="px-2 pt-6 pb-4">Producer</th>
          <th class="px-2 pt-6 pb-4">Helper</th>
          <th class="px-2 pt-6 pb-4">Category</th>
          <th class="px-2 pt-6 pb-4 text-center">Oil</th>
          <th class="px-2 pt-6 pb-4 text-center">10ml</th>
          <th class="px-2 pt-6 pb-4 text-center">30ml</th>
          <th class="px-2 pt-6 pb-4 text-center">50ml</th>
          <th class="px-2 pt-6 pb-4 text-center">100ml</th>
          <th class="px-2 pt-6 pb-4 text-center border-l">0.25L</th>
          <th class="px-2 pt-6 pb-4 text-center">0.5L</th>
          <th class="px-2 pt-6 pb-4 text-center">1L</th>
          <th class="px-2 pt-6 pb-4 text-center">5L</th>
          <th class="px-2 pt-6 pb-4 text-center border-l">5ml</th>
          <th class="px-2 pt-6 pb-4">Auditor</th>
          <th class="px-2 pt-6 pb-4">Machine</th>

          <!--th class="px-4 pt-6 pb-4">Pause #</th-->
          <th class="px-4 pt-6 pb-4 border-l">Auto Tags</th>
          <th class="px-4 pt-6 pb-4 border-l">Custom Tags</th>
          <th class="px-4 pt-6 pb-4 border-l">Issue Tags</th>
          <th class="px-4 pt-6 pb-4 border-l">Comments</th>
          <!--th class="px-4 pt-6 pb-4 border-l">Elapsed</th>
          <th class="px-4 pt-6 pb-4">Ongoing</th>
          <th class="px-4 pt-6 pb-4">Total</th-->
          <th class="px-6 pt-6 pb-4">Batch Code</th>
          <th class="px-6 pt-6 pb-4">ID</th>
          <th class="" colspan="1"></th>
        </tr>
      </draggable>
      <tr v-if="productions.length === 0">
        <td class="border-t px-6 py-4" colspan="20">No productions allocated.</td>
      </tr>
    </table>

  </div>
</template>

<script>

import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import Swal from 'sweetalert2';
import {secondsToTime} from '@/Services/genericDataService';
import {productionTimeSeconds, productionStartTimer} from '@/Services/productionsDataService';

export default {
  props: ['productions', 'auth', 'users', 'auditors', 'categories', 'machine_types', 'view_type', 'machineNumber', 'listName', 'label_status_choices', 'machine_times'],
  components: {
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
  },
  data() {
    return {
      tag_options: [
        'Open Order',
        'No / Low Raw',
        'FBA Open Order',
        'OT',
      ],
      tag_option_colours: [
        {name: 'Open Order', color: 'bg-red-500'},
        {name: 'No / Low Raw', color: 'bg-yellow-500'},
        {name: 'FBA Open Order', color: 'bg-blue-500'},
        {name: 'OT', color: 'bg-orange-500'}
      ],
      issue_tag_options: [
        'Label Printer',
        'Machine Failure',
        'Oil Stock',
        'Machine Failure',
        'Spillage'
      ],
      timers: [], // each production will have a timer ID stored here
    }
  },
  methods: {
    getTagColorClass(tagName) {
      const tag = this.tag_option_colours.find(option => option.name === tagName);
      return tag ? tag.color : 'bg-gray-200'; // default color if not found
    },
    updateItemOrder(event) {
      this.$emit('update-item-order', event);
    },
    saveProduction(production) {
      this.$inertia.put(this.route('productions.admin.save-batch', this.view_type),
          {
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false
          })
    },
    startProduction(production) {
      this.$inertia.put(this.route('productions.admin.start-batch', this.view_type),
          {
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false,
            onSuccess: () => {
              alert('Show time! Your batch has now started. Go forth and make some oil!')
            },
          })
    },
    resumeProduction(production) {
      this.$inertia.put(this.route('productions.admin.start-batch', this.view_type),
          {
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false,
          })
    },

    pauseProduction(production) {
      this.$inertia.put(this.route('productions.admin.pause-batch', this.view_type),
          {
            production: production,
          },
          {
            preserveScroll: true,
            preserveState: false
          })
    },
    createAssemblyBatches(production) {
      // Generate the list of non-zero batches
      const nonZeroBatches = [];
      const sizeQuantityMapping = {
        //'10ml': production.size_10,
        '50ml': production.size_50,
        '100ml': production.size_100,
        // Add more sizes if needed
      };

      // Loop through the sizeQuantityMapping to find non-zero quantities
      for (const [size, quantity] of Object.entries(sizeQuantityMapping)) {
        if (quantity > 0) {
          nonZeroBatches.push(`${size} x ${quantity}`);
        }
      }

      if (nonZeroBatches.length > 0) {
        const batchList = nonZeroBatches.join('\n');
        const userConfirmed = window.confirm(
            'Are you sure you want to create the following assembly batches for this oil?\n\n' +
            production.oil_name + '\n\n' +
            batchList
        );

        if (userConfirmed) {
          /*
          this.$inertia.put(this.route('assemblies.admin.create-from-production'),
              {
                production: production,
                filters : {
                  category: 'af-uk-pr' // Direct user to MFN
                }
              },
              {
                preserveScroll: true,
                preserveState: false
              }
          );
           */
           this.$inertia.put(this.route('productions.admin.create-assemblies', this.view_type),
              {
                production: production,
              },
              {
                preserveScroll: true,
                preserveState: false
              }
          );
        }
      } else {
        alert("No batches to create. Quantities are 0 for relevant sizes");
      }
    },
    splitProduction(production) {
      const sizes = [
        {label: '10ml', key: 'size_10', value: production.size_10},
        {label: '30ml', key: 'size_30', value: production.size_30},
        {label: '50ml', key: 'size_50', value: production.size_50},
        {label: '100ml', key: 'size_100', value: production.size_100},
        {label: '250ml', key: 'size_250', value: production.size_250},
        {label: '500ml', key: 'size_500', value: production.size_500},
        {label: '1000ml', key: 'size_1000', value: production.size_1000},
        {label: '5000ml', key: 'size_5000', value: production.size_5000},
        {label: '5ml', key: 'size_5', value: production.size_5}
      ];

      let htmlContent = `
<div class = "mt-5 text-xl">${production.oil_name}</div>
    <div class="mt-10" style="display: grid; grid-template-columns: auto auto auto auto; gap: 20px; align-items: center;">
      <div><strong>Size</strong></div>
      <div><strong>Total</strong></div>
      <div><strong>Completed</strong></div>
      <div><strong>Remaining</strong></div>
  `;

      sizes.forEach((size, index) => {
        htmlContent += `
      <div>${size.label}</div>
      <div>${size.value}</div>
      <input id="swal-input${index + 1}" class="swal2-input mx-auto" placeholder="0" style="width: 80px;">
      <div id="remaining-${index + 1}">${size.value}</div>
    `;
      });

      htmlContent += `</div>`;

      Swal.fire({
        title: 'Split Batch',
        html: htmlContent,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Submit',
        didOpen: () => {
          sizes.forEach((size, index) => {
            const inputElement = document.getElementById(`swal-input${index + 1}`);
            const remainingElement = document.getElementById(`remaining-${index + 1}`);

            inputElement.addEventListener('input', () => {
              const inputValue = parseInt(inputElement.value) || 0;
              const remainingValue = size.value - inputValue;

              if (remainingValue < 0) {
                remainingElement.textContent = 'Invalid';
                remainingElement.style.color = 'red';
              } else {
                remainingElement.textContent = remainingValue;
                remainingElement.style.color = 'black';
              }
            });
          });
        },
        preConfirm: () => {
          const invalidInputs = sizes.some((size, index) => {
            const inputValue = parseInt(document.getElementById(`swal-input${index + 1}`).value) || 0;
            return size.value - inputValue < 0;
          });

          if (invalidInputs) {
            Swal.showValidationMessage('One or more inputs result in a negative remaining value. Please correct them.');
            return false; // Prevents the modal from closing
          }

          // Create the original and new production objects
          const updatedProduction = {...production};
          const newProduction = {...production};

          sizes.forEach((size, index) => {
            const inputValue = parseInt(document.getElementById(`swal-input${index + 1}`).value) || 0;
            const remainingValue = size.value - inputValue;

            // Update the original production with the completed values
            updatedProduction[size.key] = inputValue;

            // Set the new production to the remaining values
            newProduction[size.key] = remainingValue;
          });

          return {updatedProduction, newProduction};
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const {updatedProduction, newProduction} = result.value;

          // Send both the original updated production and the new production to the backend
          this.$inertia.post(this.route('productions.admin.split-batch', this.view_type),
              {
                production: updatedProduction,
                newProduction: newProduction
              },
              {
                preserveScroll: true,
                preserveState: false
              }
          );
        } else {
          console.log('Batch split canceled by user.');
        }
      });
    },
    completeProduction(production) {
      if (confirm('Are you sure you want to mark this batch as complete?')) {
        this.$inertia.put(this.route('productions.admin.complete-batch', this.view_type),
            {
              production: production,
            },
            {
              preserveScroll: true,
              preserveState: false
            })
      }
    },
    archiveProduction(production) {
      if (confirm('Are you sure you want to archive this batch?')) {
        this.$inertia.put(this.route('productions.admin.archive-batch', this.view_type),
            {
              production: production,
            },
            {
              preserveScroll: true,
              preserveState: false
            })
      }
    },
    deleteProduction(production) {
      if (confirm('Are you sure you want to delete this production batch?')) {
        this.$inertia.delete(this.route('productions.destroy', this.view_type, production.id),
            {
              preserveScroll: true,
              preserveState: false
            })
      }
    },
    getRowClass(production) {
      if (production.bulkSelect) {
        return 'bg-indigo-100 item cursor-move m-4'; // Replace 'bg-purple-500' with your actual purple class
      } else if (production.status == 3) {
        return 'bg-gray-200 item cursor-move m-4';
      } else if (production.status == 2) {
        return 'bg-yellow-100 item cursor-move m-4';
      } else if (production.status == 1) {
        return 'bg-green-100 item cursor-move m-4';
      } else {
        return 'item cursor-move m-4';
      }
    },
    // Estimated Time Methods
    handleSecondsToTime(seconds) {
      return secondsToTime(seconds)
    },
    handleProductionTimeSeconds(production) {
      return productionTimeSeconds(production, this.machine_times)
    },
  },
  computed: {
    total_time: function () {
      let sum = 0;
      this.productions.forEach((production) => {
        sum += this.handleProductionTimeSeconds(production);
      });
      return sum;
    },
    total_elapsed_time: function () {
      let total_seconds = 0;
      this.productions.forEach(function (production) {
        if (production.status === 3) {
          total_seconds += production.total_seconds;
        }
      });
      return secondsToTime(total_seconds);
    }
  },
  mounted() {
    // Very smart mount
    // If in progress, calculate elapsed time (using timestamp and delta to now)
    // If not in progress, just use elapsed time
    for (let i = 0; i < this.productions.length; i++) {
      //if (this.productions[i].id === 2) {
      if (this.productions[i].in_progress) {
        productionStartTimer(this.productions[i]);
      }
    }
  }
}
</script>
