<template>
  <div class="">
    <div class="mb-4">
      <div class="flex items-center group py-3" :href="route('packings.admin')">
        <div class="text-gray-500">WHOLESALE</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('customers')">
        <i :class="['fas fa-users mr-2 ', isUrl('customers') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('customers') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Customers</div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('scalings')">
        <i :class="['fas fa-layer-group mr-2 ', isUrl('scalings') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('scalings') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Scaling Sandbox</div>
      </inertia-link>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl'],
  methods: {
  },
}
</script>
