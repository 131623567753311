<script>

import {route} from './baseApiService';
import {Inertia} from '@inertiajs/inertia'

// -------------------
// Core functionality
// -------------------

function toggleCategoryCore(target_route, categoryKey = null, filters = null) {
  Inertia.get(route(target_route),
      {
        category: categoryKey,
        status: filters.status
      },
      {
        preserveScroll: true,
        preserveState: false
      })
}

function createAssemblyCore(target_route, filters = null) {
  Inertia.post(route(target_route),
      {
        filters
      },
      {
        preserveScroll: true,
        preserveState: false
      })
}

// Core function with shared logic
function saveAssemblyCore(target_route, assembly, filters = null) {
  Inertia.put(route(target_route),
      {
        object: assembly,
        filters,
      },
      {
        preserveScroll: true,
        preserveState: true
      }
  );
}

// Have to do put visit, as delete doesn't allow data (filters) to be sent
// router.delete(url, options)
function deleteAssemblyCore(target_route, assemblyId, filters = null) {
  Inertia.put(route(target_route, assemblyId), // Assuming the route takes the packing ID as a parameter
    {
      filters,
    },
    {
      preserveScroll: true,
    }
  );
}

function updateItemOrderCore(target_route, items, filters = null) {
  Inertia.put(route(target_route),
    {
      items: items,
      filters
    },
    {
      preserveScroll: true,
      preserveState: false
    }
  );
}



// -------------------
// Assembly Admin API Calls
// -------------------

// No need for own URL? (only toggling category via GET)
export function toggleCategoryAdmin(categoryKey = null, filters = null) {
  toggleCategoryCore('assemblies.admin', categoryKey, filters);
}

export function createAssemblyAdmin(filters = null) {
  createAssemblyCore('assemblies.admin.create', filters);
}

export function saveAssemblyAdmin(assembly, filters = null) {
  saveAssemblyCore('assemblies.admin.update', assembly, filters);
}

export function deleteAssemblyAdmin(assemblyId, filters = null) {
  deleteAssemblyCore('assemblies.admin.destroy', assemblyId, filters);
}

export function updateItemOrderAdmin(items, filters = null) {
  updateItemOrderCore('assemblies.admin.reorder', items, filters);
}



// -------------------
// Packing View API Calls
// -------------------

export function toggleCategoryView(categoryKey = null, filters = null) {
  toggleCategoryCore('assemblies.view', categoryKey, filters);
}

/*
export function saveAssemblyView(packing, filters = null) {
  saveAssemblyCore('assemblies.view.update', packing, filters);
}
 */






// -------------------
// (Hopefully) redundant functions
// -------------------

/*
export function toggleCategory($inertia, target_route, categoryKey = null, status = null) {
  $inertia.get(target_route,
      {
        category: categoryKey,
        status: status
      },
      {
        preserveScroll: true,
        preserveState: false
      })
}

export function savePacking($inertia, target_route, packing, filters = null) {
  $inertia.put(target_route,
      {
        packing,
        filters,
      },
      {
        preserveScroll: true,
        preserveState: true,
      })
}
 */




</script>

