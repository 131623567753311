<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Units</h1>
      <inertia-link class="btn-indigo" :href="route('units.create')">
        <span>Create</span>
        <span class="hidden md:inline">Unit</span>
      </inertia-link>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name"/>
        </div>
        <div class="my-10">
            <span v-for="category in categories">
                <input type="checkbox" :id="category[1]" :value="category[0]" v-model="form.categoryFilters">
                <label class="mr-5" :for="category[1]">{{ category[1] }}</label>
            </span>
        </div>
        <div class="my-10">
            <span v-for="supplier in suppliers">
                <input type="checkbox" :id="supplier.name" :value="supplier.id" v-model="form.supplierFilters">
                <label class="mr-5" :for="supplier.name">{{ supplier.name }}</label>
            </span>
        </div>
        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <div class=" mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Supplier</th>
          <th class="px-6 pt-6 pb-4">Currency</th>
          <th class="px-6 pt-6 pb-4">Cost Ex VAT</th>
          <th class="px-6 pt-6 pb-4">Quantity</th>
          <th class="px-6 pt-6 pb-4">UoM</th>
          <th class="px-6 pt-6 pb-4" colspan="2">Density</th>
        </tr>
        <tr v-for="unit in units.data" :key="unit.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('units.edit', unit.id)">
              {{ unit.name }}
              <icon v-if="unit.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('units.edit', unit.id)">
              {{ unit.sku }}
              <icon v-if="unit.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('units.edit', unit.id)">
              {{ unit.category_name }}
              <icon v-if="unit.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              {{ unit.supplier_name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              {{ unit.currency_name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              {{ unit.cost }}
            </inertia-link>
          </td>
                     <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              {{ unit.purchase_quantity }}
            </inertia-link>
          </td>
           <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              {{ unit.measurement_name }}
            </inertia-link>
          </td>

           <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              {{ unit.density }}
            </inertia-link>
          </td>
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              {{ unit.grade_name }}
            </inertia-link>
          </td-->
          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('units.edit', unit.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="units.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No units found.</td>
        </tr>
      </table>
    </div>
    <!--pagination :links="units.links" /-->
    <div class="mt-6 -mb-1 flex flex-wrap">
      <template v-for="(link, key) in units.links">
        <div v-if="link.url === null" :key="key" class="mr-1 mb-1 px-4 py-3 text-sm border rounded text-gray-400"
             :class="{ 'ml-auto': link.label === 'Next' }">{{ link.label }}
        </div>
        <inertia-link v-else :key="key"
                      class="mr-1 mb-1 px-4 py-3 text-sm border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
                      :class="{ 'bg-white': link.active, 'ml-auto': link.label === 'Next' }"
                      :href="link.url"
                      :data="form"
                      :preserveState="true">
          {{ link.label }}
        </inertia-link>
      </template>
    </div>


  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
//import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'units'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    units: Object,
    //filters: Object,
    categories: Object,
    suppliers: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        //search: this.filters.search,
        //categoryFilters: this.filters.categoryFilters,
        search: '',
        categoryFilters: [],
        supplierFilters: [],
      },
    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search':query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
  */
  methods: {
    submit() {
      this.$inertia.get(this.route('units'), this.form, {preserveState: true})
    },
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
