<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Inventory</h1>
      <div class="flex justify-end">
        <!--inertia-link class="btn-indigo ml-4" :href="route('inventory.create')">
          <span>Create Log</span>
        </inertia-link-->
      </div>
    </div>

    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="ID / SKU"/>
        </div>
        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
        </div>
      </form>
    </div>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4 text-center"><!--i class="fas fa-shopping-cart cursor-help"
                                                    title="Is product being sold on Linnworks (i.e. is it mapped)?"></i-->
          </th>
          <th class="px-6 pt-6 pb-4">Inventory ID</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Total Quantity</th>
          <th class="px-6 pt-6 pb-4">Remaining</th>
          <th class="px-6 pt-6 pb-4">Zeroed</th>
          <th class="px-6 pt-6 pb-4">Modified</th>

          <th class="px-6 pt-6 pb-4">Location</th>
          <th class="px-6 pt-6 pb-4">Notes</th>

          <th class="px-6 pt-6 pb-4">ID</th>


        </tr>
        <tr v-for="item in inventory.data" :key="inventory.id" class="">
          <td class="border-t">
            <div @click="zeroInventory(item)" class="px-6 py-4 text-center cursor-pointer">
              <i class="fas fa-arrow-down text-gray-600"></i>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.inventory_id }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.sku }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.total_quantity }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.remaining_quantity }}
            </div>
          </td>
          <td class="border-t">
            <div v-if="item.zeroed" class="px-6 py-4 flex items-center">
              {{ item.zeroed.substring(0, 16) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.modified.substring(0, 16) }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.location }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.notes }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ item.id }}
            </div>
          </td>


        </tr>
        <tr v-if="inventory.data.length === 0">
          <td class="border-t px-6 py-4" colspan="10">None found.</td>
        </tr>
      </table>
    </div>
    <!--pagination :links="inventory.links"/-->
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilte

export default {
  metaInfo: {title: 'inventory'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    inventory: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  methods: {
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('inventory'), this.form,
          {
            preserveState: false,  // Can't set to true or prices don't refresh with filters/search
          })
    },
    zeroInventory(inventory) {
      //Below must be get, not reload
      if (confirm('Are you sure you want to set this to zero?')) {
        this.$inertia.put(this.route('inventory.set.zero'),
            {
              filters: this.filters,
              object: inventory
            },
            {
              preserveState: false,  // Can't set to true or prices don't refresh with filters/search
            })
      }
    },
  },
}
</script>
