<template>
  <div>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">

          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Shipment Date</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Days Since Shipment</th>

          <th class="px-6 pt-6 pb-4">Units Sent</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Label/Box Count</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Batch Code</th>
          <!--th class="px-6 pt-6 pb-4" colspan="1">Packing ID</th>
          <th class="px-6 pt-6 pb-4">Batch Quantity</th-->


          <!--th class="px-6 pt-6 pb-4" colspan="2">Status</th-->

        </tr>
        <!--tr v-for="packing in packings.data" :key="packing.id" v-if="packing.user_id != null"-->
        <tr v-for="packing in packings" :key="packing.id">
          <td class="border-t w-96">
            <div class="py-4 px-6 flex items-center w-96" tabindex="-1">
              <span class="truncate" v-if="packing.bundle_sku == null"> -- No product selected --</span>
              <span class="truncate" v-else>{{ packing.bundle_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="packing.bundle_sku == null"> -- No product selected --</span>
              <span v-else>{{ packing.bundle_sku }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="packing.category == null"> -- No category selected --</span>
              <span v-else>{{ packing.category_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span>
                 <!-- {{ packing.shipped_timestamp.substring(0, 10) }} -->
                   {{ formatDate(packing.shipped_timestamp) }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ calculateDaysAgo(packing.shipped_timestamp) }} days ago
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.units_sent }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.labels_count }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.batch_code }}
            </div>
          </td>
          <!--td class="border-t">
           <div class="px-6 py-4 flex items-center focus:text-indigo-500">
             {{ packing.id}}
           </div>
         </td>
                 <td class="border-t">
           <div class="px-6 flex items-center" tabindex="-1">
             <div>
               {{ packing.quantity_required }}
             </div>
           </div>
         </td-->
        </tr>
        <tr v-if="packings.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No packing batches found</td>
        </tr>
      </table>
    </div>
    <pagination :links="links"/>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import {savePackingView} from '@/Services/packingsApiService';
import Pagination from '@/Shared/Pagination'
import {isDateInPast} from '@/Services/genericDataService';

export default {
  props: ['packings', 'links', 'filters'],
  components: {
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
    Pagination
  },
  data() {
    return {}
  },
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
    handleSavePacking(packing) {
      //savePackingView(packing, this.filters)
      savePackingView(packing, this.filters)
    },
    calculateDaysAgo(shippedTimestamp) {
      if (!shippedTimestamp) {
        return '--'; // or any placeholder you want to show when the date is not available
      }
      const shippedDate = new Date(shippedTimestamp);
      const currentDate = new Date();
      const differenceInTime = currentDate.getTime() - shippedDate.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      return differenceInDays;
    },
    formatDate(isoString) {
      if (!isoString) return '';

      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const date = new Date(isoString);
      const dayOfMonth = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${dayOfMonth} ${month} ${year}`;
    },
  }
}
</script>
