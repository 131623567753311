<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">FBA Packing - {{ capitalizedStatus }}</h1>
    </div>
    <div class="mt-20">
      <!--h2 class="mt-16 mb-8 font-bold text-2xl">Batches</h2-->
      <CategoryToggleHeader
          :filters="filters"
          :categoryCounts="categoryCounts"
          @category-toggled="handleToggleCategory"
      />
      <!--div class="my-10 flex">
        <div
            v-for="(categoryData, categoryKey) in categoryButtons"
            :key="categoryKey"
            :class="`${categoryData.colour} flex-1 text-white py-4 text-center cursor-pointer min-w-[200px]`"
            @click="handleToggleCategory(categoryKey)"
        >
          <span :class="[
                'text-2xl font-bold',
                filters.category === categoryKey ? 'underline' : ''
              ]">
                     {{ categoryData.title }} - {{ categoryCounts[categoryKey] || 0 }} Batches
          </span>
        </div>
      </div-->
      <PackingsViewTable :packings="packings.data" :filters="filters" :links="packings.links"/>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import PackingsViewTable from '@/Components/PackingsViewTable';
import CategoryToggleHeader from '@/Components/CategoryToggleHeader';
import {toggleCategoryView} from '@/Services/packingsApiService';

export default {
  metaInfo: {title: 'packings'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    PackingsViewTable,
    CategoryToggleHeader
  },
  props: {
    auth: Object,
    packings: Object,
    users: Object,
    filters: Object,
    categoryCounts: Object
  },
  data() {
    return {
      /*
      categoryButtons: {
        'af-uk-pr': {'title': 'MFN', 'colour': 'bg-red-400'},
        'af-uk': {'title': 'UK', 'colour': 'bg-blue-400'},
        'af-eu-de': {'title': 'Germany', 'colour': 'bg-indigo-400'},
        'af-eu-fr': {'title': 'France', 'colour': 'bg-green-400'},
        'af-eu-es': {'title': 'Spain', 'colour': 'bg-orange-400'},
        'af-eu-it': {'title': 'Italy', 'colour': 'bg-pink-400'}
      },
       */
      //
      //form: {
      //  search: this.filters.search,
      //  trashed: this.filters.trashed,
      //},
    }
  },
  methods: {
    handleToggleCategory(categoryKey) {
      //toggleCategory(this.$inertia, this.route('packings.view'), categoryKey, this.filters.status);
      toggleCategoryView(categoryKey, this.filters.status);
    }
  },
  computed: {
    unassigned_count() {
      //return this.packings.data.filter(packing => packing.user_id == null).length;
      return this.packings.data.filter(packing => packing.user_id != this.auth.user.id).length;
    },
    assigned_count() {
      return this.packings.data.filter(packing => packing.user_id == this.auth.user.id).length;
    },
    capitalizedStatus() {
      const status = this.filters.status;
      const translationMap = {
        '0': 'Ready to Pack',
        '3': 'Ready to Ship',
        '4': 'Shipped',
        // add more mappings here
      };
      const translatedStatus = translationMap[status] || status;
      return translatedStatus
    }
  }
}
</script>
