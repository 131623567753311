<template>
  <div>
    <div class="mb-4">
      <div class="flex items-center group py-3">
        <div class="text-gray-500">PRICING</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('currencies')">
        <i :class="['fas fa-dollar-sign fa-fw mr-2 ', isUrl('currencies') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <!--icon name="dashboard" class="w-4 h-4 mr-2" :class="isUrl('currencies') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" /-->
        <div :class="isUrl('currencies') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Currencies</div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('suppliers')">
        <i :class="['fas fa-plane fa-fw mr-2 ', isUrl('suppliers') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('suppliers') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Suppliers</div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('units')">
        <i :class="['fas fa-puzzle-piece fa-fw mr-2 ', isUrl('units') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('units') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Units</div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('bundles')">
        <i :class="['fas fa-th  fa-fw mr-2 ', isUrl('bundles') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('bundles') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Bundles</div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('prices')">
        <i :class="['fas fa-list fa-fw mr-2 ', isUrl('prices') ? 'text-white' : 'text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('prices') ? 'text-white' : 'text-gray-500 group-hover:text-white'">Prices</div>
      </inertia-link>
    </div>
  </div>

</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl'],
  methods: {
  },
}
</script>
