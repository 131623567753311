<
<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Production - Completed</h1>
      <div>
      </div>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <div>
        <i class="fa-solid fa-calendar-days"></i>
        <v-date-picker v-model="form.date" @input="submit">
          <template v-slot="{ inputValue, inputEvents }">
            <input
                :value="inputValue"
                class="w-40 text-center bg-gray-100 cursor-pointer"
                v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <button @click="handleAdjustDate(-1)" class="btn-indigo px-3">
          <i class="fa-solid fa-minus"></i>
        </button>
        <button @click="handleAdjustDate(1)" class="btn-indigo ml-2 px-3">
          <i class="fa-solid fa-plus"></i>
        </button>
        <button class="btn-indigo ml-2" @click="handleSetToToday">Today</button>
        <button class="btn-indigo ml-2" @click="handleClearDate">Clear</button>
        <!--button class="btn-indigo ml-2" @click="incrementDate(2) ">+2 Days</button-->
      </div>
    </div>
    <!--div class="mt-10">
      ----- OR -----
    </div-->
    <div class="mt-10">
      <form @submit.prevent="submit">
        <div class="my-10">
          <!--div class="my-3">User</div>
          <span class="mt-5" v-for="user in users">
                <input type="checkbox" :id="user[1]" :value="user[0]" v-model="form.userFilters">
                <label class="mr-5" :for="user[1]">{{ user[1] }}</label>
            </span>
        </div-->
          <div class="w-96">
            <text-input v-model="form.search" class="" label="SKU / Name / User / Issue / Comment"/>
          </div>

          <div class="my-5 flex">
            <button class="btn-indigo" type="submit">Search</button>
            <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
          </div>
        </div>
      </form>
    </div>
    <!--div class="mt-10">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Tracking ID"/>
        </div>
        <div class="my-5 flex">
          <button class="btn-indigo" type="submit">Search</button>
        </div>
      </form>
    </div-->
    <ProductionsCompletedTable :productions="productions.data" :machine_times="machine_times" />

  </div>
  <!--pagination :links="labels.links"/-->
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import {adjustDate, setToToday, setToTomorrow, copyToClipboard, secondsToTime} from '@/Services/genericDataService';
import {productionTimeSeconds} from '@/Services/productionsDataService';
import Multiselect from "vue-multiselect";
import ProductionsCompletedTable from '@/Components/ProductionsCompletedTable';

export default {
  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput,
    'multiselect': Multiselect,
    ProductionsCompletedTable
  },
  props: {
    auth: Object,
    productions: Object,
    filters: Object,
    users: Object,
    machine_times: Object
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        search: this.filters.search,
        date: this.filters.date,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.get(this.route('productions.completed'), this.form, {preserveState: false})
    },
    handleAdjustDate(days) {
      if (!this.form.date) {
        this.form.date = new Date().toISOString().substring(0, 10);
      }
      this.form.date = adjustDate(this.form.date, days);
      this.submit();
    },
    handleSetToToday() {
      this.form.date = setToToday()
      this.submit();
    },
    handleClearDate() {
      this.form.date = '';
      this.submit();
    },
  },
}
</script>
