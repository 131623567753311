<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Assembly Batches</h1>
    </div>
       <CategoryToggleHeader
        :filters="filters"
        :categoryCounts="categoryCounts"
        @category-toggled="handleToggleCategory"
    />
    <!--div v-if="this.filters.status=='3'" class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU"/>
        </div>

        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
        </div>
      </form>
    </div-->
    <AssembliesViewTable :assemblies="assemblies.data" :filters="filters" :auth="auth" :links="assemblies.links"/>
    <pagination :links="assemblies.links"/>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import CategoryToggleHeader from '@/Components/CategoryToggleHeader';
import AssembliesViewTable from '@/Components/AssembliesViewTable';
import {toggleCategoryView} from '@/Services/assembliesApiService';

export default {
  metaInfo: {title: 'assemblies'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    CategoryToggleHeader,
    AssembliesViewTable
  },
  props: {
    auth: Object,
    assemblies: Object,
    users: Object,
    filters: Object,
    categoryCounts: Array
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        //  trashed: this.filters.trashed,
      },
    }
  },
  methods: {
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('assemblies.view'), this.form, {preserveState: true})
    },
    handleToggleCategory(categoryKey) {
      toggleCategoryView(categoryKey, this.filters);
    }
  },
  computed: {
    unassigned_count() {
      return this.assemblies.data.filter(assembly => assembly.user_id == null).length;
    },
    assigned_count() {
      return this.assemblies.data.filter(assembly => assembly.user_id == this.auth.user.id).length;
    }
  }
}
</script>
