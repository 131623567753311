<template>
  <div>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <!--draggable v-model="myArray" group="people" @start="drag=true" @end="drag=false"-->
        <draggable v-model="packings" @start="drag=true" @end="handleUpdateItemOrder" draggable=".item">
          <tr v-for="packing in packings" :key="packing.id"
              class="item cursor-move m-4">
            <!--td class="border-t">
             <div class="px-6 py-4 flex items-center " tabindex="-1">
               <div class="cursor-move">
                 <i class="fa fa-ellipsis-vertical text-gray-600"></i>
               </div>
             </div>
           </td-->
            <td class="border-t">
              <div class="px-6 py-4 flex items-center " tabindex="-1">
                <div @click="handleDeletePacking(packing)" class="cursor-pointer">
                  <i class="fa fa-trash text-indigo-400"></i>
                </div>
              </div>
            </td>
            <td class="border-t">
              <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
                <input type="checkbox" :id="1" v-model="packing.ship_today" @change="handleSavePacking(packing)">
              </div>
            </td>
              <td class="border-t">
              <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
                <input type="checkbox" :id="1" v-model="packing.is_priority" @change="handleSavePacking(packing)">
              </div>
            </td>
              <td class="border-t">
            <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="packing.insufficient_stock_override"
                     @change="handleSavePacking(packing)">
            </div>
          </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                {{ packing.status_name }}
              </div>
            </td>
            <!--td class="border-t"
                class="border-t" colspan="1">
              <div v-if="packing.bundle_sku == null || packing.category == ''"
                   class="pl-10 px-6 flex items-center focus:text-indigo-500">
              </div>
              <div v-else-if="packing.status == 3"
                   class="pl-10 px-6 flex items-center focus:text-indigo-500">
                <i class="fas fa-boxes-stacked text-gray-600"></i>
              </div>
              <div v-else :href="route('packings.edit', packing.id)"
                   class="pl-10 px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <i class="fas fa-box-open text-gray-600"></i>
              </div>
            </td-->


            <!--td class="border-t">
              <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
                {{ packing.bundle_pre_boxed }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
                <span v-if="packing.assembly_id">
                   <i class="fas fa-check text-gray-600"></i>
                </span>
              </div>
            </td-->
            <td class="border-t border-l w-10">
              <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
              <div class="align-middle px-2 relative px-6">
                 <span v-if="handleIsDateInPast(packing.due_date)"
                       class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
                <span v-if="packing.is_priority"
                      class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center">
                <!-- https://stackoverflow.com/questions/67866941/v-date-picker-not-calling-change-event-when-single-date-is-picked -->
                <v-date-picker v-model="packing.due_date" @input="handleSavePacking(packing)">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                        :value="inputValue"
                        class="border p-2 rounded w-32 bg-gray-100"
                        v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </div>
            </td>
            <td class="border-t">
              <div class="px-8 pl-0 flex items-center focus:text-indigo-500" tabindex="-1">
                <button @click="handleAdjustDate(packing, -1)" class="ml-2 bg-indigo-500 text-white p-2 rounded">
                  <i class="fa-solid fa-minus"></i>
                </button>
                <button @click="handleAdjustDate(packing, 1)" class="ml-2 bg-indigo-500 text-white p-2 rounded">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <select v-model="packing.user_id" class="w-32 border rounded p-2 bg-gray-100"
                        @change="handleSavePacking(packing)">
                  <!--option>{{ assembly.username }}</option>
                  <option>----</option-->
                  <option :value="null"></option>
                  <option v-for="user in users" :key=user.id :value=user.id>{{ user.username }}</option>
                </select>
              </div>
            </td>

            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <select v-model="packing.category" class="w-20 border rounded p-2 bg-gray-100"
                        @change="handleSavePacking(packing)">
                  <option v-for="category in categories" :key="category[0]" :value="category[0]">{{
                      category[1]
                    }}
                  </option>
                </select>
              </div>
            </td>
            <td class="border-t">
              <div v-if="packing.bundle_sku == null" class="px-6 flex items-center" tabindex="-1">
                <inertia-link :href="route('packings.component.create', packing.id)" :method="post"
                              class="w-full border rounded py-3 p-2 bg-gray-100"
                              tabindex="-1">
                  -- Select Product --
                </inertia-link>
              </div>
              <div v-else class="px-6 flex items-center" tabindex="-1">
                <inertia-link :href="route('packings.component.create', packing.id)" :method="post"
                              class="w-96 overflow-hidden border rounded py-3 p-2 bg-gray-100 "
                              tabindex="-1">
                  {{ packing.bundle_name }}
                </inertia-link>
              </div>
            </td>
            <td class="border-t">
              <div v-if="packing.bundle_sku == null"
                   class="px-6 flex items-center" tabindex="-1">
                <inertia-link :href="route('packings.component.create', packing.id)" :method="post"
                              class="w-72 border rounded py-3 p-2 bg-gray-100"
                              tabindex="-1">
                  -- Select Product --
                </inertia-link>
              </div>
              <div v-else
                   class="px-6 flex items-center" tabindex="-1">
                <inertia-link :href="route('packings.component.create', packing.id)" :method="post"
                              class="w-72 border rounded py-3 p-2 bg-gray-100"
                              tabindex="-1">
                  {{ packing.bundle_sku }}
                </inertia-link>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <input
                    v-model="packing.quantity_required"
                    class="w-20 border rounded p-2 bg-gray-100"
                    @input="packing.change_status='Y'"
                    v-on:keyup.enter="handleSavePacking(packing)"
                />
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <input
                    v-model="packing.quantity_to_verify"
                    class="w-20 border rounded p-2 bg-gray-100"
                    @input="packing.change_status='Y'"
                    v-on:keyup.enter="handleSavePacking(packing)"
                />
              </div>
            </td>
            <!--td class="border-t">
              <div class="px-6 flex items-center" tabindex="-1">
                <input
                    v-model="packing.comment"
                    class="w-72 border rounded p-2 bg-gray-100"
                    @input="assembly.change_status='Y'"
                    v-on:keyup.enter="savePacking(packing)"
                />
              </div>
            </td-->
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <select v-model="packing.box_id" class="w-56 border rounded p-2 bg-gray-100"
                        @change="handleSavePacking(packing)">
                  <option :value=null>None</option>
                  <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
                </select>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="packing.box_quantity"
                       @change="handleSavePacking(packing)">
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <select v-model="packing.inner_box_id" class="w-56 border rounded p-2 bg-gray-100"
                        @change="handleSavePacking(packing)">
                  <option :value=null>None</option>
                  <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
                </select>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
                <input class="w-20 border rounded p-2 bg-gray-100" v-model="packing.inner_box_quantity"
                       @change="handleSavePacking(packing)">
              </div>
            </td>
            <!--td class="border-t">
              <div class="align-middle px-2 top-2x">
                <multiselect class="cursor-pointer" v-model="packing.tags" :options="options" :multiple="true"
                             :searchable="false"
                             :close-on-select="true" :show-labels="false" @close="handleSavePacking(packing)">
                </multiselect>


              </div>
            </td-->
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ parseFloat(packing.quantity_verified / packing.quantity_to_verify * 100).toFixed(0) }}%

              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ packing.quantity_verified }}
              </div>
            </td>
            <td class="border-t">
              <a v-if="packing.bundle_id" target="_blank" class="px-6 py-4 flex items-center text-indigo-400"
                 :href="route('bundles.edit', packing.bundle_id)">
                Link
              </a>
            </td>
              <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ packing.batch_code }}
              </div>
            </td>
              <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ packing.id }}
              </div>
            </td>

          </tr>
          <tr class="text-left font-bold" slot="header">
            <th colspan="1"></th>
            <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-truck cursor-help" title="Ship Today"></i></th>
            <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-star cursor-help" title="Make Priority"></i></th>
            <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-ban cursor-help" title="Stock Block Override"></i></th>
            <th class="px-6 pt-6 pb-4">Status</th>


            <!--th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-cube"></i></th>
            <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-people-carry-box"></i></th-->
            <th class="px-6 pt-6 pb-4 border-l">Tags</th>
            <th class="px-6 pt-6 pb-4">Due Date</th>
            <th class="" colspan="1"></th>
            <th class="px-6 pt-6 pb-4">Packer</th>
            <th class="px-6 pt-6 pb-4">Category</th>
            <th class="px-6 pt-6 pb-4">Name</th>
            <th class="px-6 pt-6 pb-4">SKU</th>
            <th class="px-6 pt-6 pb-4"># Required</th>
            <th class="px-6 pt-6 pb-4"># To Verify</th>
            <!--th class="px-6 pt-6 pb-4">Comment</th-->
            <th class="px-6 pt-6 pb-4">Outer Box Type</th>
            <th class="px-6 pt-6 pb-4">Outer Box #</th>
            <th class="px-6 pt-6 pb-4">Inner Box Type</th>
            <th class="px-6 pt-6 pb-4">Inner Box #</th>


            <th class="px-6 pt-6 pb-4" colspan="1">% Verified</th>
            <th class="px-6 pt-6 pb-4" colspan="1"># Verified</th>
            <th class="px-6 pt-6 pb-4" colspan="1">Bundle URL</th>
            <th class="px-6 pt-6 pb-4" colspan="1">Batch Code</th>
             <th class="px-6 pt-6 pb-4" colspan="1">Packing ID</th>
          </tr>
        </draggable>
        <tr v-if="packings.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No packing batches found.</td>
        </tr>
      </table>
    </div>
    <!--pagination :links="packings.links"/-->
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import {updateItemOrderAdmin, deletePackingAdmin, savePackingAdmin} from '@/Services/packingsApiService';
import {adjustDate} from '@/Services/genericDataService';
import {isDateInPast} from '@/Services/genericDataService';

export default {
  props: ['packings', 'users', 'boxes', 'categories', 'options', 'filters'],
  components: {
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
  },
  data() {
    return {}
  },
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
    handleAdjustDate(packing, daysToAdd) {
      packing.due_date = adjustDate(packing.due_date, daysToAdd)
    },
    // API Calls
    handleUpdateItemOrder() {
      var items = this.packings.map(function (data, index) {
        return {data: data, order: index}
      })
      updateItemOrderAdmin(items, this.filters);
    },
    handleSavePacking(packing) {
      savePackingAdmin(packing, this.filters)
    },
    handleDeletePacking(packing) {
      if (confirm('Are you sure you want to delete this packing batch?')) {
        deletePackingAdmin(packing.id, this.filters)
      }
    },
  }
}
</script>
