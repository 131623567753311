<template>
  <div>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="border-l"><!-- Status--></th>
          <th class="px-8 pt-6 pb-4"><i class="fa-solid fa-truck cursor-help" title="Ship Today"></i></th>
          <th class="px-6 pt-6 pb-4 border-l">Tags</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Packer</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Batch Quantity</th>
          <th class="px-6 pt-6 pb-4">Outer Box Type</th>
          <th class="px-6 pt-6 pb-4">Outer Box #</th>
          <th class="px-6 pt-6 pb-4">Inner Box Type</th>
          <th class="px-6 pt-6 pb-4">Inner Box #</th>
          <th class="px-6 pt-6 pb-4">Quantity To Verify</th>
          <th class="px-6 pt-6 pb-4" colspan="1">% Verified</th>
          <th class="px-6 pt-6 pb-4" colspan="1"># Verified</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Batch Code</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Packing ID</th>
          <!--th class="px-6 pt-6 pb-4" colspan="2">Status</th-->
        </tr>
        <!--tr v-for="packing in packings.data" :key="packing.id" v-if="packing.user_id != null"-->
        <tr v-for="packing in packings" :key="packing.id">
          <td class="border-t w-" colspan="1">
            <div class="flex">
              <inertia-link v-if="packing.status==0" class="flex items-center focus:text-indigo-500 ml-4"
                            :href="route('packings.edit', packing.id)" tabindex="-1">
                <button class="btn-indigo">Start</button>
              </inertia-link>

              <a class="flex items-center focus:text-indigo-500 mx-4" :href="route('packings.printlabel', packing.id)">
                <button class="btn-indigo">Print Labels</button>
              </a>
            </div>
          </td>

          <td class="border-t" colspan="1">
            <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="packing.ship_today" @change="handleSavePacking(packing)">
            </div>
          </td>
                           <td class="border-t border-l w-10">
              <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
              <div class="align-middle px-2 relative px-6">
                <span v-if="handleIsDateInPast(packing.due_date)"
                      class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
                      <span v-if="packing.is_priority"
                      class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>

              </div>
            </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.due_date.substring(0, 10) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ packing.status_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ packing.username }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="packing.category == null"> -- No category selected --</span>
              <span v-else>{{ packing.category_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="packing.bundle_sku == null"> -- No product selected --</span>
              <span v-else>{{ packing.bundle_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="packing.bundle_sku == null"> -- No product selected --</span>
              <span v-else>{{ packing.bundle_sku }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.quantity_required }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.box_name }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.box_quantity }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.inner_box_name }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.inner_box_quantity }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.quantity_to_verify }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ parseFloat(packing.quantity_verified / packing.quantity_to_verify * 100).toFixed(0) }}%
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.quantity_verified }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.batch_code }}
            </div>
          </td>
           <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.id}}
            </div>
          </td>
        </tr>
        <tr v-if="packings.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No packing batches found</td>
        </tr>
      </table>
    </div>
    <pagination :links="links"/>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import Pagination from '@/Shared/Pagination'
import {isDateInPast} from '@/Services/genericDataService';
import {savePackingView} from '@/Services/packingsApiService';


export default {
  props: ['packings', 'links', 'filters'],
  components: {
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
    Pagination
  },
  data() {
    return {}
  },
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
    // API Calls
    handleSavePacking(packing) {
      savePackingView(packing, this.filters)
    },
  }
}
</script>
