<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Scaling Sandbox</h1>
      <!--inertia-link class="btn-indigo" :href="route('orders.create')">
        <span>Create XXX</span>
      </inertia-link-->
      <!--div class="btn-indigo mr-4" method="put" :href="route('bundles.create')">
        <span>Create</span>
        <span class="hidden md:inline">Bundle</span>
      </div-->
      <div>
        <button class="btn-indigo" type="button" @click="addScaling">Add Scaling</button>
      </div>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name"/>
        </div>
        <!--div class="mt-10">
          <input type="checkbox" id="amazon" value="y" v-model="form.amazon">
          <label class="mr-5" for="amazon">Amazon</label>
          <input type="checkbox" id="amazonfba" value="y" v-model="form.amazonfba">
          <label class="mr-5" for="amazonfba">Amazon FBA</label>
          <input type="checkbox" id="shopify" value="y" v-model="form.shopify">
          <label class="mr-5" for="shopify">Shopify</label>
          <input type="checkbox" id="etsy" value="y" v-model="form.etsy">
          <label class="mr-5" for="ebay">Etsy</label>
          <input type="checkbox" id="ebay" value="y" v-model="form.ebay">
          <label class="mr-5" for="ebay">Ebay</label>
          <input type="checkbox" id="direct" value="y" v-model="form.direct">
          <label class="mr-5" for="direct">Direct</label>
        </div>
        <div class="my-10">
          <button class="btn-indigo" type="submit">Search</button>
        </div-->
      </form>
    </div>
    <!--div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
    </div-->
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <!--th class="px-6 pt-6 pb-4">Customer</th-->
          <th class="px-6 pt-6 pb-4">Reference</th>
          <th class="px-6 pt-6 pb-4">Date</th>
          <th class="px-6 pt-6 pb-4">Created By</th>
        </tr>
        <tr v-for="scaling in scalings.data" :key="scaling.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('orders.edit', order.id)">
              {{ order.customer_name }}
              <icon v-if="order.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td-->
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('scalings.edit', scaling.id)">
              {{ scaling.name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('scalings.edit', scaling.id)">
              {{ scaling.date.substring(0, 10) }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('scalings.edit', scaling.id)">
              {{ scaling.created_by }}
            </inertia-link>
          </td>
          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('scalings.edit', scaling.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="scalings.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No wholesale scaling found.</td>
        </tr>
      </table>
    </div>
    <!--pagination :links="scalings.links"/-->
    <div class="mt-6 -mb-1 flex flex-wrap">
      <template v-for="(link, key) in scalings.links">
        <div v-if="link.url === null" :key="key" class="mr-1 mb-1 px-4 py-3 text-sm border rounded text-gray-400"
             :class="{ 'ml-auto': link.label === 'Next' }">{{ link.label }}
        </div>
        <inertia-link v-else :key="key"
                      class="mr-1 mb-1 px-4 py-3 text-sm border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
                      :class="{ 'bg-white': link.active, 'ml-auto': link.label === 'Next' }"
                      :href="link.url"
                      :data="form"
                      :preserveState="true">
          {{ link.label }}
        </inertia-link>
      </template>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'orders'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    scalings: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search
      },
    }
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        // this.$inertia.replace(this.route('templates', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },

  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    addScaling() {
      this.$inertia.post(this.route('scalings.create'),
          {
            // Create entry with sandbox ID
            customer_id: 1
          }
      )
    },
  },
}
</script>
