<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <!--inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('labels')">Currencies</inertia-link-->
      <!--span class="text-indigo-400 font-medium"></span--> DGN Generator
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl pb-5">
      <!--form @submit.prevent="submit"-->
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <form method="post" enctype="multipart/form-data">
          <!--label for="files">Select files:</label-->
          <div class="mb-6">
            Select FBA packlist.
          </div>
          <div class="mt-2">
            <!--select-input v-model="form.region" name="region" class="pr-6 pb-8 w-full lg:w-1/2"
                          label="">
              <option key="uk" value='uk'>UK Shipment</option>
              <option key="de" value='de'>DE Shipment</option>
              <option key="fr" value='fr'>FR Shipment</option>
            </select-input-->

            <!--input type="file" id="files" accept=".csv" name="files"-->
            <input type="file" accept=".csv" id="csv_files" name="csv_files" multiple>
            <div class="mt-10">
              <loading-button :loading="sending" class="btn-indigo" type="submit">Convert</loading-button>
            </div>
          </div>
        </form>
      </div>
      <!--form method="post">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.header" :error="errors.header" name='header' class="pr-6 pb-8 w-full" label="Header"/>
          <text-input v-model="form.subheader" :error="errors.subheader" name='subheader'  class="pr-6 pb-8 w-full" label="Subheader"/>
        </div>
        <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex items-center">
          <inertia-link class="btn-indigo" :href="route('labels.component.create')">
            <span>Add Header</span>
          </inertia-link>
          <button type="submit" class="btn-indigo ml-4">
                  Generate Label
              </button>
        </div>
      </form-->
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Currency'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    //data: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        region: 'uk',
      },
    }
  },
  /*
  methods: {
    submit() {
      this.$inertia.post(this.route('labels.create'), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
  },
   */
}
</script>
