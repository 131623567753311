<
<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">FBA Assembly Archive</h1>

    </div>
    <div class="mt-10 flex justify-between items-center">
      <div>
        <div class = "mb-5">
          Due Date:
        </div>
        <i class="fa-solid fa-calendar-days"></i>
        <v-date-picker v-model="form.date" @input="submit">
          <template v-slot="{ inputValue, inputEvents }">
            <input
                :value="inputValue"
                class="w-40 text-center bg-gray-100 cursor-pointer"
                v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <button @click="handleAdjustDate(-1)" class="btn-indigo px-3">
          <i class="fa-solid fa-minus"></i>
        </button>
        <button @click="handleAdjustDate(1)" class="btn-indigo ml-2 px-3">
          <i class="fa-solid fa-plus"></i>
        </button>
        <button class="btn-indigo ml-2" @click="handleSetToToday">Today</button>
        <!--button class="btn-indigo ml-2" @click="handleSetToTomorrow">Tomorrow</button-->
        <!--button class="btn-indigo ml-2" @click="incrementDate(2) ">+2 Days</button-->
      </div>
    </div>
    <div class="mt-10">
      ----- OR -----
    </div>
    <div class="mt-10">
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Batch Code / SKU / Name / UPS Tracking ID / FBA Tracking ID"/>
        </div>
        <div class="my-5 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <AssembliesArchiveTable :assemblies="assemblies.data" :filters="filters" :links="assemblies.links"/>
  </div>
  <!--pagination :links="labels.links"/-->
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter
import AssembliesArchiveTable from '@/Components/AssembliesArchiveTable';
import {adjustDate, setToToday, setToTomorrow, copyToClipboard} from '@/Services/genericDataService';
import {retrieveLabelPDF} from '@/Services/genericApiService';

export default {
  metaInfo: {title: 'packings'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput,
    AssembliesArchiveTable
  },
  props: {
    auth: Object,
    assemblies: Object,
    filters: Object,
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        search: this.filters.search,
        date: this.filters.date,
      },
    }
  },
  methods: {
    submit() {
      this.$inertia.get(this.route('assemblies.archive'), this.form, {preserveState: false})
    },
    handleAdjustDate(days) {
      this.form.date = adjustDate(this.form.date, days);
      this.submit();
    },
    handleSetToToday() {
      this.form.date = setToToday()
      this.submit();
    },
    handleSetToTomorrow() {
      this.form.date = setToTomorrow()
      this.submit();
    },
    handleCopyToClipboard(text) {
      copyToClipboard(text)
    },
  },
}
</script>
