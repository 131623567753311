<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Bundles</h1>
      <div>
        <!-- original button that requires first few bits filled out -->
        <!--inertia-link class="btn-indigo mr-4" :href="route('bundles.create')">
          <span>Create</span>
          <span class="hidden md:inline">Bundle</span>
        </inertia-link-->
         <button class="btn-indigo mr-8" @click="getBarcodes">
          <span>Get Barcodes</span>
        </button>
        <button class="btn-indigo mr-8" @click="getLWItemMeta">
          <span>LW Sync</span>
        </button>
        <inertia-link class="btn-indigo mr-4" method="post" :href="route('bundles.create')">
          <span>Create</span>
          <span class="hidden md:inline">Bundle</span>
        </inertia-link>
        <a class="btn-indigo ml-4" :href="'/bundles/?get_csv=y'">
          Export to CSV
        </a>
      </div>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <!--form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU"/>
        </div>
      </form-->
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU"/>
        </div>
        <div class="my-10">
            <span v-for="category in categories">
                <input type="checkbox" :id="category[1]" :value="category[0]" v-model="form.categoryFilters">
                <label class="mr-5" :for="category[1]">{{ category[1] }}</label>
            </span>
        </div>
        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <!--div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
    </div-->
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4 text-left" colspan="1"></th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <th class="px-6 pt-6 pb-4">LW Barcode</th>
          <th class="px-6 pt-6 pb-4">FBA Barcode</th>
          <th class="px-6 pt-6 pb-4">Components + Freight (Ex VAT)</th>
          <th class="px-6 pt-6 pb-4">Density</th>
          <th class="px-6 pt-6 pb-4">Bundle ID</th>
        </tr>
        <tr v-for="bundle in bundles.data" :key="bundle.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t border-l">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="duplicateBundle(bundle)" class="cursor-pointer">
                <i class="far fa-copy text-gray-600"></i>
              </div>
            </div>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('bundles.edit', bundle.id)">
              {{ bundle.name }}
              <icon v-if="bundle.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2"/>
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              {{ bundle.category_name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              {{ bundle.sku }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              {{ bundle.hazard_name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              {{bundle.nikura_barcode}}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              {{bundle.amazon_barcode}}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              £{{ parseFloat(bundle.goods_cost).toFixed(3) }}
            </inertia-link>
          </td>
           <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              £{{ parseFloat(bundle.density).toFixed(3) }}
            </inertia-link>
          </td>
             <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              {{  bundle.id }}
            </inertia-link>
          </td>
          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('bundles.edit', bundle.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="bundles.data.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No bundles found.</td>
        </tr>
      </table>
    </div>
    <!--pagination :links="bundles.links"/-->
     <div class="mt-6 -mb-1 flex flex-wrap">
      <template v-for="(link, key) in bundles.links">
        <div v-if="link.url === null" :key="key" class="mr-1 mb-1 px-4 py-3 text-sm border rounded text-gray-400"
             :class="{ 'ml-auto': link.label === 'Next' }">{{ link.label }}
        </div>
        <inertia-link v-else :key="key"
                      class="mr-1 mb-1 px-4 py-3 text-sm border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
                      :class="{ 'bg-white': link.active, 'ml-auto': link.label === 'Next' }"
                      :href="link.url"
                      :data="form"
                      :preserveState="true">
          {{ link.label }}
        </inertia-link>
      </template>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'bundles'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    bundles: Object,
    //filters: Object,
    categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        //search: this.filters.search,
        //categoryFilters: [],
        search: '',
        categoryFilters: [],
      },
    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        // this.$inertia.replace(this.route('templates', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
   */
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('bundles'), this.form, {preserveState: true})
    },
    duplicateBundle(bundle) {
      if (confirm('Are you sure you want to duplicate this bundle?')) {
        this.$inertia.post(this.route('bundles.duplicate'),
            {
              id: bundle.id,
            },
            {
              // Options (i.e. preserve scroll
            })
      }
    },
     getLWItemMeta() {
      if (confirm('Are you sure you want to refresh sync meta for all bundles? This may take a few minutes. ' +
          'Note that you may see a timeout error message after 30s. This will not impact the sync and can be ignored.')) {
        this.$inertia.put(this.route('bundles'),
            {
              getLWItemMeta: true,
            },
            {
              preserveScroll: true
            })
      }
    },
     getBarcodes() {
      if (confirm('Are you sure you want to pull in the latest barcodes? ' +
          'Note that you may see a timeout error message after 30s. This will not impact the sync and can be ignored.')) {
        this.$inertia.put(this.route('bundles'),
            {
              getBarcodes: true,
            },
            {
              preserveScroll: true
            })
      }
    },
  },
}
</script>
