<template>
  <div>
    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Due Date</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Assembled</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Cobalted</th>
           <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Assembler</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Batch Quantity</th>
          <!--th class="px-6 pt-6 pb-4">Outer Box Type</th>
          <th class="px-6 pt-6 pb-4">Outer Box #</th>
          <th class="px-6 pt-6 pb-4">Inner Box Type</th>
          <th class="px-6 pt-6 pb-4">Inner Box #</th>
          <th class="px-6 pt-6 pb-4">Quantity To Verify</th>
          <th class="px-6 pt-6 pb-4" colspan="1">% Verified</th>
          <th class="px-6 pt-6 pb-4" colspan="1"># Verified</th-->
          <th class="px-6 pt-6 pb-4" colspan="1">Batch Code</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Assembly ID</th>
           <th class="px-6 pt-6 pb-4" colspan="1">Packing ID</th>



          <!--th class="px-6 pt-6 pb-4" colspan="2">Status</th-->

        </tr>
        <!--tr v-for="packing in packings.data" :key="packing.id" v-if="packing.user_id != null"-->
        <tr v-for="assembly in assemblies" :key="assembly.id"
            :class="{'bg-gray-300': assembly.status == 3}">
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.due_date.substring(0, 10) }}
            </div>
          </td>
             <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if="assembly.assembled_timestamp != null && assembly.assembled_timestamp != '' ">
                  {{ assembly.assembled_timestamp.substring(0, 10) }}
                </span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if="assembly.audited_timestamp != null && assembly.audited_timestamp != '' ">
                  {{ assembly.audited_timestamp.substring(0, 10) }}
                </span>
            </div>
          </td>
             <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ assembly.category_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ assembly.status_name }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ assembly.username }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="assembly.category == null"> -- No category selected --</span>
              <span v-else>{{ assembly.category_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="assembly.bundle_sku == null"> -- No product selected --</span>
              <span v-else>{{ assembly.bundle_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="assembly.bundle_sku == null"> -- No product selected --</span>
              <span v-else>{{ assembly.bundle_sku }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ assembly.quantity_required }}
              </div>
            </div>
          </td>
          <!--td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.box_name }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.box_quantity }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.inner_box_name }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.inner_box_quantity }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.quantity_to_verify }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ parseFloat(packing.quantity_verified / packing.quantity_to_verify * 100).toFixed(0) }}%
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ packing.quantity_verified }}
            </div>
          </td-->
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.batch_code }}
            </div>
          </td>
           <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.id}}
            </div>
          </td>
           <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.packing_id}}
            </div>
          </td>
        </tr>
        <tr v-if="assemblies.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No assembly batches found</td>
        </tr>
      </table>
    </div>
    <pagination :links="links"/>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import Pagination from '@/Shared/Pagination'
import {isDateInPast} from '@/Services/genericDataService';

export default {
  props: ['assemblies', 'links', 'filters'],
  components: {
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
    Pagination
  },
  data() {
    return {}
  },
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
  }
}
</script>
