<template>
  <div class="mt-12 bg-white rounded shadow overflow-x-auto">
    <table class="w-full whitespace-no-wrap">
      <!--draggable v-model="myArray" group="people" @start="drag=true" @end="drag=false"-->
      <draggable v-model="assemblies" @start="drag=true" @end="handleUpdateItemOrder" draggable=".item">
        <tr v-for="assembly in assemblies" :key="assembly.id"
            :class="assembly.hidden == 'Y' ? 'item hidden' : assembly.change_status == 'Y' ? 'item bg-indigo-100 cursor-move m-4' : 'item cursor-move m-4'">
          <!--td class="border-t">
           <div class="px-6 py-4 flex items-center " tabindex="-1">
             <div class="cursor-move">
               <i class="fa fa-ellipsis-vertical text-gray-600"></i>
             </div>
           </div>
         </td-->
          <td class="border-t">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="handleDeleteAssembly(assembly)" class="cursor-pointer">
                <i class="fa fa-trash text-indigo-400"></i>
              </div>
            </div>
          </td>
          <!--td class="border-t" colspan="1">
            <div v-if="assembly.bundle_sku == null || assembly.category == ''"
                 class="pl-10 px-6 flex items-center focus:text-indigo-500">
            </div>
            <div v-else-if="assembly.status == 3" class="pl-10 px-6 flex items-center focus:text-indigo-500">
              <i class="fas fa-check text-gray-600"></i>
            </div>
            <div v-else-if="assembly.status == 2" :href="route('assemblies.complete', assembly.id)"
                 class="pl-10 px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <i class="fas fa-barcode text-gray-600"></i>
            </div>
            <div v-else :href="route('assemblies.edit', assembly.id)"
                 class="pl-10 px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <i class="fas fa-play text-gray-600"></i>
            </div>
          </td-->
          <!--td class="border-t  border-l">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.order + 1 }}
            </div>
          </td-->
          <td class="border-t">
            <div v-if="assembly.category.indexOf('af') === 0 || assembly.category=='ae'"
                 class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="assembly.ship_today" @change="handleSaveAssembly(assembly)">
            </div>
            <div v-else class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
            </div>
          </td>
          <td class="border-t">
            <div v-if="assembly.category.indexOf('af') === 0 || assembly.category=='ae'"
                 class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="assembly.push_to_packing" @change="handleSaveAssembly(assembly)">
            </div>
            <div v-else class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
            </div>
          </td>
           <td class="border-t">
            <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="assembly.is_priority"
                     @change="handleSaveAssembly(assembly)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-8 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="assembly.insufficient_stock_override"
                     @change="handleSaveAssembly(assembly)">
            </div>
          </td>
          <td class="border-t border-l w-10">
            <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
            <div class="align-middle px-2 relative top-4 px-6">
                  <span v-if="handleIsDateInPast(assembly.due_date)"
                      class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
            <span v-if="assembly.is_priority"
                  class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>
            </div>
          </td>


          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ assembly.status_name }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 flex items-center">
              <!-- https://stackoverflow.com/questions/67866941/v-date-picker-not-calling-change-event-when-single-date-is-picked -->
              <v-date-picker v-model="assembly.due_date" @input="handleSaveAssembly(assembly)">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      :value="inputValue"
                      class="border p-2 rounded w-32 bg-gray-100"
                      v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <select v-model="assembly.user_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSaveAssembly(assembly)">
                <option :value="null"></option>
                <option v-for="user in users" :key=user.id :value=user.id>{{ user.username }}</option>
              </select>
            </div>
          </td>


          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <select v-model="assembly.category" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSaveAssembly(assembly)">
                <option v-for="category in categories" :key="category[0]" :value="category[0]">{{
                    category[1]
                  }}
                </option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div v-if="assembly.bundle_sku == null" class="px-6 flex items-center" tabindex="-1">
              <inertia-link :href="route('assemblies.component.create', assembly.id)" :method="post"
                            class="w-full border rounded py-3 p-2 bg-gray-100"
                            tabindex="-1">
                -- Select Product --
              </inertia-link>
            </div>
            <div v-else class="px-6 flex items-center" tabindex="-1">
              <inertia-link :href="route('assemblies.component.create', assembly.id)" :method="post"
                            class="w-96 overflow-hidden border rounded py-3 p-2 bg-gray-100"
                            tabindex="-1">
                {{ assembly.bundle_name }}
              </inertia-link>
            </div>
          </td>
          <td class="border-t">
            <div v-if="assembly.bundle_sku == null" class="px-6 flex items-center" tabindex="-1">
              <inertia-link :href="route('assemblies.component.create', assembly.id)" :method="post"
                            class="w-72 border rounded py-3 p-2 bg-gray-100"
                            tabindex="-1">
                -- Select Product --
              </inertia-link>
            </div>
            <div v-else class="px-6 flex items-center" tabindex="-1">
              <inertia-link :href="route('assemblies.component.create', assembly.id)" :method="post"
                            class="w-72 border rounded py-3 p-2 bg-gray-100"
                            tabindex="-1">
                {{ assembly.bundle_sku }}
              </inertia-link>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <input
                  v-model="assembly.quantity_required"
                  class="w-20 border rounded p-2 bg-gray-100"
                  @input="assembly.change_status='Y'"
                  v-on:keyup.enter="handleSaveAssembly(assembly)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <input
                  v-model="assembly.quantity_to_verify"
                  class="w-20 border rounded p-2 bg-gray-100"
                  @input="assembly.change_status='Y'"
                  v-on:keyup.enter="handleSaveAssembly(assembly)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <select v-model="assembly.box_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSaveAssembly(assembly)">
                <option :value=null>None</option>
                <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input class="w-20 border rounded p-2 bg-gray-100" v-model="assembly.box_quantity"
                     @change="handleSaveAssembly(assembly)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <select v-model="assembly.inner_box_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSaveAssembly(assembly)">
                <option :value=null>None</option>
                <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input class="w-20 border rounded p-2 bg-gray-100" v-model="assembly.inner_box_quantity"
                     @change="handleSaveAssembly(assembly)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <input
                  v-model="assembly.comment"
                  class="w-72 border rounded p-2 bg-gray-100"
                  @input="assembly.change_status='Y'"
                  v-on:keyup.enter="handleSaveAssembly(assembly)"
              />
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ parseFloat(assembly.quantity_verified / assembly.quantity_to_verify * 100).toFixed(0) }}%
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.quantity_verified }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.created.substring(0, 10) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.batch_code }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
              {{ assembly.id }}
            </div>
          </td>
        </tr>
        <tr class="text-left font-bold" slot="header">
          <th class="border-l" colspan="1"></th>
          <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-truck cursor-help" title="Ship Today"></i></th>
          <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-box cursor-help" title="Push To Packing"></i></th>
          <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-star cursor-help" title="Make Priority"></i>
          <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-ban cursor-help" title="Stock Block Override"></i></th>
          <th class="px-6 pt-6 pb-4 border-l">Tags</th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>
          <th class="px-6 pt-6 pb-4">Assembler</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">SKU</th>
          <th class="px-6 pt-6 pb-4">Quantity Required</th>
          <th class="px-6 pt-6 pb-4">Quantity To Scan</th>
          <th class="px-6 pt-6 pb-4">Outer Box Type</th>
          <th class="px-6 pt-6 pb-4">Outer Box #</th>
          <th class="px-6 pt-6 pb-4">Inner Box Type</th>
          <th class="px-6 pt-6 pb-4">Inner Box #</th>
          <th class="px-6 pt-6 pb-4">Comment</th>
          <th class="px-6 pt-6 pb-4" colspan="1">% Verified</th>
          <th class="px-6 pt-6 pb-4" colspan="1"># Verified</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Date Created</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Batch Code</th>
          <th class="px-6 pt-6 pb-4" colspan="1">Assembly ID</th>

          <!--th class="px-6 pt-6 pb-4">Date Started</th-->
          <!--th class="px-6 pt-6 pb-4" colspan="2">Status</th-->

        </tr>

      </draggable>
    </table>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import draggable from 'vuedraggable'

import {isDateInPast} from '@/Services/genericDataService';
import {updateItemOrderAdmin, deleteAssemblyAdmin, saveAssemblyAdmin} from '@/Services/assembliesApiService';

export default {
  props: ['assemblies', 'users', 'boxes', 'categories', 'options', 'filters'],
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    draggable,
  },
  data() {
    return {
      items: '',
      user_id: this.auth.user.id,
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
        categoryFilters: this.filters.categoryFilters,
        userFilters: this.filters.userFilters,
        dateFilters: this.filters.dateFilters
      },
    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
  */
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
    // API Calls
    handleUpdateItemOrder() {
      var items = this.assemblies.map(function (data, index) {
        return {data: data, order: index}
      })
      updateItemOrderAdmin(items, this.filters);
    },
    handleSaveAssembly(assembly) {
       console.log('testee')
      saveAssemblyAdmin(assembly, this.filters)
    },
    handleDeleteAssembly(assembly) {
      if (confirm('Are you sure you want to delete this assembly?')) {
       deleteAssemblyAdmin(assembly.id, this.filters)
      }
    },
    /*
    updateItemOrder: function () {
      var items = this.assemblies.map(function (data, index) {
        return {data: data, order: index}
      })

      this.$inertia.put(this.route('assemblies.admin.reorder'),
          {
            user_filters: this.filters.userFilters,
            category_filters: this.filters.categoryFilters,
            filters: {
              category: this.filters.category,
            },
            items: items,
          },
          {
            preserveScroll: true,
            //preserveState: false
          })
    },
    saveAssembly(assembly) {
      // If channel is Shopify Or Amazon, check for LW SKU (i.e. if there is a place to push SKU to
      // If no LW SKU, ask to sync, otherwise ask to save/push
      // If channel is anything else, just ask to save
      this.$inertia.put(this.route('assemblies.admin.update'),
          {
            userFilters: this.filters.userFilters,
            category_filters: this.filters.categoryFilters,
            filters: {
              category: this.filters.category,
            },
            assembly:
                {
                  id: assembly.id,
                  //user: assembly.user,
                  user_id: assembly.user_id,
                  quantity_required: assembly.quantity_required,
                  quantity_to_verify: assembly.quantity_to_verify,
                  due_date: assembly.due_date,
                  category: assembly.category,
                  push_to_packing: assembly.push_to_packing,
                  ship_today: assembly.ship_today,
                  comment: assembly.comment,
                  box_id: assembly.box_id,
                  box_quantity: assembly.box_quantity,
                  inner_box_id: assembly.inner_box_id,
                  inner_box_quantity: assembly.inner_box_quantity,
                  insufficient_stock_override: assembly.insufficient_stock_override,
                  is_priority: assembly.is_priority,

                }
          },
          {
            preserveScroll: true,
            //preserveState: false
          })

    },
     */
  },
}
</script>
