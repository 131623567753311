<template>
  <div>
    <div class="mb-4">
      <div class="flex items-center group py-3">
        <div class="text-gray-500">INTERNATIONAL TOOLS</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('FBA.avask')">
        <i :class="['fas fa-earth-europe mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('international/avask') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Avask
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('FBA.dgngenerator')">
        <i :class="['fas fa-radiation mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('international/dgngenerator') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          DGN Generator
        </div>
      </inertia-link>
    </div>
  </div>

</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl'],
  methods: {
  },
}
</script>
