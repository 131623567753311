<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <!--inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('labels')">Currencies</inertia-link-->
      <!--span class="text-indigo-400 font-medium"></span--> EU Hazardous Descrambler
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl pb-5">
      <!--form @submit.prevent="submit"-->
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <form method="post" enctype="multipart/form-data">
          <!--label for="files">Select files:</label-->
          <!--div class="mb-6">
            Please select category and FBA packlist(s). Hold shift to select multiple files.
          </div-->
          <div class="mt-2">
            <div class="mt-6">
              <label for="amazon_csvs">Select Amazon packlist CSV(s):</label>
              <div class="mt-5">
                <input type="file" accept=".csv" id="amazon_csvs" name="amazon_csvs" multiple>
              </div>
            </div>
            <div class="mt-12">
              <!--label for="amazon_pdf">Select Amazon pdf:</label-->
              <!--input type="file" accept=".pdf" id="amazon_pdfs" name="amazon_pdf" multiple-->
              <label for="amazon_pdfs">Select Amazon pdf(s):</label>
              <div class="mt-5">
              <input type="file" accept=".pdf" id="amazon_pdfs" name="amazon_pdfs" multiple>
                </div>
            </div>
            <div class="mt-12">
              <loading-button :loading="sending" class="btn-indigo" type="submit">Upload</loading-button>
            </div>
          </div>
          <div></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Currency'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    //data: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        region: 'uk',
      },
    }
  },
  /*
  methods: {
    submit() {
      this.$inertia.post(this.route('labels.create'), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
  },
   */
}
</script>
