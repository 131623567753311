<template>
  <div class="grid">
    <div class="pr-10">
      <div class="flex justify-between items-center">
        <h1 class="font-bold text-3xl">{{ view_type.charAt(0).toUpperCase() + view_type.slice(1) }} Machines</h1>
        <div>
          <!--button class="btn-indigo mr-4 cursor-pointer" @click="saveProductions">Save All</button-->
          <button class="btn-indigo mr-4 cursor-pointer" @click="archiveAllProductions">Archive All</button>
          <!--button class="btn-indigo mr-4 cursor-pointer" @click="createBatch">Add Batch...</button-->
          <button class="btn-indigo mr-4 cursor-pointer" @click="handleUploadProductions"><span>Bulk Upload</span>
          </button>
        </div>
      </div>
      <div v-for="day in days">
        <div
            :class="productionsAll[day].colour + ' rounded text-white p-5 mt-10 flex items-center justify-between text-xl'">
          <div>
            {{ productionsAll[day].title }}
          </div>
          <div class="flex space-x-2">
            <button class="btn-indigo cursor-pointer" @click="createBatch(day)">Add Batch...</button>
            <button class="bg-gray-300 text-black btn-indigo cursor-pointer" @click="archiveProductionsForDay(day)">
              Archive
            </button>
          </div>
        </div>
        <div v-for="(productions, list) in productionsAll[day].lists"
             class="grid grid-flow-col auto-cols-max gap-10">
          <ProductionsAdminTable
              :view_type=view_type
              :productions="productions"
              :machine_times="machine_times"
              :auth="auth"
              :users="users"
              :auditors="auditors"
              :categories="categories"
              :machine_types="machine_types"
              :label_status_choices="label_status_choices"
              @update-item-order="updateItemOrder"
              @update-time="updateTime"
              :machineNumber="`${list}`"
              :listName="`${day}-${list}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import ProductionsAdminTable from '@/Components/ProductionsAdminTable'

export default {
  metaInfo: {title: 'productions'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    draggable,
    //'vue-multiselect': window.VueMultiselect.default
    'multiselect': Multiselect,
    ProductionsAdminTable

  },
  props: {
    auth: Object,
    productions: Object,
    users: Object,
    auditors: Object,
    filters: Object,
    categories: Object,
    label_status_choices: Object,
    machine_types: Object,
    view_type: Object,
    machine_times: Object
  },
  data() {
    return {
      days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
      productionsAll: this.initializeProductionsAll()
    }
  },
  methods: {
    initializeProductionsAll() {
      const days = {
        'mon': {'title': 'Monday', 'colour': 'bg-blue-400'},
        'tue': {'title': 'Tuesday', 'colour': 'bg-indigo-400'},
        'wed': {'title': 'Wednesday', 'colour': 'bg-green-400'},
        'thu': {'title': 'Thursday', 'colour': 'bg-orange-400'},
        'fri': {'title': 'Friday', 'colour': 'bg-pink-400'},
        'sat': {'title': 'Saturday', 'colour': 'bg-gray-500'}
      };
      Object.keys(days).forEach(day => {
        days[day].lists = {
          '1': this.productions.data.filter(p => p.list === `${day}-1`),
          '2': this.productions.data.filter(p => p.list === `${day}-2`),
          '3': this.productions.data.filter(p => p.list === `${day}-3`)
          //'1': this.productions.data.filter(p => p.list.includes(`1-${day}`)),
          //'2': this.productions.data.filter(p => p.list.includes(`2-${day}`))
        };
      });
      return days;
    },
    updateTime(newTime) {
      this.timer = newTime;
    },
    createBatch(day) {
      //if (confirm('Are you sure you want to create a batch?')) {
      this.$inertia.post(this.route('productions.admin.create', this.view_type),
          // 2/12/24. Data/request body should include any object properties to set
          // Could set machine_type here as well, but done on view side
           {
              list: day + '-1'
           },
          {
            preserveState: false,
            preserveScroll: true
          })
      //}
    },
    updateItemOrder: function (event) {
      // Get DOM element of new list, then retrieve list name from here
      const newListElement = event.to;
      const newListName = newListElement.getAttribute('data-list-name');


      let items = [];
      const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      const lists = ['1', '2', '3'];
      let bulkItems = [];

      // 1a + 1b are optional for UI purposes (i.e. move list before making put request

      // 1a Collect and remove bulkSelect items
      days.forEach(day => {
        lists.forEach(list => {
          this.productionsAll[day]['lists'][list] = this.productionsAll[day]['lists'][list].filter(item => {
            if (item.bulkSelect) {
              bulkItems.push(item);
              return false;
            }
            return true;
          });
        });
      });

      // 1b Add bulkSelect items to the new list
      const [newDay, newList] = newListName.split('-');
      this.productionsAll[newDay]['lists'][newList] = [...this.productionsAll[newDay]['lists'][newList], ...bulkItems];

      // 2 Update list attribute before sending
      days.forEach(day => {
        lists.forEach(list => {
          let dayListItems = this.productionsAll[day]['lists'][list].map((item, index) => {
            if (item.bulkSelect) {
              return {
                item: item,
                order: index,
                //list: draggedItem.list
                list: newListName
              };
            } else {
              return {
                item: item,
                order: index,
                list: `${day}-${list}`
              };
            }
          });
          items.push(...dayListItems);
        });
      });


      this.$inertia.put(this.route('productions.admin.reorder', this.view_type), {
        data: items
      }, {
        preserveState: false,
        preserveScroll: true,
      });
    },
    /*
    saveProductions() {
      // If channel is Shopify Or Amazon, check for LW SKU (i.e. if there is a place to push SKU to
      // If no LW SKU, ask to sync, otherwise ask to save/push
      // If channel is anything else, just ask to save
      this.$inertia.put(this.route('productions.admin', this.view_type),
          {
            userFilters: this.filters.userFilters,
            category_filters: this.filters.categoryFilters,
            productions: this.productions.data,
          },
          {
            preserveScroll: true,
            preserveState: false
          })
    },
     */
    archiveAllProductions() {
      // Select the finished prod batches (i.e. ones where status is 3)
      const archivedProductions = this.productions.data.filter(production => production.status == 3);

      if (archivedProductions.length === 0) {
        alert("There are no completed batches to archive.");
        return;
      }
      if (confirm(`Are you sure you want to archive all completed batches?`)) {
        this.$inertia.put(this.route('productions.admin.archive-all', this.view_type), {
          productions: archivedProductions  // Only send productions where status == 4
        }, {
          preserveScroll: true,
          preserveState: false
        });
      }
    },
    archiveProductionsForDay(day) {
      // Filter completed productions for the specified day
      const archivedProductions = this.productions.data.filter(production =>
          production.status == 3 && production.list.includes(day)
      );

      if (archivedProductions.length === 0) {
        alert("There are no completed batches for this day to archive.");
        return;
      }

      if (confirm(`Are you sure you want to archive all completed batches for ${this.productionsAll[day].title}?`)) {
        this.$inertia.put(this.route('productions.admin.archive-day', this.view_type),
            {
              productions: archivedProductions,
            },
            {
              preserveScroll: true,
              preserveState: false
            });
      }
    },
    handleUploadProductions() {
      this.$inertia.get(this.route('productions.upload'))
    },

  },
}
</script>
