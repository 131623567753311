<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">FBA Packing Admin</h1>
      <div class="btn-indigo mr-4 cursor-pointer" @click="handleCreatePacking">
        <span>Add Batch...</span>
      </div>
    </div>
    <CategoryToggleHeader
        :filters="filters"
        :categoryCounts="categoryCounts"
        @category-toggled="handleToggleCategory"
    />
    <!--div class="my-10 mt-20 flex">
      <div
          v-for="(categoryData, categoryKey) in categoryButtons"
          :key="categoryKey"
          :class="`${categoryData.colour} flex-1 text-white py-4 text-center cursor-pointer min-w-[200px]`"
          @click="handleToggleCategory(categoryKey)"
      >
        <span :class="[
              'text-2xl font-bold',
              filters.category === categoryKey ? 'underline' : ''
            ]">
           {{ categoryData.title }} - {{ categoryCounts[categoryKey] || 0 }} Batches
        </span>
      </div>
    </div-->
    <PackingsAdminTable :packings="packings.data" :users="users" :boxes="boxes" :categories="categories" :options="options" :filters="filters"/>
    <!--pagination :links="packings.links"/-->
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";
import PackingsAdminTable from '@/Components/PackingsAdminTable';
import CategoryToggleHeader from '@/Components/CategoryToggleHeader';
import {toggleCategoryAdmin, createPackingAdmin} from '@/Services/packingsApiService';

export default {
  metaInfo: {title: 'packings'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    draggable,
    'multiselect': Multiselect,
    PackingsAdminTable,
    CategoryToggleHeader
  },
  props: {
    auth: Object,
    packings: Object,
    users: Object,
    filters: Object,
    categories: Object,
    categoryCounts: Object,
    boxes: Object
  },
  data() {
    return {
      categoryButtons: {
        'af-uk-pr': {'title': 'MFN', 'colour': 'bg-red-400'},
        'af-uk': {'title': 'UK', 'colour': 'bg-blue-400'},
        'af-eu-de': {'title': 'Germany', 'colour': 'bg-indigo-400'},
        'af-eu-fr': {'title': 'France', 'colour': 'bg-green-400'},
        'af-eu-es': {'title': 'Spain', 'colour': 'bg-orange-400'},
        'af-eu-it': {'title': 'Italy', 'colour': 'bg-pink-400'}
      },
      currentVisibleCategory: 'af-uk',
      form: {
        search: this.filters.search,
        category: this.filters.category
      },
      options: [
        'Priority',
        'Waiting on Prod',
      ],
    }
  },
  methods: {
    handleCreatePacking() {
      createPackingAdmin(this.filters);
    },
    handleToggleCategory(categoryKey) {
     toggleCategoryAdmin(categoryKey, this.filters);
    }
  },
}
</script>
