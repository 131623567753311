<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <!--inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('labels')">Currencies</inertia-link-->
      <!--span class="text-indigo-400 font-medium"></span--> Label Generator
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <!--form @submit.prevent="submit"-->
      <form method="post">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.header" :error="errors.header" name='header' class="pr-6 pb-8 w-full" label="Header"/>
          <text-input v-model="form.subheader" :error="errors.subheader" name='subheader'  class="pr-6 pb-8 w-full" label="Subheader"/>
        </div>
        <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex items-center">
          <inertia-link class="btn-indigo" :href="route('custom-labels.component.create')">
            <span>Add Header</span>
          </inertia-link>

              <!--a :href="'/assemblies/printlabel/1'"-->
                <!--a class="btn-indigo ml-4" :href="route('labels.create')" :data="{x:'y'}">
                  Generate Label
              </a-->
          <button type="submit" class="btn-indigo ml-4">
                  Generate Label
              </button>

          <!--loading-button :loading="sending" class="btn-indigo ml-4" type="submit">Generate Label</loading-button-->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Currency'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    data: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        header: this.data.header,
        subheader: null,
      },
    }
  },
  /*
  methods: {
    submit() {
      this.$inertia.post(this.route('labels.create'), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
  },
   */
}
</script>
