<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">FBA Shipment Planner</h1>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <form @submit.prevent="submit">
        <!--div class="my-10 flex">

          <button class="btn-indigo" type="submit">Today</button>
          <button class="btn-indigo ml-2" type="submit">Tomorrow</button>
          <button class="btn-indigo ml-2" type="submit">01/11/2023</button>
        </div-->
        <i class="fa-solid fa-calendar-days"></i>
        <v-date-picker v-model="form.date" @input="submit">
          <template v-slot="{ inputValue, inputEvents }">
            <input
                :value="inputValue"
                class="w-40 text-center bg-gray-100 cursor-pointer"
                v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <button @click="handleAdjustDate(-1)" class="btn-indigo px-3">
          <i class="fa-solid fa-minus"></i>
        </button>
        <button @click="handleAdjustDate(1)" class="btn-indigo ml-2 px-3">
          <i class="fa-solid fa-plus"></i>
        </button>
        <button class="btn-indigo ml-2" @click="handleSetToToday">Today</button>
        <button class="btn-indigo ml-2" @click="handleSetToTomorrow">Tomorrow</button>
        <!--button class="btn-indigo ml-2" @click="incrementDate(2) ">+2 Days</button-->
      </form>
    </div>
    <div class="mt-10 flex">
      <div
          v-for="(categoryData, categoryKey) in categories"
          :key="categoryKey"
          :class="`${categoryData.colour} flex-1 text-white py-4 text-center cursor-pointer min-w-[200px]`"
          @click="toggleCategory(categoryKey)"
      >
    <span :class="[
          'text-2xl font-bold',
          currentVisibleCategory === categoryKey ? 'underline' : ''
        ]">
      {{ categoryData.title }}
      - {{ filteredPackings(categoryKey).length + filteredAssemblies(categoryKey).length }} Batches
    </span>
      </div>
    </div>


    <!-- The following code shows/hides tables based on the button clicked -->
    <div v-for="(categoryData, categoryKey) in categories" :key="categoryKey">
      <div v-show="currentVisibleCategory === categoryKey">
        <ShippingsPackingsViewTable :packings="filteredPackings(categoryKey)"
                                    :assemblies="filteredAssemblies(categoryKey)"
                                    :category="categoryKey" :filters="filters" :boxes="boxes"/>
        <ShippingsAssembliesViewTable
            :assemblies="filteredAssemblies(categoryKey)"
            :filters="filters" :boxes="boxes"/>
      </div>
    </div>
  </div>
</template>


<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import ShippingsPackingsViewTable from '@/Components/ShippingsPackingsViewTable'
import ShippingsAssembliesViewTable from '@/Components/ShippingsAssembliesViewTable'
import {adjustDate, setToToday, setToTomorrow, copyToClipboard} from '@/Services/genericDataService';


export default {
  metaInfo: {title: 'packings'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    ShippingsPackingsViewTable,
    ShippingsAssembliesViewTable
  },
  props: {
    auth: Object,
    packings: Object,
    assemblies: Object,
    users: Object,
    filters: Object,
    boxes: Array,
  },
  data() {
    return {
      categories: {
        'af-uk': {'title': 'UK', 'colour': 'bg-blue-400'},
        'af-eu-de': {'title': 'Germany', 'colour': 'bg-indigo-400'},
        'af-eu-fr': {'title': 'France', 'colour': 'bg-green-400'},
        'af-eu-es': {'title': 'Spain', 'colour': 'bg-orange-400'},
        'af-eu-it': {'title': 'Italy', 'colour': 'bg-pink-400'}
      },
      visibleCategories: {
        'af-uk': true,
        'af-eu-de': false,
        'af-eu-fr': false,
        'af-eu-es': false,
        'af-eu-it': false
      },
      currentVisibleCategory: 'af-uk',
      categories_old: ['af-uk', 'af-eu-de', 'af-eu-fr', 'af-eu-es', 'af-eu-it'],
      form: {
        search: this.filters.search,
        date: this.filters.date
      },
      //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        //this.$inertia.replace(this.route('units', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
   */
  methods: {
    submit() {
      this.$inertia.get(this.route('shippings.planner'), this.form, {preserveState: false})
    },
    toggleCategory(categoryKey) {
      if (this.currentVisibleCategory === categoryKey) {
        // Hide the table if the button for the currently visible category is clicked again
        this.currentVisibleCategory = null;
      } else {
        // Otherwise, set the clicked category as the currently visible one
        this.currentVisibleCategory = categoryKey;
      }
    },
    filteredPackings(categoryKey) {
      return this.packings.data.filter(packing => packing.category === categoryKey);
    },
    filteredAssemblies(categoryKey) {
      return this.assemblies.data.filter(assembly => assembly.category === categoryKey);
    },
    handleRetrieveLabelPDF(label) {
      retrieveLabelPDF(this.route, label)
    },
    handleAdjustDate(days) {
      this.form.date = adjustDate(this.form.date, days);
      this.submit();
    },
    handleSetToToday() {
      this.form.date = setToToday()
      this.submit();
    },
    handleSetToTomorrow() {
      this.form.date = setToTomorrow()
      this.submit();
    },
    handleCopyToClipboard(text) {
       copyToClipboard(text)
    },
    /*
    adjustDate(days) {
      let currentDate = new Date(this.filters.date);
      currentDate.setDate(currentDate.getDate() + days);
      this.filters.date = this.formatDate(currentDate);
      this.submitDate();
    },
    formatDate(date) {
      return date.toISOString().substr(0, 10);
    },
    setToToday() {
      let today = new Date();
      this.filters.date = this.formatDate(today);
      this.submitDate();
    },
    setToTomorrow() {
      let today = new Date();
      today.setDate(today.getDate() + 1); // this adds a day to the current date
      this.filters.date = this.formatDate(today);
      this.submitDate();
    }
    */

  },
  computed: {
    unassigned_count() {
      return this.packings.data.filter(packing => packing.user_id == null).length;
    }
  }
}
</script>
