<template>
  <div class="">
    <div class="mt-10 mb-4">
      <div class="flex items-center group py-3">
        <div class="text-gray-500">ARCHIVES</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('assemblies.archive')">
        <i :class="['fas fa-box mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('assemblies/archive') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Assembly
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('packings.archive')">
        <i :class="['fas fa-boxes-stacked mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('packings/archive') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Packing
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('labels')">
        <i :class="['fas fa-layer-group mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('labels') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Labels
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('labels.unprinted')">
        <i :class="['fas fa-tag mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('labels/unprinted') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Unprinted Labels
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('packings.shipped')">
        <i :class="['fas fa-truck-fast mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('packings/shipped') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Shipped
        </div>
      </inertia-link>
    </div>
    <div class="mt-10 mb-4">
      <div class="flex items-center group py-3">
        <div class="text-gray-500">TO DELETE</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('assemblies.view')" :data="{status: '3'}">
        <i :class="['fas fa-box mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('assemblies/view/complete') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Assembled
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('packings.view')" :data="{status: '3'}">
        <i :class="['fas fa-boxes-stacked mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('packings/view/complete') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Ready To Ship
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('packings.view')" :data="{status: '4'}">"
        <i :class="['fas fa-check mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('packings/view/shipped') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Shipped
        </div>
      </inertia-link>
    </div>
  </div>

</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl', 'auth'],
  methods: {},
  /*
  computed: {
    isAllowedEmail() {
      const allowedEmails = ['andrew.j.stainer@gmail.com', 'thijs@nikura.com', 'philip@nikura.com', 'admin@nikura.com'];
      return allowedEmails.includes(this.auth.user.email);
      //return true
    }
  },
   */
}
</script>
