<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">Binrack Search</h1>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <!--form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU"/>
        </div>
      </form-->
      <form @submit.prevent="submit">
        <div class="w-96">
          <text-input v-model="form.search" class="" label="Name / SKU / FBA Barcode"/>
        </div>
        <div class="my-10 flex">
          <button class="btn-indigo" type="submit">Search</button>
          <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
        </div>
      </form>
    </div>
    <!--div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
    </div-->
    <div class="mt-12 rounded bg-white shadow overflow-x-auto text-sm">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
               <th class="px-6 pt-6 pb-4">SKU</th>
               <th class="px-6 pt-6 pb-4">Binrack</th>
          <th class="px-6 pt-6 pb-4">Name</th>

          <!--th class="px-6 pt-6 pb-4">FBA Barcode</th-->
        </tr>
        <tr v-for="bundle in bundles.data" :key="bundle.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
                  <td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ bundle.sku }}
            </div>
               </td>
          <td class="border-t">

             <div class="px-6 py-4 flex items-center">
              {{ bundle.bin_rack }}
           </div>
          </td>
               <td class="border-t">
             <div class="px-6 py-4 flex items-center">
              {{ bundle.name }}
             </div>
          </td>



           <!--td class="border-t">
            <div class="px-6 py-4 flex items-center">
              {{ bundle.parent_barcode }}
            </div>
          </td-->
        </tr>
        <tr v-if="bundles.data.length === 0">
          <td class="border-t px-6 py-4" colspan="20">No bundles found.</td>
        </tr>
      </table>
    </div>
    <!--pagination :links="bundles.links"/-->
     <!--div class="mt-6 -mb-1 flex flex-wrap">
      <template v-for="(link, key) in bundles.links">
        <div v-if="link.url === null" :key="key" class="mr-1 mb-1 px-4 py-3 text-sm border rounded text-gray-400"
             :class="{ 'ml-auto': link.label === 'Next' }">{{ link.label }}
        </div>
        <inertia-link v-else :key="key"
                      class="mr-1 mb-1 px-4 py-3 text-sm border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
                      :class="{ 'bg-white': link.active, 'ml-auto': link.label === 'Next' }"
                      :href="link.url"
                      :data="form"
                      :preserveState="true">
          {{ link.label }}
        </inertia-link>
      </template>
    </div-->
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'bundles'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    bundles: Object,
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        //search: this.filters.search,
        //categoryFilters: [],
        search: '',
      },
    }
  },
  /*
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form)
        // this.$inertia.replace(this.route('templates', Object.keys(query).length ? query : { remember: 'forget' }))
        this.$inertia.reload({
          data: {
            'search': query.search
          },
        })
      }, 150),
      deep: true,
    },
  },
   */
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('bundles.location'), this.form, {preserveState: true})
    },
  },
}
</script>
