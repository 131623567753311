<template>
  <div>
    <div class="flex justify-between items-center">
      <div class="mt-10 font-bold border-size-2 text-2xl">
        Packed
      </div>
      <div class="mt-10 flex">
        <a class="btn-indigo m-0 text" :href="'/shippings/planner?action=getAmazonXL&category=' + category">
          Export
        </a>
        <!--button class="btn-indigo ml-2 m-0"
                @click="handleShipAll(packings)">
          <span>Mark as Shipped</span>
        </button-->
      </div>
    </div>

    <div class="mt-12 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
                   <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-truck cursor-help" title="Ship Today"></i></th>
       <th class="px-6 pt-6 pb-4 pl-8"><i class="fa-solid fa-star cursor-help" title="Make Priority"></i>
                <th class="px-6 pt-6 pb-4 border-l">Tags</th>
          <th class="px-6 pt-6 pb-4">Due Date</th>
           <th class="px-6 pt-6 pb-4"></th>
          <th class="px-6 pt-6 pb-4">Status</th>
          <th class="px-6 pt-6 pb-4">Category</th>
          <th class="px-6 pt-6 pb-4">Hazardous</th>
          <th class="px-6 pt-6 pb-4">Tool SKU</th>
          <th class="px-6 pt-6 pb-4">Amazon SKU</th>
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="px-6 pt-6 pb-4">Quantity</th>
          <th class="px-6 pt-6 pb-4">Inner Box Type</th>
            <th class="px-6 pt-6 pb-4">Inner Box #</th>
            <th class="px-6 pt-6 pb-4">Outer Box Type</th>
            <th class="px-6 pt-6 pb-4">Outer Box #</th>
          <th class="px-6 pt-6 pb-4">Units Per Box</th>
          <th class="px-6 pt-6 pb-4">Box Weight (kg)</th>
          <th class="px-6 pt-6 pb-4">Total Weight (kg)</th>
             <th class="px-6 pt-6 pb-4">Batch Code</th>
           <th class="px-6 pt-6 pb-4">Packing ID</th>
          <!--th class="px-6 pt-6 pb-4">Packer</th>
          <th class="px-6 pt-6 pb-4">Shipped</th-->å
        </tr>
        <tr v-for="packing in packings" :key="packing.id"
                    :class="{'bg-gray-300': packing.status == '4'}">
          <!--td class="border-t border-l">
            <div class="px-6 flex items-center focus:text-indigo-500 cursor-pointer" @click="shipPacking(packing)">
              <i class="fas fa-truck-fast text-indigo-400"></i>
            </div>
          </td-->
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="packing.ship_today" @change="handleSavePacking(packing)">
            </div>
          </td>
           <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input type="checkbox" :id="1" v-model="packing.is_priority" @change="handleSavePacking(packing)">
            </div>
          </td>
                      <td class="border-t border-l w-10">
              <!--div class="align-middle px-2 top-2x w-72 whitespace-normal"-->
              <div class="align-middle px-2 relative px-6">
                 <span v-if="handleIsDateInPast(packing.due_date)"
                       class="auto-tag bg-blue-400 mt-0 mb-2">Late</span>
                <span v-if="packing.is_priority"
                      class="auto-tag bg-orange-400 mt-0 mb-2">Priority</span>
              </div>
            </td>
          <td class="border-t">
            <div class="px-6 flex items-center">
              <!-- https://stackoverflow.com/questions/67866941/v-date-picker-not-calling-change-event-when-single-date-is-picked -->
              <v-date-picker v-model="packing.due_date" @input="handleSavePacking(packing)">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      :value="inputValue"
                      class="border p-2 rounded w-32 bg-gray-100"
                      v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </div>
          </td>
          <td class="border-t">
            <div class="px-8 pl-0 flex items-center focus:text-indigo-500" tabindex="-1">
              <button @click="handleAdjustDate(packing, -1)" class="ml-2 bg-indigo-600 text-white p-2 rounded">
                <i class="fa-solid fa-minus"></i>
              </button>
              <button @click="handleAdjustDate(packing, 1)" class="ml-2 bg-indigo-600 text-white p-2 rounded">
                <i class="fa-solid fa-plus"></i>
              </button>
              <!--button @click="setDueDate(packing, 2)" class="ml-2 bg-indigo-500 text-white px-2 py-1 rounded">+2
              </button-->
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 py-4 flex items-center" tabindex="-1">

              {{ packing.status_name }}

            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <span v-if="packing.category == null"> -- No category selected --</span>
              <span v-else>{{ packing.category_name }}</span>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.bundle_hazard }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', packing.bundle_id)">
                {{ packing.bundle_sku }}
              </a>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', packing.bundle_id)">
                {{ packing.amazon_channel_sku }}
              </a>
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <a target="_blank" :href="route('bundles.edit', packing.bundle_id)">
                {{ packing.bundle_name }}
              </a>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              <div>
                {{ packing.quantity_required }}
              </div>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <select v-model="packing.inner_box_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSavePacking(packing)">
                <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input class="w-20 border rounded p-2 bg-gray-100" v-model="packing.inner_box_quantity"
                     @change="handleSavePacking(packing)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <select v-model="packing.box_id" class="w-56 border rounded p-2 bg-gray-100"
                      @change="handleSavePacking(packing)">
                <option v-for="box in boxes" :key=box.id :value=box.id>{{ box.name }}</option>
              </select>
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center focus:text-indigo-500" tabindex="-1">
              <input class="w-20 border rounded p-2 bg-gray-100" v-model="packing.box_quantity"
                     @change="handleSavePacking(packing)">
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseInt(packing.quantity_required / packing.box_quantity) }}
            </div>
          </td>
          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseFloat(packing.quantity_required / packing.box_quantity * packing.bundle_weight / 1000, 2) }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ parseFloat(packing.quantity_required * packing.bundle_weight / 1000, 2) }}
            </div>
          </td>
           <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ packing.batch_code }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ packing.id }}
            </div>
          </td>

          <!--td class="border-t">
            <div class="px-6 flex items-center" tabindex="-1">
              {{ packing.username }}
            </div>
          </td>

          <td class="border-t">
            <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <span v-if="packing.shipped_timestamp != null && packing.shipped_timestamp != '' ">
                  {{ packing.shipped_timestamp.substring(0, 16) }}
                </span>
            </div>
          </td-->
        </tr>
        <tr v-if="packings.length === 0">
          <td class="border-t px-6 py-4" colspan="30">No batches found in packing.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {shipAll} from '@/Services/shippingsApiService';
import {saveObject} from '@/Services/genericApiService';
import {adjustDate, isDateInPast} from '@/Services/genericDataService';

export default {
  props: {
    category: String,
    packings: Object,
    assemblies: Object,
    filters: Object,
    boxes: Array
  },
  methods: {
    // Data manipulation
    handleIsDateInPast(dueDate) {
      return isDateInPast(dueDate)
    },
    handleSavePacking(packing) {
      saveObject(this.$inertia, this.route('shippings.planner.packing.update'), packing, this.filters);
    },
    handleAdjustDate(packing, daysToAdd) {
      packing.due_date = adjustDate(packing.due_date, daysToAdd)
    },
    handleShipAll(packings) {
      if (this.assemblies.length === 0) {
        if (confirm(
            'Are you sure you want to mark all these batches as shipped?'
        )) {
          shipAll(this.$inertia, this.route, packings, this.filters);
        }
      } else {
        alert('ERROR - There are still batches in Assembly. \n\n' +
            'All batches must be in Packing before proceeding')
      }
    },
  }
}
</script>
