<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('bundles')">Bundle</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="bundle.deleted_at" class="mb-6" @restore="restore">
      This bundle has been deleted.
    </trashed-message>
    <h2 class="mb-8 mt-16 font-bold text-2xl mt-15">Details</h2>
    <form @submit.prevent="submit">
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.name" :error="errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name"/>
          <text-input v-model="form.sku" :error="errors.sku" class="pr-6 pb-8 w-full lg:w-1/2" label="SKU"/>
          <select-input v-model="form.category" :error="errors.category" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Category">
            <option v-for="category in categories" :key="category[0]" :value="category[0]">{{ category[1] }}</option>
          </select-input>
          <!--select-input v-model="form.fba_category" :error="errors.fba_category" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="FBA Category">
            <option v-for="fba_category in fba_categories" :key="fba_category[0]" :value="fba_category[0]">
              {{ fba_category[1] }}
            </option>
          </select-input-->

          <select-input v-model="form.hazard" :error="errors.hazard" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="FBA Hazardous">
            <option v-for="hazard in hazards" :key="hazard[0]" :value="hazard[0]">{{ hazard[1] }}</option>
          </select-input>


          <select-input v-model="form.pre_boxed" :error="errors.pre_boxed" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="Pre Boxed">
            <option v-for="pre_boxed in pre_boxeds" :key="pre_boxed[0]" :value="pre_boxed[0]">{{
                pre_boxed[1]
              }}
            </option>
          </select-input>
          <text-input v-model="form.notes" :error="errors.notes"
                      class="pr-6 pb-8 w-full lg:w-1/2" label="Notes"/>
          <select-input v-model="form.liquid_unit_measure" :error="errors.liquid_unit_measure"
                        class="pr-6 pb-8 w-full lg:w-1/2" label="Liquid Components Unit of Measure (in beta)">
            <option v-for="liquid_unit_measure in liquid_unit_measures" :key="liquid_unit_measure[0]"
                    :value="liquid_unit_measure[0]">{{ liquid_unit_measure[1] }}
            </option>
          </select-input>
        </div>
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <div class="pr-6 pb-8 w-full">Redundant Barcodes (now at bottom of page)</div>
          <text-input v-model="form.nikura_barcode" :error="errors.nikura_barcode" class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Nikura Barcode"/>
          <text-input v-model="form.nikura_de_barcode" :error="errors.nikura_de_barcode"
                      class="pr-6 pb-8 w-full lg:w-1/2" label="Nikura DE Barcode"/>
          <text-input v-model="form.amazon_barcode" :error="errors.amazon_barcode" class="pr-6 pb-8 w-full lg:w-1/2"
                      label="Amazon Barcode"/>
          <text-input v-model="form.amazon_de_barcode" :error="errors.amazon_de_barcode"
                      class="pr-6 pb-8 w-full lg:w-1/2" label="Amazon DE Barcode"/>
          <text-input v-model="form.amazon_fr_barcode" :error="errors.amazon_fr_barcode"
                      class="pr-6 pb-8 w-full lg:w-1/2" label="Amazon FR Barcode"/>
        </div>
      </div>

      <div class="mt-10">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Save</loading-button>
      </div>
    </form>
    <h2 class="mt-16 mb-8 font-bold text-2xl">Components In This Bundle</h2>
    <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->
    <div class="rounded bg-white shadow overflow-x-auto mt-10">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4"></th>
          <th class="px-6 pt-6 pb-4">Type</th>
          <!--th class="px-6 pt-6 pb-4">Category</th-->
          <th class="px-6 pt-6 pb-4">Name</th>
          <th class="pl-3 pr-6 pt-6 pb-4">Quantity</th>
          <th class="px-6 pt-6 pb-4">Unit of Measure</th>
          <th class="px-6 pt-6 pb-4">Components + Freight (Ex VAT)</th>
        </tr>
        <tr v-for="component_unit in component_units" :key="component_unit.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100">
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center"
                          method="delete"
                          :href="route('bundles.component.destroy', bundle.id)"
                          :data="{
                            id: component_unit.id,
                            content_type: 'unit'
                          }"
                          preserve-scroll
                          tabindex="-1">
              <i class="fa fa-minus-circle text-gray-600"></i>
            </inertia-link>
          </td-->
          <td class="border-t">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="deleteUnitComponent(component_unit)" class="cursor-pointer">
                <i class="fa fa-minus-circle text-gray-600"></i>
              </div>
            </div>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', component_unit.id)"
                          tabindex="-1">
              Unit
            </inertia-link>
          </td>
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', component_unit.id)"
                          tabindex="-1">
              {{ component_unit.category }}
            </inertia-link>
          </td-->
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('units.edit', component_unit.id)">
              {{ component_unit.name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <div class="mx-2">
              <text-input v-model="component_unit.quantity" class="w-24"/>
            </div>
          </td>
          <!-- Calculate and display units of measure -->
          <td class="border-t">
            <div class="ml-6" tabindex="-1">
              <span v-if="['eo','fo'].includes(component_unit.category)">
                <span v-if="form.liquid_unit_measure === 'ml'">ml</span>
                <span v-if="form.liquid_unit_measure === 'g'">g</span>
              </span>
              <!--select v-if="component_unit.component_measurement==='u'" class="bg-transparent w-40 ml-6"
                      v-model="component_unit.component_measurement">
                <option :key="component_unit.component_measurement" :value="component_unit.component_measurement">
                  unit
                </option>
              </select-->

              <!--select class = "bg-transparent ml-6" v-model="component_unit.component_measurement">
                <option v-for="measurement in measurements" :key='measurement[0]' :value='measurement[0]'>{{
                    measurement[1]
                  }}
                </option>
              </select-->


            </div>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('units.edit', component_unit.id)"
                          tabindex="-1">
              <!--span v-if="form.liquid_unit_measure==='g' && component_unit.measurement==='ml'">
                £{{
                  parseFloat(component_unit.quantity / component_unit.purchase_quantity * 1 / component_unit.density
                      * (component_unit.supplier_currency_conversion * component_unit.cost + component_unit.supplier_freight_cost)).toFixed(3)
                }}
              </span>
              <span v-else-if="form.liquid_unit_measure==='ml' && component_unit.measurement==='g'">
                £{{
                  parseFloat(component_unit.quantity / component_unit.purchase_quantity * component_unit.density
                      * (component_unit.supplier_currency_conversion * component_unit.cost + component_unit.supplier_freight_cost)).toFixed(3)
                }}
              </span>
              <span v-else>
                £{{
                  parseFloat(component_unit.quantity / component_unit.purchase_quantity
                      * (component_unit.supplier_currency_conversion * component_unit.cost + component_unit.supplier_freight_cost)).toFixed(3)
                }}
              </span-->
              £{{ parseFloat(component_unit.cost_adj * component_unit.quantity).toFixed(3) }}
              <!-- £{{ parseFloat(component_unit.cost_adj * component_unit.quantity).toFixed(3) }} -->
            </inertia-link>
          </td>
          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('units.edit', component_unit.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>

        <tr v-for="component_bundle in component_bundles" :key="component_bundle.id">
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center"
                          method="delete"
                          :href="route('bundles.component.destroy', bundle.id)"
                          :data="{
                            id: component_bundle.id,
                            content_type: 'bundle'
                          }"
                          preserve-scroll
                          tabindex="-1">
              <i class="fa fa-minus-circle text-gray-600"></i>
            </inertia-link>
          </td-->
          <td class="border-t">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="deleteBundleComponent(component_bundle)" class="cursor-pointer">
                <i class="fa fa-minus-circle text-gray-600"></i>
              </div>
            </div>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', component_bundle.id)"
                          tabindex="-1">
              Bundle
            </inertia-link>
          </td>
          <!--td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', component_bundle.id)"
                          tabindex="-1">
              {{ component_bundle.category }}
            </inertia-link>
          </td-->
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500"
                          :href="route('bundles.edit', component_bundle.id)">
              {{ component_bundle.name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <div class="mx-2">
              <text-input v-model="component_bundle.quantity" class="w-24"/>
            </div>
          </td>
          <td class="border-t">

          </td>


          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', component_bundle.id)"
                          tabindex="-1">
              £{{ parseFloat(component_bundle.goods_cost * component_bundle.quantity).toFixed(3) }}
            </inertia-link>
          </td>

          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('bundles.edit', component_bundle.id)"
                          tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400"/>
            </inertia-link>
          </td>
        </tr>
        <tr v-if="component_units.length === 0 && component_bundles.length === 0">
          <td class="border-t px-6 py-4" colspan="10">No components found.</td>
        </tr>
      </table>
    </div>

    <div class="mt-10 flex">
      <!--inertia-link class="btn-indigo" :href="route('bundles.unit.create', bundle.id)">
        <span>Add</span>
        <span class="hidden md:inline">Unit</span>
      </inertia-link>
      <inertia-link class="btn-indigo ml-10" :href="route('bundles.child.create', bundle.id)">
        <span>Add</span>
        <span class="hidden md:inline">Bundle</span>
      </inertia-link-->
      <inertia-link class="btn-indigo" :href="route('bundles.component.create', bundle.id)">
        <span>Add</span>
        <span class="hidden md:inline">Component</span>
      </inertia-link>
      <form @submit.prevent="updateComponents">
        <loading-button :loading="sending" class="btn-indigo ml-10" type="submit">Save</loading-button>
      </form>
    </div>


    <h2 class="mt-16 mb-8 font-bold text-2xl">Pricing</h2>
    <!--div>
           <br>
      To calculate price from margin (or vice versa), input data and
      <span class="font-bold">hit Enter/Return</span> or
      <span class="font-bold">hit Calculate button</span>.
      <br>
    </div-->
    <div class="mt-12 rounded bg-white shadow overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="border-l" colspan="1"><!-- Delete --></th>
          <th class="px-6 pt-6 pb-4 text-center border-l"><i class="fas fa-shopping-cart"></i></th>
          <th class="px-6 pt-6 pb-4 text-center">LW</th>
          <!--th class="px-6 pt-6 pb-4 text-center"><i class="fa-brands fa-amazon"></i></th-->
          <th class="border-l" colspan="2"><!-- Save + Undo --></th>
          <th class="px-6 pt-6 pb-4">Channel</th>
          <!--th class="px-6 pt-6 pb-4">Postage</th-->
          <th class="px-6 pt-6 pb-4">Postage Bundle</th>
          <th class="px-6 pt-6 pb-4 text-left">Margin %</th>
          <th class="px-6 pt-6 pb-4 text-left" colspan="1">Price (inc VAT)</th>
          <!--th class="px-6 pt-6 pb-4">Postage</th-->
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Price (ex VAT)</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Margin</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Platform Fees</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Components</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">Postage Cost</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">SNL Discount</th>
          <th class="pl-10 pt-6 pb-4 text-right" colspan="1">SNL Threshold</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Bundle SKU</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">LW SKU</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">LW ChannelSKU</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Tool Price</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">LW Price</th>
          <th class="pl-10 pt-6 pb-4 text-left" colspan="1">Amazon Price</th>

          <!--th class="px-6 pt-6 pb-4 border-l text-center" colspan="2">Name</th-->
          <!--th class="px-6 pt-6 pb-4 border-l text-center">Postage & Packaging</th-->
        </tr>
        <!--tr v-for="price in prices" :key="price.id"
            :class="price.price != price.target_price || price.channel != price.original_channel || price.postage_id != price.original_postage_id ? 'bg-indigo-100' : ''"-->
        <tr v-for="price in prices" :key="price.id"
            :class="Math.abs(price.price - price.price_original) > 0.01 ? 'bg-indigo-100' : ''">
          <!-- delete price -->
          <td class="border-t">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="deletePrice(price)" class="cursor-pointer">
                <i class="fa fa-minus-circle text-gray-600"></i>
              </div>
            </div>
          </td>
          <!-- Status - Is there a LW SKU? -->
          <td v-if="price.lw_channel_sku == null || price.lw_channel_sku == ''" class="border-t border-l" colspan="1">
            <div class="px-6 py-4 flex items-center" tabindex="-1">
              <div>
              </div>
            </div>
          </td>
          <td v-else class="border-t border-l" colspan="1">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div>
                <i class="fas fa-check text-gray-600"></i>
              </div>
            </div>
          </td>
          <!-- Status - Do prices match? -->
          <td v-if="price.lw_channel_sku == null || price.lw_channel_sku == ''" class="border-t" class="border-t"
              colspan="1">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div>
                <!--i class="fas fa-check text-gray-600"></i-->
              </div>
            </div>
          </td>
          <td v-else-if="price.price == price.lw_price" class="border-t" class="border-t" colspan="1">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div>
                <i class="fas fa-check text-gray-600"></i>
              </div>
            </div>
          </td>
          <td v-else-if="price.lw_price==null || price.lw_price==''" class="border-t" class="border-t" colspan="1">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="savePrice(price)" class="cursor-pointer">
                <i class="fas fa-ban text-gray-600"></i>
              </div>
            </div>
          </td>
          <td v-else class="border-t" class="border-t" colspan="1">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="savePrice(price)" class="cursor-pointer">
                <i class="fas fa-not-equal text-gray-600"></i>
              </div>
            </div>
          </td>
          <!-- Status - Does Amazon price match? -->
          <!--td class="border-t" class="border-t" colspan="1">
            <div
                v-if="(price.channel.includes('am') || price.channel.includes('af'))
                && price.lw_channel_sku != '' && price.price == price.amazon_price"
                class="px-6 py-4 flex items-center ">
              <i class="fas fa-check text-gray-600"></i>
            </div>
            <div
                  v-else-if="(price.channel.includes('am') || price.channel.includes('af'))
                && price.lw_channel_sku != '' && price.price != price.amazon_price"
                class="px-6 py-4 flex items-center ">
              <div @click="saveAmazonPrice(price)" class="cursor-pointer">
                <i class="fas fa-not-equal text-gray-600"></i>
              </div>
            </div>
            <div v-else class="px-6 py-4 flex items-center ">
            </div>
          </td-->

          <!-- Save + Undo logic -->
          <td v-if="Math.abs(price.price - price.price_original) > 0.01" class="border-t border-l">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="price.price=price.price_original, price.margin_perc=getMarginPerc(price)">
                <i class="fas fa-undo text-gray-600"></i>
              </div>
            </div>
          </td>
          <td v-if="Math.abs(price.price - price.price_original) > 0.01" class="border-t borderl">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="savePrice(price)" class="cursor-pointer">
                <i class="fas fa-save text-gray-600"></i>
              </div>
            </div>
          </td>
          <td v-else class="border-t border-l" colspan="2">
            <!-- padding to provide gap if no buttons -->
          </td>
          <!--New bit -->
          <td class="border-t">
            <div class="mx-2">
              <select class="bg-transparent" v-model="price.channel" class="w-72"
                      @change="saveChannelAndPostage(price)">
                <option :value=null>[Select Channel]</option>
                <option v-for="channel in channels" :key="channel[0]" :value="channel[0]">{{ channel[1] }}</option>
              </select>
            </div>
          </td>
          <!--td class="border-t">
            <div class="mx-2">
              <select class="bg-transparent" v-model="price.postage_id" class="w-72"
                      @change="saveChannelAndPostage(price)">
                <option :value=null>None</option>
                <option v-for="postage in postage" :key="postage.id" :value="postage.id">{{ postage.name }}</option>
              </select>
            </div>
          </td-->
          <td class="border-t">
            <div class="mx-2">
              <select class="bg-transparent" v-model="price.postage_bundle_id" class="w-72"
                      @change="saveChannelAndPostage(price)">
                <option :value=null>None</option>
                <option v-for="postage_bundle in postage_bundles" :key="postage_bundle.id" :value="postage_bundle.id">
                  {{ postage_bundle.name }}
                </option>
              </select>
            </div>
          </td>
          <!-- margin % -->
          <td class="border-t text-center">
            <div class="px-6 flex items-center" tabindex="-1">
              <input
                  class="w-20 border rounded p-2"
                  v-model="price.margin_perc"
                  v-on:keyup="price.price=getPrice(price)"
              />
              <span class="pl-2">%</span>
            </div>
          </td>
          <!-- price exvat -->
          <td class="border-t text-center">
            <div class="px-6 flex items-center" tabindex="-1">
              <span class="pr-2">{{ price.currency_symbol }}</span>
              <input
                  class="w-20 border rounded p-2"
                  v-model="price.price"
                  v-on:keyup="price.margin_perc=getMarginPerc(price)"
                  v-on:keyup.enter="savePrice(price)"
              />
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ parseFloat(price.price * price.currency_conversion / 1.2).toFixed(2) }}
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ getMargin(price) }}
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ parseFloat(getPlatformFees(price)).toFixed(2) }}
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ parseFloat(price.goods_cost).toFixed(2) }}
            </div>
          </td>
          <!--td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ parseFloat(price.postage_service_cost).toFixed(2) }}
            </div>
          </td-->
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1">
              £{{ parseFloat(price.postage_bundle_cost).toFixed(2) }}
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1" v-if="parseFloat(getSnlDiscount(price)) !== 0">
              £{{ parseFloat(getSnlDiscount(price)).toFixed(2) }}
            </div>
          </td>
          <td class="border-t text-right">
            <div class="py-4" tabindex="-1" v-if="parseFloat(price.snl_discount_threshold) !== 0">
              <span>{{ `${price.currency_symbol}${parseFloat(price.snl_discount_threshold).toFixed(2)}` }}</span>
            </div>
          </td>

          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.bundle_sku }}
            </div>
          </td>
          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.lw_sku }}
            </div>
          </td>
          <td class="border-t text-left pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.lw_channel_sku }}
            </div>
          </td>
          <td class="border-t text-right pl-10">
            <div class="py-4" tabindex="-1">
              {{ price.currency_symbol }}{{ price.price }}
            </div>
          </td>
          <td class="border-t text-right pl-10">
            <div class="py-4" tabindex="-1">
              <span v-if="price.lw_price != 0 && price.lw_price != '' && price.lw_price != null"
                    class="pr-2">{{ price.currency_symbol }}{{ price.lw_price }}</span>
            </div>
          </td>
          <td class="border-t text-right pl-10">
            <div class="py-4" tabindex="-1">
              <span v-if="price.amazon_price" class="pr-2">{{ price.currency_symbol }}{{ price.amazon_price }}</span>
            </div>
          </td>
          <td class="border-t px-4">
          </td>

        </tr>
        <tr v-if="prices.length === 0">
          <td class="border-t px-6 py-4" colspan="13">No prices found.</td>
        </tr>
      </table>
    </div>
    <div class="mt-10 flex">
      <form @submit.prevent="addPrice">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Add Price</loading-button>
      </form>
      <button class="btn-indigo ml-8" @click="getLWPrices">
        <span>LW Sync</span>
      </button>
    </div>


    <h2 class="mt-16 mb-8 font-bold text-2xl">Barcodes</h2>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <!--div class="mt-12 rounded bg-white shadow overflow-x-auto w-144"-->
      <table class="w-full whitespace-no-wrap">
        <tr class="text-left font-bold">
          <th class="border-l" colspan="1"><!-- Delete --></th>
          <th class="px-6 pt-6 pb-4 border-l">Country</th>
          <th class="px-6 pt-6 pb-4">Barcode</th>
        </tr>
        <!--tr v-for="price in prices" :key="price.id"
            :class="price.price != price.target_price || price.channel != price.original_channel || price.postage_id != price.original_postage_id ? 'bg-indigo-100' : ''"-->
        <tr v-for="barcode in barcodes" :key="barcode.id">
          <td class="border-t">
            <div class="px-6 py-4 flex items-center " tabindex="-1">
              <div @click="deleteBarcode(barcode)" class="cursor-pointer">
                <i class="fa fa-minus-circle text-gray-600"></i>
              </div>
            </div>
          </td>
          <td class="border-t border-l">
            <div class="mx-6">
              <select class="bg-transparent" v-model="barcode.category" class="w-72"
                      @change="saveBarcode(barcode)">
                <option :value=null>[Select Category]</option>
                <option v-for="category in barcode_categories" :key="category[0]" :value="category[0]">{{
                    category[1]
                  }}
                </option>
              </select>
            </div>
          </td>
          <td class="border-t text-center">
            <div class="px-6 flex items-center" tabindex="-1">
              <input
                  class="w-84 border rounded p-2"
                  v-model="barcode.barcode"
                  v-on:keyup.enter="saveBarcode(barcode)"
              />
            </div>
          </td>
        </tr>
        <tr v-if="barcodes.length === 0">
          <td class="border-t px-6 py-4" colspan="13">No barcodes found.</td>
        </tr>
      </table>
    </div>


    <div class="mt-10 flex">
      <form @submit.prevent="addBarcode">
        <loading-button :loading="sending" class="btn-indigo" type="submit">Add Barcode</loading-button>
      </form>
    </div>
    <div class="mt-16">
      <button v-if="!bundle.deleted_at" class="btn-indigo bg-red-500" type="button" @click="destroy">Delete Bundle
      </button>
    </div>


  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    bundle: Object,
    units: Object,
    bundle_children: Object,
    components: Object,
    postage_bundles: Object,
    prices: Object,
    barcodes: Object,
    postage: Object,
    postage_suggestions: Object,
    categories: Object,
    barcode_categories: Object,
    //measurements: Object,
    liquid_unit_measures: Object,
    hazards: Object,
    pre_boxeds: Object,
    //fba_categories: Object,
    channels: Object,
    component_units: Object,
    component_bundles: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        name: this.bundle.name,
        sku: this.bundle.sku,
        category: this.bundle.category,
        //fba_category: this.bundle.fba_category,
        hazard: this.bundle.hazard,
        pre_boxed: this.bundle.pre_boxed,
        notes: this.bundle.notes,
        nikura_barcode: this.bundle.nikura_barcode,
        nikura_de_barcode: this.bundle.nikura_de_barcode,
        amazon_barcode: this.bundle.amazon_barcode,
        amazon_de_barcode: this.bundle.amazon_de_barcode,
        amazon_fr_barcode: this.bundle.amazon_fr_barcode,
        liquid_unit_measure: this.bundle.liquid_unit_measure
      },
    }
  },
  mounted() {
    // Set platform fees for each price on page load
    this.prices.forEach(price => {
      this.setMargin(price);
      this.setMarginPerc(price);
    });
  },
  methods: {
    getSnlDiscount(price) {
      // Threshold is in own currency
      if (parseFloat(price.price) < parseFloat(price.snl_discount_threshold)) {
        // Return discount converted to £
        return parseFloat(price.snl_discount) * parseFloat(price.currency_conversion);
      } else {
        return 0;
      }
    },
    setMargin: function (price) {
      let margin = this.getMargin(price); // Use a separate method to calculate fees
      // Set the platform fees directly in the price object
      price.margin = margin;
    },
    setMarginPerc: function (price) {
      let marginPerc = this.getMarginPerc(price); // Use a separate method to calculate fees
      // Set the platform fees directly in the price object
      price.margin_perc = marginPerc;
    },
    // New VUE functions
    getPlatformFees: function (price) {
      // ** Amazon ** (includes 2% digital tax)
      // https://sell.amazon.co.uk/pricing?ref_=sduk_soa_pr_h#fulfillment-fees
      let platformFees
      // Catch if platform fees not set
      if (!price.channel) {
        platformFees = 0
      } else if (price.channel == 'am') {
        if (price.price <= 10) {
          platformFees = Math.max(0.0816 * price.price, 0.25)
        } else {
          platformFees = 0.153 * price.price
        }
        // ** Amazon FBA ** (includes 2% digital tax)
      } else if (price.channel.includes('af-uk')) {
        var hazard_fee = 0
        if (price.bundle_hazard == 'Y') {
          hazard_fee = 0.1
        }
        if (price.price <= 10) {
          return Math.max(0.0816 * price.price, 0.25) + hazard_fee
        } else {
          platformFees = 0.153 * price.price + hazard_fee
        }
      } else if (price.channel.includes('af-eu')) {
        var hazard_fee = 0
        if (price.bundle_hazard == 'Y') {
          hazard_fee = 0.1
        }
        if (price.price <= 10) {
          platformFees = Math.max(0.08 * price.price, 0.3) + hazard_fee
        } else {
          platformFees = 0.15 * price.price + hazard_fee
        }
        // ** Ebay ** (includes 2% digital tax)
      } else if (price.channel == 'eb' || price.channel == 'ebm' || price.channel == 'ebs') {
        //return 0.0816 * price.price
        platformFees = 0.109 * price.price + 0.3
        // ** Etsy ** (includes 2% digital tax)
        // Listing fee (15p) + Transaction fee (5%) + Sale Fee (4% + 20p)
      } else if (price.channel == 'et' || price.channel == 'etm') {
        platformFees = 0.15 + 0.05 * price.price + 0.04 * price.price + 0.2
        // ** Shopify **
      } else if (price.channel == 'sh')
        platformFees = 0.019 * price.price + 0.2
      else {
        platformFees = 0
      }
      // Include currency conversion, as platform fees are given in euros
      return platformFees * price.currency_conversion
    },
    getMargin: function (price) {
      let margin = price.price * price.currency_conversion / 1.2 - price.goods_cost - this.getPlatformFees(price) - (price.postage_bundle_cost - this.getSnlDiscount(price))
      return parseFloat(margin).toFixed(2)
    },
    getMarginPerc: function (price) {
      //let margin = price.price/1.2 - price.goods_cost - price.postage_service_cost - this.getPlatformFees(price)
      let margin = this.getMargin(price)
      let margin_perc = margin / (price.price * price.currency_conversion / 1.2) * 100
      return parseFloat(margin_perc).toFixed(0)
    },
    getPrice: function (price) {
      // Method takes margin entered, and finds price to achieve this margin
      let target_margin_perc = price.margin_perc
      let goods_cost = parseFloat(price.goods_cost)
      let postage_bundle_cost = parseFloat(price.postage_bundle_cost)

      // Variables calculated within loop based on price.price
      var platform_fees_calc
      var margin_calc
      var price_calc

      // Calculate max margin achievable
      price.price = 999999
      let margin_perc_max = ((999999 / 1.2) - this.getPlatformFees(price)) / (999999 / 1.2) * 100

      // If margin entered exceeds max, give error
      if (Math.abs(target_margin_perc > margin_perc_max)) {
        return "too high"
      }

      // Start with low value, and work upwards until price achieves margin requested
      price.price = 0.01
      while (true) {
        platform_fees_calc = this.getPlatformFees(price)
        margin_calc = target_margin_perc / 100 * (price.price / 1.2)
        price_calc = 1.2 * (margin_calc + platform_fees_calc + goods_cost + postage_bundle_cost)
        if (Math.abs(price.price - price_calc) < 0.001) {
          return parseFloat(price.price).toFixed(2)
        } else {
          price.price += 0.001
        }
        if (Math.abs(price.price) > 1000) {
          return 'error'
        }
      }
    },
    submit() {
      this.$inertia.put(this.route('bundles.update', this.bundle.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    // Alternative method --> https://stackoverflow.com/questions/42694457/getting-form-data-on-submits
    updateComponents() {
      //If updating prices, no need to send specific component id. Instead send all components
      this.$inertia.put(this.route('bundles.components.update', this.bundle.id),
          {
            units: this.component_units,
            bundles: this.component_bundles
          },
          {
            onStart: () => this.sending = true,
            onFinish: () => this.sending = false,
            preserveScroll: true
          })
    },
    addPrice() {
      this.$inertia.put(this.route('bundles.price.create', this.bundle.id), {}, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
        preserveScroll: true
      })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this bundle?')) {
        this.$inertia.delete(this.route('bundles.destroy', this.bundle.id))
      }
    },
    deleteUnitComponent(component) {
      if (confirm('Are you sure you want to delete this component?')) {
        this.$inertia.put(this.route('bundles.component.destroy', this.bundle.id),
            {
              id: component.id,
              content_type: 'unit'
            },
            {
              preserveScroll: true
            })
      }
    },
    deleteBundleComponent(component) {
      if (confirm('Are you sure you want to delete this component?')) {
        this.$inertia.put(this.route('bundles.component.destroy', this.bundle.id),
            {
              id: component.id,
              content_type: 'bundle'
            },
            {
              preserveScroll: true
            })
      }
    },
    deletePrice(price) {
      if (confirm('Are you sure you want to delete this price?')) {
        this.$inertia.put(this.route('bundles.price.destroy', this.bundle.id),
            {
              id: price.id,
            },
            {
              preserveScroll: true
            })
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this bundle?')) {
        this.$inertia.put(this.route('bundles.restore', this.bundle.id))
      }
    },
    // *** FUNCTIONS BELOW ARE REDUNDANT POST LOGIC MOVE TO VUE *** ///
    //https://forum.vuejs.org/t/the-bind-variable-and-element-of-loop/13423/5
    setMarginFromPrice(id, target_price, original_target_price, channel, postage_id) {
      if (target_price != original_target_price) {
        this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
            {
              set_margin: true,
              id: id,
              target_price: target_price,
              channel: channel,
              postage_id: postage_id,
            },
            {
              preserveScroll: true
            })
      }
    },
    setPriceFromMargin(id, target_margin, original_target_margin, channel, postage_id) {
      if (target_margin != original_target_margin) {
        this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
            {
              set_price: true,
              id: id,
              target_margin: target_margin,
              channel: channel,
              postage_id: postage_id,
            },
            {
              preserveScroll: true
            })
      }
    },
    /* OLD Save
    savePrice(price) {
      // Only run function if price has changed
      this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
          {
            save: true,
            id: price.id,
            price: price.price,
            channel: price.channel,
            postage_id: price.postage_id
          },
          {
            preserveScroll: true
          })

    },
     */
    getLWPrices() {
      if (confirm('Are you sure you want to refresh sync status for all prices?')) {
        this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
            {
              sku: this.form.sku,
              getLWPrices: true,
            },
            {
              preserveScroll: true
            })
      }
    },
    saveChannelAndPostage(price) {
      this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
          {
            save: true,
            price: price
          },
          {
            preserveScroll: true
          })
    },
    addBarcode() {
      this.$inertia.put(this.route('bundles.edit', this.bundle.id), {
            action: 'addBarcode',
          },
          {
            preserveScroll: true
          })
    },
    saveBarcode(barcode) {
      this.$inertia.put(this.route('bundles.edit', this.bundle.id),
          {
            action: 'saveBarcode',
            barcode: barcode
          },
          {
            preserveScroll: true
          })
    },
    deleteBarcode(barcode) {
      if (confirm('Are you sure you want to delete this barcode?')) {
        this.$inertia.put(this.route('bundles.edit', this.bundle.id),
            {
              action: 'deleteBarcode',
              barcode: barcode
            },
            {
              preserveScroll: true
            })
      }
    },

    savePrice(price) {
      // If channel is Shopify Or Amazon, check for LW SKU (i.e. if there is a place to push SKU to
      // If no LW SKU, ask to sync, otherwise ask to save/push
      // If channel is anything else, just ask to save

      // if (price.channel == 'sh' || price.channel == 'am' || price.channel == 'af' || price.channel == 'af-eu') {
      if (price.lw_sku != null && price.lw_sku != '') {
        if (confirm(
            'Are you sure you want save and push this price to Linnworks? ' +
            'Please review details below before proceeding. ' +
            'If anything looks incorrect, try refreshing data via LW Sync. \n\n' +
            price.bundle_name + ' --> ' + price.channel_name + '\n\n' +
            'Linnworks SKU - ' + price.lw_sku + '\n' +
            'Current LW Price - £' + price.lw_price + '\n' +
            'New Price - £' + price.price
        )) {
          this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
              {
                pushSave: true,
                price: price
              },
              {
                preserveScroll: true
              })
        }
      } else {
        if (confirm(
            'Are you sure you want save this price? \n\n' +
            'Note that this Price is not currently mapped to a Linnworks SKU, so nothing will be pushed.'
        )) {
          this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
              {
                save: true,
                price: price
              },
              {
                preserveScroll: true
              })
        }
      }
    },
    resetPrice(id) {
      // Only run function if price has changed
      this.$inertia.put(this.route('bundles.prices.update', this.bundle.id),
          {
            reset: true,
            id: id,
          },
          {
            preserveScroll: true
          })
    }
    ,
  },
}
</script>
