<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <!--inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('labels')">Currencies</inertia-link-->
      <!--span class="text-indigo-400 font-medium"></span--> Hazardous Descrambler
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl pb-5">
      <!--form @submit.prevent="submit"-->
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <form method="post" enctype="multipart/form-data">
          <!--label for="files">Select files:</label-->
          <!--div class="mb-6">
            Please select category and FBA packlist(s). Hold shift to select multiple files.
          </div-->
          <div class="mt-2">
            <!--text-input name="norderid" class="pr-6 pb-8 w-full lg:w-1/2"
                        label="LW Order ID"/-->
            <!--select-input v-model="form.region" name="region" label="Region" class="pr-6 pb-8 w-full lg:w-1/2"
                          label="">
              <option key="uk" value='uk'>UK Shipment</option>
              <option key="de" value='de'>DE Shipment</option>
              <option key="fr" value='fr'>FR Shipment</option>
            </select-input-->
            <!--input type="file" id="files" accept=".csv" name="files"-->
            <div class="mt-6">
              <label for="amazon_csvs">Select Amazon packlist CSV(s):</label>
              <div class="mt-5">
                <input type="file" accept=".csv" id="amazon_csvs" name="amazon_csvs" multiple>
              </div>
            </div>
            <div class="mt-12">
              <label for="ups_pdf">Select UPS label pdf:</label>
              <div class="mt-5">
                <input type="file" accept=".pdf" id="ups_pdf" name="ups_pdf">
              </div>
            </div>
            <div class="mt-12">
              <label for="amazon_pdfs">Select Amazon box pdf(s):</label>
              <div class="mt-5">
                <input type="file" accept=".pdf" id="amazon_pdfs" name="amazon_pdfs" multiple>
              </div>
            </div>
            <div class="mt-12">
              <text-input name='norderid' class="pr-6 pb-8" label="LW Order ID"/>
            </div>
            <div class="mt-6">
              <button :disabled="sending" class="btn-indigo bg-" type="submit" name="action" value="upload">Upload
              </button>
              <button :disabled="sending" class="btn-indigo bg-gray-600 ml-4" type="submit" name="action" value="download">Download
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
          <div class = "m-5 mt-10">*Remember. If above fails, the UPS tracking numbers can be matched to their SKUs manually. <br>
          1) Go to Linnworks <br>
            2) Go to Order --> Open Orders (old)  <br>
            3) Find your order and open it  <br>
            4) Go to the Package Split tab  <br>
          </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'

export default {
  metaInfo: {title: 'Create Currency'},
  layout: Layout,
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
  },
  props: {
    auth: Object,
    errors: Object,
    //data: Object
  },
  remember: 'form',
  data() {
    return {
      sending: false,
      form: {
        region: 'uk',
      },
    }
  },
}
</script>
