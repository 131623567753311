<template>
  <div>
    <!--h1 class="mb-20 font-bold text-3xl"-->
    <h1 class="mb-10 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('assemblies.admin')">Assembly
      </inertia-link>
      <span class="font-medium">/</span>
      {{ bundle.name }}
    </h1>

    <h2 class="mt-16 mb-8 font-bold text-2xl">Batch Details</h2>
    <!--form @submit.prevent="submit"-->
      <div class="rounded bg-white shadow overflow-x-auto mt-10">
        <table class="w-full whitespace-no-wrap">
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4">Assembler</th>
            <th class="px-6 pt-6 pb-4">Station</th>
            <th class="px-6 pt-6 pb-4">Category</th>
            <th class="px-6 pt-6 pb-4">Title</th>
            <th class="px-6 pt-6 pb-4">SKU</th>
            <th class="px-6 pt-6 pb-4">Quantity Required</th>
            <th class="px-6 pt-6 pb-4">Products To Verify</th>
            <th class="px-6 pt-6 pb-4">Products Verified</th>
          </tr>
          <tr>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ assembly.username }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center text-indigo-500 cursor-pointer" @click="clearStation">
                {{ assembly.station }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ assembly.category_name }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ bundle.name }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ bundle.sku }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ assembly.quantity_required }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ assembly.quantity_to_verify }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ form.quantity_verified }}
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="!assembly.station">
        <h2 class="mt-16 mb-8 font-bold text-2xl">Assembly Station</h2>
        <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
          <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
            <div class="pr-6 pb-6 w-full mt-2">
              Enter assembly station
            </div>
            <input class="pr-6 w-full lg:w-1/2 border rounded p-3 bg-gray-100" v-model="form.station" autofocus
                   ref="station"
                   @keyup.enter="submitStation"
                   label="Station"/>
          </div>
          <div class="p-8 flex flex-wrap">
            <button class="btn-indigo mr-8" @click="submitStation">
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
      <div v-else>


        <h2 class="mt-16 mb-8 font-bold text-2xl">Component Stock at <span class="text-indigo-500 cursor-pointer"
                                                                           @click="clearStation">{{ form.station }}</span>
        </h2>
        <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->
        <div class="rounded bg-white shadow overflow-x-auto mt-10">
          <table class="w-full whitespace-no-wrap ">
            <tr class="text-left font-bold">
              <th class="px-6 pt-6 pb-4">Name</th>
              <th class="px-6 pt-6 pb-4">Tool SKU</th>
              <th class="px-6 pt-6 pb-4">LW SKU</th>
              <th class="px-6 pt-6 pb-4">Stock Required</th>
              <th class="px-6 pt-6 pb-4">Stock Available</th>
            </tr>
            <!--tr v-for="component_bundle in component_bundles" :key="component_bundle.id"
                v-if="component_bundle.quantity * assembly.quantity_required > component_bundle.stock_level"
            -->
            <tr v-for="component_bundle in component_bundles" :key="component_bundle.id"
                :class="{'bg-red-200': component_bundle.quantity * assembly.quantity_required > component_bundle.stock_level}">
              <td class="border-t">
                <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                  <a target="_blank" :href="route('bundles.edit', component_bundle.id)">{{
                      component_bundle.name
                    }}</a>
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                  <a target="_blank" :href="route('bundles.edit', component_bundle.id)">{{ component_bundle.sku }}</a>
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                  {{ component_bundle.lw_sku }}
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ component_bundle.quantity * assembly.quantity_required }}
              </span>
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ component_bundle.stock_level }}
              </span>
                </div>
              </td>
            </tr>
          </table>
        </div>

      </div>
    <!--/form-->
    <div v-if="assembly.station && (!insufficient_stock_flag || assembly.insufficient_stock_override)">

      <h2 class="mt-16 mb-8 font-bold text-2xl">Scanning</h2>
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">

        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <div v-if="assembly.quantity_verified >= assembly.quantity_to_verify - 1" class="pr-6 pb-6 w-full">
            Final verification! All {{ assembly.quantity_required }} products must be assembled before proceeding.
          </div>
          <div class="pr-6 pb-6 w-full mt-2">
            Next up --> {{ name_to_scan }}

          </div>


          <!--text-input class="pr-6 pb-8w-full lg:w-1/2" :disabled=1 v-model="component_quantity"
                      label="Components To Scan"/>
          <text-input class="pr-6 pb-8 w-full lg:w-1/2" :disabled=1 v-model="component_scan_count"
                      label="Components Scanned"/-->


          <input class="pr-6 w-full lg:w-1/2 border rounded p-3 bg-gray-100" v-model="barcode" autofocus ref="barcode"
                 @keyup.enter="scanBarcode(barcode)"
                 label="Barcode"/>

        </div>


        <div class="p-8 flex flex-wrap">
          <button class="btn-indigo mr-8" @click="scanBarcode(barcode)">
            <span>Next</span>
          </button>
        </div>

      </div>

      <!--div class="bg-white rounded shadow overflow-x-auto max-w-3xl"-->
      <div class="rounded bg-white shadow overflow-x-auto mt-10">
        <table class="w-full whitespace-no-wrap">
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4">Type</th>
            <th class="px-6 pt-6 pb-4">Name</th>
            <th class="px-6 pt-6 pb-4">SKU</th>
            <th class="px-6 pt-6 pb-4">Routing Sequence</th>
            <th class="px-6 pt-6 pb-4">Barcode</th>
            <th class="px-6 pt-6 pb-4">Scans Remaining</th>
          </tr>
          <tr v-for="box_unit in box_units" :key="box_unit.id"
              :class="box_unit.barcode == barcode_to_scan ? 'bg-indigo-100' : isScanned(box_unit.barcode) ? 'line-through' : ''">
            <!--td class="border-t">
              <inertia-link class="px-6 py-4 flex items-center"
                            method="delete"
                            :href="route('bundles.component.destroy', bundle.id)"
                            :data="{
                              id: component_bundle.id,
                              content_type: 'bundle'
                            }"
                            preserve-scroll
                            tabindex="-1">
                <i class="fa fa-minus-circle text-gray-600"></i>
              </inertia-link>
            </td-->
            <!--td class="border-t">
              <inertia-link class="px-6 py-4 flex items-center" :href="route('bundles.edit', component_bundle.id)"
                            tabindex="-1">
                {{ component_bundle.category }}
              </inertia-link>
            </td-->
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                Box
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ box_unit.name }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ box_unit.sku }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center"
                   tabindex="-1">
                <!-- No routine sequence for boxes -->
              </div>
            </td>

            <td class="border-t">
              <div class="px-6 py-4 flex items-center"
                   tabindex="-1">
                {{ box_unit.barcode }}
              </div>
            </td>

            <td class="border-t">
              <div class="px-20 py-4 flex items-center"
                   tabindex="-1">
                <!--i class="fas fa-ban text-gray-600 ml-5"></i-->
                <span class="scan-flag" :id="box_unit.barcode" :name="box_unit.name">1</span>
              </div>
            </td>
          </tr>
          <tr v-for="component_bundle in component_bundles" :key="component_bundle.id"
              :class="component_bundle.child_barcode == barcode_to_scan? 'bg-indigo-100' :
            isScanned(component_bundle.child_barcode ) ? 'line-through' : ''">
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                Product
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <a target="_blank" :href="route('bundles.edit', component_bundle.id)">{{ component_bundle.name }}</a>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                <a target="_blank" :href="route('bundles.edit', component_bundle.id)">{{ component_bundle.sku }}</a>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ component_bundle.routing_sequence }}
              </span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span>
                 {{ component_bundle.child_barcode }}
              </span>
              </div>
            </td>


            <td class="border-t">
              <div class="px-20 py-4 flex items-center"
                   tabindex="-1">
               <span class="scan-flag" :id="component_bundle.child_barcode"
                     :name="component_bundle.name">{{ parseFloat(component_bundle.quantity).toFixed(0) }}
              </span>
              </div>
            </td>
          </tr>
          <tr v-if="component_bundles.length + box_units.length == 0">
            <td class="border-t px-6 py-4" colspan="10">No components found.</td>
          </tr>
        </table>
      </div>
      <div class="mt-10 ml-6">
        * {{ assembly.category_name }} is currently setup to use {{ component_bundles[0].child_barcode_field }} for
        component barcodes. If barcodes are missing or incorrect, click on component name or SKU to correct.
      </div>
    </div>


  </div>

</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {title: this.form.name}
  },
  layout: Layout,
  components: {
    Icon,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    auth: Object,
    errors: Object,
    assembly: Object,
    bundle: Object,
    component_bundles: Object,
    box_units: Object,
    barcode_to_scan: Object,
    name_to_scan: Object,
    insufficient_stock_flag: Object,
  },
  remember: 'form',
  data() {
    return {
      //component_quantity: this.component_bundles.length + this.box_units.length,
      //component_scan_count: 0,
      //current_box_barcode: this.box_units[0]['barcode'],
      //current_component_barcode: this.component_bundles[0]['nikura_barcode'],
      barcode: '',
      i: 0,
      sending: false,
      barcodes_scanned: [],
      form: {
        //quantity_required: this.assembly.quantity_required,
        //quantity_to_verify: this.assembly.quantity_to_verify,
        quantity_verified: this.assembly.quantity_verified,
        station: this.assembly.station,
        //bundle_id: this.assembly.bundle_id,
        //user: this.assembly.user,
        //due_date: this.assembly.due_date,
        //status: this.assembly.status,
      },
    }
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    isScanned(barcode) {
      return this.barcodes_scanned.filter(n => n === barcode).length === 0 ? false : true;
    },
    focusInput() {
      this.$refs.barcode.focus();
    },
    submit() {
      this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
        onStart: () => this.sending = true,
        onFinish: () => this.sending = false,
      })
    },
    submitStation: function () {
      // Needed to prevent form submitting
      this.form.station = this.form.station.toUpperCase()
      const value = this.form.station
      if (!value.startsWith('0-ASSEMBLY')) {
        window.alert(this.form.station  + ' is not a recognised station. \n\n' +
            'Allowed stations are 0-ASSEMBLY or 0-ASSEMBLY-X'
      );
        return;
      }
      this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form)
    },
    clearStation: function () {
      if (confirm('Are you sure you want to clear and reenter the station?')) {
        this.form.station = '';
        this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
          onStart: () => this.sending = true,
          onFinish: () => this.sending = false,
        });
      }
    },
    scanBarcode: function (barcode) {
      this.barcode = ''
      // 1) Query selector will find first element with scan-flag, and reduce by 1. This element holds barcode under id
      if (document.querySelector(".scan-flag").id == barcode) {
        document.querySelector(".scan-flag").innerText = document.querySelector(".scan-flag").innerText - 1

        // 2) If all components have been scanned, remove scan-flag element
        if (document.querySelector(".scan-flag").innerText == 0) {
          // Add barcode scanned to list and remove scan flag
          this.barcodes_scanned.push(barcode)
          document.querySelector(".scan-flag").remove()
          //document.querySelector(".scan-flag").replaceWith('Scanned')
          //document.querySelector(".scan-flag").replaceWith("<i class='fas fa-ban text-gray-600 ml-5'></i>")

          // 3) Check if all elements have been scanned (i.e. scan flag removed)
          if (document.querySelectorAll(".scan-flag").length == 0) {
            //this.component_scan_count = 0
            if (this.form.quantity_verified < this.assembly.quantity_to_verify) {
              this.form.quantity_verified += 1
            }
            // 4) If all product verified, alert user. Otherwise, refresh and save progress
            if (this.form.quantity_verified >= this.assembly.quantity_to_verify) {
              /*
              alert('All assemblies verified.',
                  this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                    //onStart: () => this.sending = true,
                    //onFinish: () => this.sending = false,
                    preserveScroll: true,
                    preserveState: false
                  }))
               */
              if (confirm('Final verification complete. Has the full batch of ' + this.assembly.quantity_required + ' been assembled? \n\n' +
                  'IMPORTANT: All batches must be assembled before clicking OK. '
              )) {
                this.form.status = 2
                this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                  //onStart: () => this.sending = true,
                  //onFinish: () => this.sending = false,
                  preserveScroll: true,
                  preserveState: false
                })
              } else {
                this.form.status = 1
                this.form.quantity_verified -= 1
                this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                  //onStart: () => this.sending = true,
                  //onFinish: () => this.sending = false,
                  preserveScroll: true,
                  preserveState: false
                })
              }
            } else {
              this.form.status = 1
              alert('Assembly ' + this.form.quantity_verified + ' of ' + this.assembly.quantity_to_verify + ' verified',
                  this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
                    //onStart: () => this.sending = true,
                    //onFinish: () => this.sending = false,
                    preserveScroll: true,
                    preserveState: false
                  }))
            }
          } else {
            // If there is still an element, get latest barcode that needs scanning (used in highlight logic)
            this.barcode_to_scan = document.querySelector(".scan-flag").id
            this.name_to_scan = document.querySelector(".scan-flag").getAttribute('name')
            this.assembly.scan_status = 'x'
          }
        }
      } else {
        alert('Wrong barcode scanned! \n\n' +
            'Barcode Scanned: ' + barcode + '\n' +
            'Expected Barcode: ' + this.barcode_to_scan)
      }

      //if (this.form.quantity_to_verify == this.form.quantity_verified) {
      //  this.form.status = 'Verified'
      //  alert('Verification complete!')
      //    //window.location.href = '/prices/?get_csv_email=y&search=' + form.search;
      //    this.$inertia.put(this.route('assemblies.update', this.assembly.id), this.form, {
      //    //onStart: () => this.sending = true,
      //    //onFinish: () => this.sending = false,
      //    preserveScroll: true,
      //    preserveState: false
      //  })
      //}
    }
    /*
    destroy() {
      if (confirm('Are you sure you want to delete this assembly?')) {
        this.$inertia.delete(this.route('assemblies.destroy', this.assembly.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this assembly?')) {
        this.$inertia.put(this.route('assemblies.restore', this.assembly.id))
      }
    },
     */
  },
}
</script>
