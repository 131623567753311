<template>
  <div class="">
    <div class="mb-4">
      <div class="flex items-center group py-3">
        <div class="text-gray-500">FBA DESCRAMBLER</div>
      </div>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('FBA.orderconverter')">
        <i :class="['fas fa-gears mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('fba/orderconverter') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Open Order Converter
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('FBA.splitpackaging')">
        <i :class="['fas fa-list mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('fba/splitpackaging') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Split Packaging
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('descrambler.hazardous')">
        <i :class="['fas fa-object-ungroup mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('descrambler/hazardous') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Haz Descrambler
        </div>
      </inertia-link>
    </div>
     <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('descrambler.non-hazardous')">
        <i :class="['fas fa-object-ungroup mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('descrambler/nonhazardous') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          Non-Haz Descrambler
        </div>
      </inertia-link>
    </div>
    <div class="mb-4">
      <inertia-link class="flex items-center group py-3" :href="route('descrambler.eu-hazardous')">
        <i :class="['fas fa-object-ungroup mr-2 text-gray-500 group-hover:text-white']"></i>
        <div :class="isUrl('descrambler/euhazardous') ? 'text-white' : 'text-gray-500 group-hover:text-white'">
          EU Haz Descrambler
        </div>
      </inertia-link>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  props: ['is_superuser', 'isUrl', 'auth'],
  methods: {},
  /*
  computed: {
    isAllowedEmail() {
      const allowedEmails = ['andrew.j.stainer@gmail.com', 'thijs@nikura.com', 'philip@nikura.com', 'admin@nikura.com'];
      return allowedEmails.includes(this.auth.user.email);
      //return true
    }
  },
   */
}
</script>
