<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-3xl">FBA Final Audit</h1>
    </div>
    <div class="mt-10 flex justify-between items-center">
    </div>
    <div class="mt-5">
      <div :class="{ 'line-through': isPacking }">
        1. Enter batch code:
      </div>
      <div :class="{ 'line-through': fbaTrackingID }">
        2. Scan FBA box ID:
      </div>
      <div>
        3. Scan UPS tracking ID:
      </div>
      <div class="mt-5">
        <div v-if="isPackingEmpty" class="w-96">
          <form @submit.prevent="submit">
            <div class="w-96">
              <text-input ref="focusElement" v-model="form.search" class="" label="" placeholder="Batch Code"/>
            </div>
            <div class="my-5 flex">
              <button class="btn-indigo" type="submit">Submit</button>
              <!--a class="btn-indigo ml-4" :href="'/prices/?get_csv=y&search='+form.search"-->
              <inertia-link class="btn-indigo ml-4" :href="route('labels.audit')"
                            tabindex="-1">
                New Batch
              </inertia-link>
            </div>
          </form>
        </div>
        <div v-else-if="isPacking && !fbaTrackingID" class="w-96">
          <form @submit.prevent>
            <div class="w-96">
              <text-input ref="focusElement" label="" v-model="form.search" placeholder="FBA Box ID"/>
            </div>
            <div class="my-5 flex">
              <button class="btn-indigo" @click="setFbaTrackingID(fbaTrackingID)">Submit</button>
            </div>
          </form>
        </div>
        <div v-else-if="isPacking && fbaTrackingID" class="w-96">
          <form @submit.prevent>
            <div class="w-96">
              <text-input label="" v-model="form.search"/>
            </div>
            <div class="my-5 flex">
              <button class="btn-indigo" @click="checkUpsTrackingID()" placeholder="UPS Tracking ID">Submit
              </button>
            </div>
          </form>
        </div>

      </div>

      <!--div class="mb-6 flex justify-between items-center">
        <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
          <label class="block text-gray-700">Trashed:</label>
          <select v-model="form.trashed" class="mt-1 w-full form-select">
            <option :value="null" />
            <option value="with">With Trashed</option>
            <option value="only">Only Trashed</option>
          </select>
        </search-filter>
      </div-->
      <h2 class="mt-16 mb-8 font-bold text-2xl"> Packing Batch Details </h2>
      <div class="mt-12 bg-white rounded shadow overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4">Batch Code</th>
            <th class="px-6 pt-6 pb-4">Due Date</th>
            <th class="px-6 pt-6 pb-4">Name</th>
            <th class="px-6 pt-6 pb-4">Quantity</th>
            <th class="px-6 pt-6 pb-4">Status</th>
            <th class="px-6 pt-6 pb-4">Hazardous</th>
            <!--th class="px-6 pt-6 pb-4">Packer</th-->
            <th class="px-6 pt-6 pb-4">Category</th>
            <th class="px-6 pt-6 pb-4">SKU</th>
            <th class="px-6 pt-6 pb-4">Outer Box #</th>
            <th class="px-6 pt-6 pb-4">Outer Box Type</th>
            <th class="px-6 pt-6 pb-4">Batch ID</th>


            <!--th class="px-6 pt-6 pb-4" colspan="2">Status</th-->

          </tr>
          <!--tr v-for="packing in packing" :key="packing.id"-->
          <tr v-if="isPackingEmpty">
            <td class="border-t px-6 py-4" colspan="20">No packing batches found.</td>
          </tr>
          <tr v-else :key="packing.id">
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <div>
                  {{ packing.batch_code }}
                </div>
              </div>
            </td>

            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ packing.due_date.substring(0, 10) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <span>{{ packing.bundle_name }}</span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <div>
                  {{ packing.quantity_required }}
                </div>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ packing.status_name }}
              </div>
            </td>
            <!--td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ packing.username }}
              </div>
            </td-->
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <span>{{ packing.bundle_hazard }}</span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <span v-if="packing.category == null"> -- No category selected --</span>
                <span v-else>{{ packing.category_name }}</span>
              </div>
            </td>

            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <span v-if="packing.bundle_sku == null"> -- No product selected --</span>
                <span v-else>{{ packing.bundle_sku }}</span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <div>
                  {{ packing.box_quantity }}
                </div>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <div>
                  {{ packing.box_name }}
                </div>
              </div>
            </td>

            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <div>
                  {{ packing.id }}
                </div>
              </div>
            </td>
          </tr>
          <!--tr v-if="packing.length === 0">
            <td class="border-t px-6 py-4" colspan="20">No packing batches found</td>
          </tr-->
        </table>
      </div>
      <h2 class="mt-16 mb-8 font-bold text-2xl">Labels</h2>
      <div class="mt-12 rounded bg-white shadow overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <tr class="text-left font-bold">
            <!--th class="px-6 pt-6 pb-4 text-center"><i class="fa-brands fa-ups fa-lg cursor-help"
                                                      title="Has UPS label been scanned?"></i></th>
            <th class="px-6 pt-6 pb-4 text-center"><i class="fa-brands fa-amazon fa-lg cursor-help"
                                                      title="Has Amazon box label been scanned?"></i></th-->
            <th class="px-6 pt-6 pb-4">Hazardous</th>
            <th class="px-6 pt-6 pb-4">Status</th>
            <th class="px-6 pt-6 pb-4">Due Date</th>
            <th class="px-6 pt-6 pb-4">Title</th>
            <th class="px-6 pt-6 pb-4">Units Per Box</th>
            <th class="px-6 pt-6 pb-4">FBA ID</th>
            <th class="px-6 pt-6 pb-4">FBA Box ID</th>
            <th class="px-6 pt-6 pb-4">UPS Tracking ID</th>
            <th class="px-6 pt-6 pb-4">UPS Box</th>
            <th class="px-6 pt-6 pb-4">FBA SKU</th>
            <th class="px-6 pt-6 pb-4">LW / UPS SKU</th>
            <!--th class="px-6 pt-6 pb-4">Duplicate #</th-->
            <th class="px-6 pt-6 pb-4">LW Order ID</th>
            <th class="px-6 pt-6 pb-4">Packing ID</th>
            <!--th class="px-6 pt-6 pb-4">Packing ID</th-->
          </tr>
          <tr v-for="label in labels.data" :key="label.id"
              :class="{'bg-indigo-100': label.fba_tracking_id === fbaTrackingID, 'bg-gray-300': label.status === 2}">
            <!--td class="border-t">
              <div v-if="1===1" class="px-6 py-4 flex items-center border-l" tabindex="-1">
                <i class="fas fa-check text-gray-600"></i>
              </div>
            </td>
            <td class="border-t">
              <div v-if="1===1" class="px-6 py-4 flex items-center" tabindex="-1">
                <i class="fas fa-check text-gray-600"></i>
              </div>
            </td-->
            <td class="border-t">
              <div class="px-6 py-4 flex items-center justify-center">
                <i v-if="label.hazardous === 'Y'" class="fa-solid fa-radiation text-red-600"></i>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
              <span class="truncate">
              {{ label.status_name }}
                </span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center focus:text-indigo-500">
                {{ label.due_date.substring(0, 10) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center w-96" tabindex="-1">
              <span class="truncate">
              {{ label.sku_title }}
                </span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-centerå" tabindex="-1">
              <span class="truncate">
              {{ label.units_per_box }}
                </span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.fba_tracking_id.substring(0, 12) }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.fba_tracking_id }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                <span v-if="label.ups_tracking_id">
                  {{ label.ups_tracking_id }}
                </span>
                <span v-else>
                  N/A
                </span>
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.box_number }} of {{ label.box_total }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.fba_sku }}
              </div>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.lw_sku }}
              </div>
            </td>
            <!--td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.dup_rank }}
              </div>
            </td-->
            <td class="border-t">
              <a class="px-6 py-4 flex items-center text-indigo-400" tabindex="-1"
                 :href="'https://www.linnworks.net/#/app/ViewOrder/' +  label.lw_order_id" target="_blank">
                {{ label.lw_order_id }}
              </a>
            </td>
            <td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.packing_id }}
              </div>
            </td>
            <!--td class="border-t">
              <div class="px-6 py-4 flex items-center" tabindex="-1">
                {{ label.packing_id }}
              </div>
            </td-->
          </tr>
          <tr v-if="labels.data.length === 0">
            <td class="border-t px-6 py-4" colspan="20">
             <span v-if="isPackingEmpty">
                No labels found.
             </span>
              <span v-else>
                 No labels found. Have they been uploaded via <a target="_blank" class="text-indigo-400"
                                                                 :href="this.route('labels')">descrambler</a>?
              Note that only hazardous labels are currently uploaded.
               </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <!--pagination :links="labels.links"/-->
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import TextInput from '@/Shared/TextInput' //AJS addition for search form. Replaces SearchFilter

export default {
  metaInfo: {title: 'labels'},
  layout: Layout,
  components: {
    Icon,
    Pagination,
    //SearchFilter,
    TextInput
  },
  props: {
    auth: Object,
    labels: Object,
    filters: Object,
    packing: Object,
    //filters: Object,
    //categories: Object
  },
  data() {
    return {
      // Only need to set intitial state. preserveState will preserve them on search
      form: {
        //search: this.filters.search,
        search: null,
      },
      fbaTrackingID: null,
      upsTrackingID: null
    }
  },
  mounted() {
    this.$refs.focusElement.focus();
  },
  methods: {
    submit() {
      //Below must be get, not reload
      this.$inertia.get(this.route('labels.audit'), this.form, {preserveState: false})
    },
    setFbaTrackingID() {
      // Assume `this.packing` is your array of packing objects
      //const exists = this.labels.data.some(label => label.fba_tracking_id === this.form.search);
      const correspondingLabel = this.labels.data.find(label => label.fba_tracking_id === this.form.search)
      if (correspondingLabel) {
        // If label doesn't have ups_tracking_id (EU Haz), then complete audit
        if (!correspondingLabel.ups_tracking_id) {
          this.completeAudit(correspondingLabel)
        } else {
          this.fbaTrackingID = this.form.search
          this.form.search = ''
          this.$refs.focusElement.focus();
        }
      } else {
        alert('No label exists with this FBA Box ID');
      }
    },
    checkUpsTrackingID() {
      // First retrieve relevant label
      const correspondingLabel = this.labels.data.find(label => label.fba_tracking_id === this.fbaTrackingID);
      // Then check if UPS also matches
      if (correspondingLabel && correspondingLabel.ups_tracking_id === this.form.search) {
        //this.upsTrackingID = this.form.search
        this.completeAudit(correspondingLabel)
      } else {
        alert('This UPS ID does not correspond to the same label');
      }
    },
    completeAudit(correspondingLabel) {
      alert('Label matched!');
      this.$inertia.put(this.route('labels.audit.update-status'),
          {
            filters: {
              // Including this will make mean same packing batch is loaded back up.
              // Don't exclude, or error returned when no search found
              search: this.packing.batch_code,
            },
            label: correspondingLabel,
            packing: this.packing
          },
          {
            preserveState: false
          })
    }
  },
  computed: {
    isPackingEmpty() {
      return Object.keys(this.packing).length === 0;
    },
    isPacking() {
      return Object.keys(this.packing).length !== 0;
    }

  }
}
</script>
