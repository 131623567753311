<template>
  <div class="my-10 mt-20 flex">
    <div
        v-for="(categoryData, categoryKey) in categoryButtons"
        :key="categoryKey"
        :class="`${categoryData.colour} flex-1 text-white py-4 text-center cursor-pointer min-w-[200px]`"
        @click="emitToggleCategory(categoryKey)"
    >
        <span :class="[
              'text-xl font-bold',
              filters.category === categoryKey ? 'underline' : ''
            ]">
           {{ categoryData.title }} - {{ categoryCounts[categoryKey] || 0 }} Batches
        </span>
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import pickBy from 'lodash/pickBy'
import SearchFilter from '@/Shared/SearchFilter'
import throttle from 'lodash/throttle'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import draggable from 'vuedraggable'


export default {
  props: ['filters', 'categoryCounts'],
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TextInput,
    draggable,
  },
  data() {
    return {
      categoryButtons: {
        'af-uk-pr': {'title': 'MFN', 'colour': 'bg-red-400'},
        'af-uk': {'title': 'UK', 'colour': 'bg-blue-400'},
        'af-eu-de': {'title': 'Germany', 'colour': 'bg-indigo-400'},
        'af-eu-fr': {'title': 'France', 'colour': 'bg-green-400'},
        'af-eu-es': {'title': 'Spain', 'colour': 'bg-orange-400'},
        'af-eu-it': {'title': 'Italy', 'colour': 'bg-pink-400'}
      },
    }
  },
  methods: {
    emitToggleCategory(categoryKey) {
      // Emit an event to the parent
      this.$emit('category-toggled', categoryKey);
    },
  },
}
</script>
